import {assocPath, filter, find, head, pathOr, propEq} from 'ramda'

import {TotalPrice} from '@daedalus/core/src/api-types/bovio/response/split_bookings/offer_check'
import {
  HotelFeeBreakdownType,
  OfferPrice
} from '@daedalus/core/src/offer/types/offer'
import PriceTypes from '@daedalus/core/src/offer/types/PriceTypes'
import {Price} from '@daedalus/core/src/price/business/price'
import {
  getTotalPrice,
  isThereACurrencyConversion
} from '@daedalus/core/src/price/business/price'
import {
  getHotelFeesBreakdown,
  hotelFeesPath,
  nightlyDisplayTotalPath
} from '@daedalus/core/src/room/business/transformSapiRoomsResponse/utils/price'
import {toFloatWithTwoDecimals} from '@daedalus/core/src/utils/number'
import {RoomPrice} from '@findhotel/sapi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DefaultValueType = any

type GetPricePropsType = (
  prices: OfferPrice[],
  propertyPath?: string[],
  defaultValue?: DefaultValueType
) => OfferPrice

type GetPriceByTypePropsType = (
  type: string,
  prices: OfferPrice[],
  propertyPath?: string[],
  defaultValue?: DefaultValueType
) => OfferPrice | DefaultValueType

//has some more levels, tbd later
const getHotelFees = pathOr(0, hotelFeesPath)

export const HOTEL_FEES_RESORT_FEE = 'resort_fee'
export const HOTEL_FEES_TAX = 'tax'
export const HOTEL_FEES_OTHER = 'other'

export const getPriceByType: GetPriceByTypePropsType = (
  type,
  prices,
  propertyPath = [],
  defaultValue = null
) =>
  pathOr(defaultValue, [...propertyPath])(
    head(filter(propEq('type', type), prices))
  )

/** Converts price to float number */
export const convertPriceToFloat = (
  updatePath: string[],
  price: RoomPrice | OfferPrice | Price | TotalPrice
) => {
  const convertedPrice = Number.parseFloat(pathOr('', updatePath, price))
  return assocPath(updatePath, convertedPrice, price)
}

/**
  Gets the price with the type PriceTypes.CHARGEABLE_PRICE
 */
export const getChargeableCurrencyPrice: GetPricePropsType = (
  prices,
  propertyPath = []
): OfferPrice =>
  getPriceByType(PriceTypes.CHARGEABLE_PRICE, prices, propertyPath)

/**
 Returns the total hotel fees, unless it is a pay at property deal, then it returns the total price
 */
export const getTotalAtProperty = (price?: Price, isCanPayLater?: boolean) => {
  if (isCanPayLater) return getTotalPrice(price)
  return getHotelFees(price)
}

export const calculateNightlyPrice = (
  price: number | null | undefined,
  numberOfNights: number,
  numberOfRooms = 1
) => {
  if (Number.isNaN(price)) return null

  return toFloatWithTwoDecimals(Number(price) / numberOfNights / numberOfRooms)
}

/**
Returns the price the user sees in checkout rounded.
 */
export const getNightlyDisplayTotalPrice = (prices: OfferPrice[]): number => {
  const type = isThereACurrencyConversion(prices)
    ? PriceTypes.DISPLAY_PRICE
    : PriceTypes.CHARGEABLE_PRICE

  return getPriceByType(type, prices, nightlyDisplayTotalPath, null)
}

const getSelectedHotelFee = (price?: Price, type?: string) => {
  const hotelFeesBreakdown = getHotelFeesBreakdown(price)
  const selectedFeeType = find(propEq('type', type))(hotelFeesBreakdown)
  return pathOr(0, ['total'], selectedFeeType)
}

export const getHotelFeesResortFee = (price?: Price) =>
  getSelectedHotelFee(price, HOTEL_FEES_RESORT_FEE)

export const getHotelFeesTax = (price?: Price) =>
  getSelectedHotelFee(price, HOTEL_FEES_TAX)

export const getHotelFeesOther = (price?: Price) =>
  getSelectedHotelFee(price, HOTEL_FEES_OTHER)

/**
 * This function will verify if the chargeable and hotelFees portion of the price
 * object have each their own currencyCode. If they don't have, we will add the one from the root of the
 * object. Future changes in the API will bring this information making this function obsolete.
 * When 003bfcb2-pay-at-property-chargeable-currency-ui is complete we can remove this function
 * @param price an offer price object
 * @example
 * // This
 * {
      "chargeable": {
        "base": "378.00",
        "taxes": "49.14",
        "total": "427.14"
      },
      "currencyCode": "USD",
      "hotelFees": {
        "breakdown": [
          {
            "total": "88.14",
            "type": "resort_fee"
          }
        ],
        "total": "88.14"
      },
      "type": "chargeable_currency"
    }

    // becomes this
    {
      "chargeable": {
        "base": "378.00",
        "taxes": "49.14",
        "total": "427.14",
        "currencyCode": "USD",
      },          
      "hotelFees": {
        "breakdown": [
          {
            "total": "88.14",
            "type": "resort_fee"
          }
        ],
        "total": "88.14",
        "currencyCode": "USD"
      },
      "type": "chargeable_currency"
    }
 */
export const addCurrencyToChargeableAndHotelFees = (
  price: OfferPrice
): OfferPrice => {
  const baseCurrency = price?.currencyCode
  const chargeableCurrency = price.chargeable?.currencyCode
  const hotelFeesCurrency = price.hotelFees?.currencyCode

  let newPrice = {...price}

  if (!chargeableCurrency) {
    newPrice = assocPath(['chargeable', 'currencyCode'], baseCurrency, newPrice)
  }

  if (!hotelFeesCurrency) {
    newPrice = assocPath(['hotelFees', 'currencyCode'], baseCurrency, newPrice)
  }

  return newPrice
}

/**
 * SAPI returns no breakdown at the moment this function is being introduced (21/10/2021)
 * To avoid changing the way we calculate and display hotel fees we are picking up the total
 * and creating a generic breakdown.
 *
 * @param price an offer price object
 * @returns the price decorated with the breakdown
 */
export const guaranteeAtLeastOneHotelFeeBreakdown = (
  price: OfferPrice
): OfferPrice => {
  if (price.hotelFees?.breakdown?.length === 0 && price.hotelFees.total !== 0) {
    return {
      ...price,
      hotelFees: {
        ...price.hotelFees,
        breakdown: [
          {
            total: price.hotelFees.total,
            type: 'other' as HotelFeeBreakdownType
          }
        ]
      }
    }
  }

  return price
}
