import {brands, defaultBrand} from '../../../brand/config'
import {BrandCode} from '../../../brand/types'
import {getIsProductionAppEnv} from '../../../utils/environment'
import {getUrlParam} from '../../../utils/url'
import {
  getBrandOverrideFromCookie,
  persistBrandOverrideOnCookie
} from '../business/persistData'

/**
 * Retrieves the brand code from the URL or cookies, with consideration for demo environments
 * and production settings.
 *
 * @param appEnv - The consumer process environment (e.g., 'production', 'prd')
 * Warning: Don't use process.env.NODE_ENV, use the APP_ENV or equivalent from the env files on each package (accessed through Settings).
 *
 * @returns brandCode- The determined brand code.
 */
export const getBrandCodeFromUrlOrCookie = (appEnv: string) => {
  const {hostname} = location
  if (!hostname) return defaultBrand.code
  const hostnameParts = hostname.split('.')
  const brandCodes = Object.keys(brands) as BrandCode[]

  const brandOverride =
    (getUrlParam({
      search: location.search,
      param: 'brandOverride'
    }) as BrandCode) || getBrandOverrideFromCookie()

  const siteIsDemo = hostnameParts.some(part => part.includes('demo'))

  const envIsProduction = getIsProductionAppEnv(appEnv)

  const brandOverrideIsValid =
    (!envIsProduction || siteIsDemo) &&
    Boolean(brandOverride) &&
    Boolean(brands[brandOverride])

  const getBrandCode = () =>
    (hostnameParts.find(part =>
      brandCodes.find(code => part.includes(code))
    ) as BrandCode) || defaultBrand.code

  const brandCode = getBrandCode()

  if (brandOverrideIsValid) {
    persistBrandOverrideOnCookie(brandOverride)
    return brandOverride
  }

  return brandCode
}
