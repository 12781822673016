import React from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {AuthenticationButtons} from '../../../authentication/AuthenticationButtons'
import {MobileMenuUserInfo} from '../MobileMenuUserInfo'
import {MobileMenuUserSectionSkeleton} from './Skeleton'

type PropsType = {
  /** Whether the user is authenticated */
  isAuthenticated: boolean
  /** Whether user data is still loading */
  isLoading: boolean
  /** Callback for Login click */
  onLogInClick: () => void
  /** Callback for Signup click */
  onSignUpClick: () => void
  onShowAccountOverlay: () => void
}

const UserSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${cssTheme.layout.spacing.s600} ${cssTheme.layout.spacing.s500}
    ${cssTheme.layout.spacing.s700};
  flex-shrink: 0;
  &.isAuthenticated {
    padding: ${cssTheme.layout.spacing.s600} ${cssTheme.layout.spacing.s500};
    align-items: stretch;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${cssTheme.layout.spacing.s600};
  gap: ${cssTheme.layout.spacing.s300};
`

export const MobileMenuUserSection = ({
  isAuthenticated,
  isLoading,
  onLogInClick,
  onSignUpClick,
  onShowAccountOverlay
}: PropsType) => {
  if (isLoading) {
    return <MobileMenuUserSectionSkeleton />
  }

  return (
    <UserSectionWrapper className={cx(isAuthenticated && 'isAuthenticated')}>
      {isAuthenticated ? (
        <MobileMenuUserInfo onShowAccountOverlay={onShowAccountOverlay} />
      ) : (
        <>
          <TextWrapper>
            <Text variant="titleL">
              <FormattedMessageWrapper
                id="userWidget.login"
                defaultMessage="Sign in or create account"
              />
            </Text>
            <Text variant="bodyM">
              <FormattedMessageWrapper
                id="userWidget.memberDisclaimer"
                defaultMessage="Find lower prices and member-only deals"
              />
            </Text>
          </TextWrapper>
          <AuthenticationButtons
            size="lg"
            onSignUpClick={onSignUpClick}
            onLogInClick={onLogInClick}
          />
        </>
      )}
    </UserSectionWrapper>
  )
}
