import React from 'react'
import {styled} from '@linaria/react'

import {Button} from '@daedalus/atlas/Button'
import {PopUp} from '@daedalus/atlas/PopUp'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

const TextWrapper = styled.div`
  margin-bottom: ${cssTheme.layout.spacing.s600};
  max-width: 326px;
  text-align: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${cssTheme.layout.spacing.s500};
`

type PropsType = {
  isOpen: boolean
  onCancelClick: () => void
  onDeleteClick: () => void
}

export const Modal = ({isOpen, onCancelClick, onDeleteClick}: PropsType) => (
  <PopUp
    isOpen={isOpen}
    titleBarProps={{
      centerContent: (
        <FormattedMessageWrapper
          id="deleteAccountModal.areYouSure"
          defaultMessage="Are you sure?"
        />
      )
    }}
  >
    <TextWrapper>
      <Text colorPath="content.neutral.c600">
        <FormattedMessageWrapper
          id="deleteAccountModal.deleteAccountMessage"
          defaultMessage="Are you sure you want to delete your account? Please remember that this is permanent and can't be undone."
        />
      </Text>
    </TextWrapper>

    <ButtonsWrapper>
      <Button fullWidth variant="primary" size="lg" onClick={onDeleteClick}>
        <FormattedMessageWrapper
          id="deleteAccountModal.yesDelete"
          defaultMessage="Yes, delete"
        />
      </Button>
      <Button fullWidth variant="quiet" size="lg" onClick={onCancelClick}>
        <FormattedMessageWrapper
          id="deleteAccountModal.noKeep"
          defaultMessage="No, keep"
        />
      </Button>
    </ButtonsWrapper>
  </PopUp>
)
