import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {Flag} from '@daedalus/atlas/Flag'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {getCountryCodeFromLanguage} from '@daedalus/core/src/localization/services/country'
import {splitPopularAndOtherLanguages} from '@daedalus/core/src/localization/services/locale'
import {LanguageType} from '@daedalus/core/src/localization/types/LanguageType'

import {SearchInput} from '../../SearchInput'
import {SelectorList} from '../SelectorList/SelectorList'

interface Props {
  isMobile?: boolean
  value: string
  onChange: (code: string) => void
  languages: LanguageType[]
  onSearchInputFocus?: () => void
}

const LanguageName = styled(Text)`
  flex-grow: 1;
  white-space: wrap;
  &.isMobile {
    white-space: nowrap;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${cssTheme.layout.spacing.s300};
  &:not(.isMobile) {
    padding: ${cssTheme.layout.spacing.s300};
  }
  &.isMobile {
    align-items: center;
  }
`

const splitPopularAndOther = (items: LanguageType[]) => {
  const {languagesList, popularLanguagesList} =
    splitPopularAndOtherLanguages(items)

  return {
    itemsList: languagesList,
    popularItemsList: popularLanguagesList
  }
}

export const LanguageSelector: React.FC<Props> = ({
  isMobile = false,
  languages,
  onChange,
  value,
  onSearchInputFocus
}) => {
  const {formatMessage} = useIntl()
  const sortedLanguages = useMemo(() => {
    const result = [...languages]
    result.sort((a, b) => a.name.localeCompare(b.name))
    return result
  }, [languages])

  const renderList = (items: LanguageType[], isSearching = false) => (
    <SelectorList
      isMobile={isMobile}
      items={items}
      value={value}
      onChange={onChange}
      getValue={item => item.code}
      popularItemsTitle={formatMessage({
        id: 'popularLanguages',
        defaultMessage: 'Popular languages'
      })}
      otherItemsTitle={formatMessage({
        id: 'allLanguages',
        defaultMessage: 'All languages'
      })}
      splitPopularAndOther={splitPopularAndOther}
      renderItem={(item, isMobile) => (
        <Wrapper className={cx(isMobile && 'isMobile')}>
          <Flag countryCode={getCountryCodeFromLanguage(item.code)} />
          <LanguageName
            variant={isMobile ? 'bodyM' : 'bodyS'}
            className={cx(isMobile && 'isMobile')}
          >
            {item.displayName}
          </LanguageName>
        </Wrapper>
      )}
      isSearching={isSearching}
    />
  )
  if (isMobile) {
    return (
      <SearchInput
        items={sortedLanguages}
        itemMatcher={SearchInput.matchers.default([
          'displayName',
          'code',
          'name'
        ])}
        onFocus={onSearchInputFocus}
      >
        {renderList}
      </SearchInput>
    )
  }

  return renderList(languages)
}
