import {acceptedCardTypes, acceptedCardTypesV2} from 'configs/payment'
import {toggle} from 'opticks'
import {filter, includes} from 'ramda'

import {CreditCard} from '@daedalus/core/src/booking/services/paymentOptionsApi'

export const filterCards = (
  cards: CreditCard[],
  isMoR = false
): CreditCard[] => {
  const isAmexDisabled = isMoR && toggle('2769be7e-disable-amex', false, true)

  return filter(
    card =>
      includes(
        card.code,
        isAmexDisabled ? acceptedCardTypesV2 : acceptedCardTypes
      ),
    cards
  )
}
