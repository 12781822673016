import {defineMessages} from 'react-intl'

export const genericMessages = defineMessages({
  backToForm: {
    id: 'backToForm',
    defaultMessage: 'Back to form'
  }
})

export const bookingErrorMessages = defineMessages({
  requestErrorTitle: {
    id: 'requestErrorTitle',
    defaultMessage: 'Payment failed'
  },
  cardNotSupportedTitle: {
    id: 'errors.cardNotSupportedTitle',
    defaultMessage: 'Card not supported'
  },
  connectionErrorTitle: {
    id: 'errors.connectionErrorTitle',
    defaultMessage: 'Connection error'
  },
  connectionErrorDescription: {
    id: 'errors.connectionErrorDescriptionV3',
    defaultMessage:
      "Your booking confirmation is on its way. It may take a few minutes and could land in your spam folder.<br></br><br></br>If you don't get a confirmation, contact support. Don't attempt to make a new booking."
  },
  duplicateBookingTitle: {
    id: 'errors.duplicateBookingTitle',
    defaultMessage: 'Duplicate booking'
  },
  duplicateBookingDescription: {
    id: 'errors.duplicateBookingDescriptionV3',
    defaultMessage:
      "You made an identical booking a few seconds ago.<br></br><br></br>You can't make duplicate bookings. Check your inbox or spam for confirmation of the first booking."
  },
  genericErrorMessage: {
    id: 'errors.genericErrorDescriptionV3',
    defaultMessage: 'Try again, or try booking a different deal.'
  },
  cardNotSupportedDescription: {
    id: 'errors.cardNotSupportedDescription',
    defaultMessage: 'Choose another card type and try again'
  },
  incorrectCVCTitle: {
    id: 'errors.incorrectCVCTitle',
    defaultMessage: 'Incorrect CVC'
  },
  incorrectCVCDescription: {
    id: 'errors.incorrectCVCDescriptionV3',
    defaultMessage: "Reenter your card's security code and try again."
  },
  incorrectCCTitle: {
    id: 'errors.incorrectCCTitle',
    defaultMessage: 'Incorrect card number'
  },
  genericFraudDeclinedMessageV3: {
    id: 'errors.genericFraudDeclinedMessageV3',
    defaultMessage: 'Try a different payment method or contact your bank.'
  },
  paymentDeclinedMessage: {
    id: 'errors.paymentDeclined',
    defaultMessage: 'Payment declined'
  },
  genericFraudDeclinedTitlePayLater: {
    id: 'errors.genericFraudDeclinedTitlePayLater',
    defaultMessage: 'Failed to add payment method'
  },
  invalidEmailTitle: {
    id: 'errors.invalidEmailTitle',
    defaultMessage: 'Invalid email'
  },
  invalidEmailDescriptionV3: {
    id: 'errors.invalidEmailDescriptionV3',
    defaultMessage: 'Reenter your email and try again.'
  },
  incorrectCCDescription: {
    id: 'errors.incorrectCCDescription',
    defaultMessage: 'Reenter your card number and try again'
  },
  insufficientFundsTitle: {
    id: 'errors.insufficientFundsTitle',
    defaultMessage: 'Insufficient funds'
  },
  insufficientFundsDescription: {
    id: 'errors.insufficientFundsDescription',
    defaultMessage: 'Try a different payment method or contact your bank'
  },
  soldOutDescription: {
    id: 'errors.soldoutDescription',
    defaultMessage:
      'We just sold out of your selected deal–check out these similar deals from our trusted partners:'
  },
  soldOutTitle: {
    id: 'errors.soldoutTitle',
    defaultMessage: 'Sold out on {brandName}'
  }
})
