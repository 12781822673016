import React, {CSSProperties, ReactNode} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import minus from '../../../utils/minus'
import {
  ControlType,
  ControlWithCheckmark
} from '../helpers/ControlWithCheckmark'
import {Grid} from '../helpers/Grid'
import {Tag} from '../Tag'
import {Text} from '../Text'

const SIZES = ['md', 'lg'] as const

type Size = (typeof SIZES)[number]

const BOX_SHADOW_WIDTH = 1

interface Props {
  selected: boolean
  dataId?: string
  name: string
  onChange: () => void
  value?: string
  size?: Size
  children: ReactNode
  labelText?: string
  withControlButton?: boolean
  withBorder?: boolean
  alignItems?: CSSProperties['alignItems']
  disabled?: boolean
  className?: string
  type: ControlType
  style?: CSSProperties
}

const defaultStyles = `
  border: 1px solid transparent;
  background-color: ${cssTheme.colors.selectionCard.default.background};
  color: ${cssTheme.colors.selectionCard.default.description};
  &.withBorder {
    border-color: ${cssTheme.colors.selectionCard.default.border};
    box-shadow: inset 0 0 0 ${BOX_SHADOW_WIDTH}px
      ${cssTheme.colors.border.neutral.c050};
  }
`

const hoverStyles = `
  border: 1px solid transparent;
  background-color: ${cssTheme.colors.selectionCard.hover.background};
  &.withBorder {
    border-color: ${cssTheme.colors.selectionCard.hover.border};
    box-shadow: inset 0 0 0 ${BOX_SHADOW_WIDTH}px
      ${cssTheme.colors.selectionCard.hover.border};
  }
`

const activeStyles = `
  border: 1px solid transparent;
  &.withBorder {
    border-color: ${cssTheme.colors.selectionCard.selected.border};
    box-shadow: inset 0 0 0 ${BOX_SHADOW_WIDTH}px
      ${cssTheme.colors.selectionCard.selected.border};
    background-color: ${cssTheme.colors.selectionCard.selected.background};
  }
`

const disabledStyles = `
  border: 1px solid transparent;
  background-color: ${cssTheme.colors.selectionCard.disabled.background};
  color: ${cssTheme.colors.selectionCard.disabled.description};
  &.withBorder {
    border-color: ${cssTheme.colors.selectionCard.disabled.border};
    box-shadow: 0 0 0 ${BOX_SHADOW_WIDTH}px
      ${cssTheme.colors.selectionCard.disabled.border};
  }
`

const Wrapper = styled.label`
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  margin: ${BOX_SHADOW_WIDTH}px; /* prevents the box-shadow from being cut off*/
  gap: ${cssTheme.layout.spacing.s300};
  padding: ${cssTheme.layout.spacing.s500};
  border-radius: ${cssTheme.layout.radius.lg};
  cursor: pointer;
  &--size-md {
    padding: ${cssTheme.layout.spacing.s300} ${cssTheme.layout.spacing.s400};
  }
  ${defaultStyles}
  &.isSelected {
    ${activeStyles}
  }
  &.isDisabled,
  &.isSelected.isDisabled {
    ${disabledStyles}
  }
  ${linariaMq.desktopXs} {
    &:hover {
      ${hoverStyles}
      &.isSelected:not(.isDisabled) {
        ${activeStyles}
      }
      &.isDisabled {
        ${disabledStyles}
      }
    }
    &:active:not(.isDisabled),
    &:focus:not(.isDisabled) {
      ${activeStyles}
    }
  }
`

const LabelText = styled(Tag)`
  position: absolute;
  top: ${minus(cssTheme.layout.spacing.s400)};
  left: ${cssTheme.layout.spacing.s400};
  border-radius: ${cssTheme.layout.radius.lg};
  background-color: ${cssTheme.colors.selectionCard.default.tag.background};
  color: ${cssTheme.colors.selectionCard.default.tag.content};
  &.isDisabled {
    background-color: ${cssTheme.colors.selectionCard.disabled.tag.background};
    color: ${cssTheme.colors.selectionCard.disabled.tag.content};
  }
  &.isSelected:not(.isDisabled) {
    background-color: ${cssTheme.colors.selectionCard.selected.tag.background};
    color: ${cssTheme.colors.selectionCard.selected.tag.content};
  }
`

const ControlButtonWrapper = styled.div`
  opacity: 0;
  &.isSelected,
  &.--type-checkbox {
    opacity: 1;
  }
`

export const SelectionCard = ({
  selected,
  dataId = 'SelectionCard',
  name,
  onChange,
  value,
  size = 'lg',
  children,
  labelText,
  withControlButton = true,
  withBorder = true,
  disabled = false,
  className,
  alignItems = 'flex-start',
  type = 'radio',
  style
}: Props) => {
  return (
    <Wrapper
      className={cx(
        `${getLinariaClassName(Wrapper)}--size-${size}`,
        withBorder && 'withBorder',
        selected && 'isSelected',
        disabled && 'isDisabled',
        className
      )}
      onClick={withControlButton ? undefined : onChange}
      data-id={dataId}
      style={style}
    >
      {labelText && (
        <LabelText
          className={cx(selected && 'isSelected', disabled && 'isDisabled')}
        >
          <Text variant="labelXS">{labelText}</Text>
        </LabelText>
      )}

      <Grid
        container
        spacing="s000"
        direction="row"
        justify="space-between"
        alignItems={alignItems}
        gap="s300"
        wrap="nowrap"
      >
        {children}
        {withControlButton && (
          <ControlButtonWrapper
            className={cx(selected && 'isSelected', `--type-${type}`)}
          >
            <ControlWithCheckmark
              type={type}
              name={name}
              checked={selected}
              onChange={onChange}
              value={value}
              disabled={disabled}
            />
          </ControlButtonWrapper>
        )}
      </Grid>
    </Wrapper>
  )
}
