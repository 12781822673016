/* eslint-disable fp/no-mutation */
import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled} from '@linaria/react'

import {cssTheme} from '@daedalus/atlas/themes'
import {useUpdateLocationState} from '@daedalus/core/src/_web/router/hooks'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {trackUserAuthClick} from '@daedalus/core/src/auth/modules/actions'
import {
  getAuthenticationFlow,
  getPasswordlessLoginSourceComponent,
  getUnlockHotelId
} from '@daedalus/core/src/auth/modules/selectors'
import {
  cleanUpLoginErrors,
  hideBottomSheetLogin
} from '@daedalus/core/src/auth/modules/slice'
import {
  makeSocialLogin,
  SocialConnections
} from '@daedalus/core/src/auth/modules/thunks'
import {
  AuthenticationFlows,
  AuthenticationMethod,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {storeAuthenticationFlow} from '@daedalus/core/src/auth/utils/storage'
import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {OfferInfoType} from '@daedalus/core/src/offer/types/offer'

import {Snowflake} from '../../../../dealFreeze/components/FreezeDetails/Snowflake'
import {removeSusiQueryParam} from '../../../hooks/useSUSIBottomSheet'
import {SusiBottomSheet} from './SusiBottomSheet'
import {
  HandleLogin,
  PasswordlessLoginPropsType,
  SusiContextProps,
  SusiHistoryState,
  TrackSignInError
} from './types'

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  border: ${cssTheme.layout.spacing.s100} solid
    ${cssTheme.colors.background.neutral.c000};
  border-radius: ${cssTheme.layout.radius.rounded};
  background: ${cssTheme.colors.background.brand.c100};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SusiContext = createContext<SusiContextProps>(
  {} as SusiContextProps
)

const AUTHENTICATION_CLICK_TIMEOUT_BEFORE_NAVIGATING_AWAY = 500

export const SusiProvider = ({
  brand,
  children,
  userCountryCode,
  anonymousId
}: PasswordlessLoginPropsType) => {
  const [iconSlot, setIconSlot] = useState<ReactElement | null>(null)
  const [titleSlot, setTitleSlot] = useState<ReactElement | null>(null)
  const [mainContentSlot, setMainContentSlot] = useState<ReactElement | null>(
    null
  )
  const [callbackUrl, setCallbackUrl] = useState<string | null>(null)
  const [bottomContentSlot, setBottomContentSlot] =
    useState<ReactElement | null>(null)
  const [infoContext, setInfoContext] = useState<Record<string, unknown>>({})
  const dispatch = useDispatch()
  const unlockHotelId = useSelector(getUnlockHotelId)
  const flow = useSelector(getAuthenticationFlow)
  const isDealFreezeFlow = flow === AuthenticationFlows.DealFreeze
  const [authenticationMethod, setAuthenticationMethod] =
    useState<AuthenticationMethod>()
  const updateLocationState = useUpdateLocationState()
  const sourceComponent = useSelector(getPasswordlessLoginSourceComponent)
  const {showSignIn} = brand

  const getInfoType = useCallback((): OfferInfoType => {
    const gemsPrice = (infoContext?.gemsPrice ?? 0) as number
    const otherSitesPrice = (infoContext?.otherSitesPrice ?? 0) as number
    return gemsPrice < otherSitesPrice
      ? OfferInfoType.Discount
      : OfferInfoType.Parity
  }, [infoContext])

  useEffect(() => {
    storeAuthenticationFlow(flow)
    if (isDealFreezeFlow) {
      setIconSlot(
        <IconWrapper>
          <Snowflake />
        </IconWrapper>
      )
    }
  }, [isDealFreezeFlow, flow])

  const updateSUSILocationState = (newState: SusiHistoryState) => {
    updateLocationState(['state', 'susi'], newState)
  }

  const trackSusiDisplayed = (
    flow: AuthenticationFlows,
    data?: Record<string, unknown>
  ) => {
    const analyticsData = data ?? {}

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.AuthenticationOverlay,
        action: Action.Displayed,
        team: Team.Retention,
        payload: {
          infoType: getInfoType(),
          isReferrerSusi: false,
          authenticationFlow: flow,
          ...analyticsData
        }
      })
    )
  }

  const trackSignInError: TrackSignInError = errorContext => {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.Authentication,
        action: Action.Errored,
        component: flow || undefined,
        analyticsContext: {
          [AnalyticsContext.ErrorContext]: errorContext
        },
        payload: {
          authenticationMethod
        },
        team: Team.Retention
      })
    )
  }

  const handleSocialLoginClick = (socialConnection: SocialConnections) => {
    setTimeout(
      () =>
        dispatch(
          makeSocialLogin({
            connection: socialConnection,
            unlockHotelId,
            sourceComponent,
            callbackUrl
          } as {
            connection: SocialConnections
            unlockHotelId: string
            sourceComponent?: SourceComponentType
          })
        ),
      AUTHENTICATION_CLICK_TIMEOUT_BEFORE_NAVIGATING_AWAY
    )
  }

  const handleLogin: HandleLogin =
    (authenticationMethod, provider, analyticsData) => () => {
      setAuthenticationMethod(authenticationMethod)
      dispatch(
        trackUserAuthClick({
          authenticationMethod,
          sourceComponent: SourceComponentType['Login'],
          analyticsData
        })
      )

      if (isReactNativeWebView()) {
        return postMessageToWebView({
          type: RNWebViewMessageTypes.SOCIAL_AUTH,
          payload: {provider: authenticationMethod}
        })
      }

      const socialLoginMapped = {
        [AuthenticationMethod.Google]: SocialConnections.Google,
        [AuthenticationMethod.Facebook]: SocialConnections.Facebook,
        [AuthenticationMethod.Apple]: SocialConnections.Apple
      }

      handleSocialLoginClick(socialLoginMapped[authenticationMethod])
    }

  const handleCloseOverlay = () => {
    dispatch(cleanUpLoginErrors())
    dispatch(hideBottomSheetLogin())
    setCallbackUrl(null)
    removeSusiQueryParam()
  }

  return (
    <SusiContext.Provider
      value={{
        authenticationMethod,
        brand,
        handleCloseOverlay,
        handleLogin,
        infoContext,
        setBottomContentSlot,
        setIconSlot,
        setInfoContext,
        setMainContentSlot,
        setTitleSlot,
        trackSignInError,
        trackSusiDisplayed,
        updateSUSILocationState,
        userCountryCode,
        anonymousId,
        setCallbackUrl
      }}
    >
      {children}
      {showSignIn && (
        <SusiBottomSheet
          IconSlot={iconSlot}
          TitleSlot={titleSlot}
          MainContentSlot={mainContentSlot}
          BottomContentSlot={bottomContentSlot}
          infoContext={infoContext}
        />
      )}
    </SusiContext.Provider>
  )
}
