import React from 'react'
import {useSelector} from 'react-redux'
import {styled} from '@linaria/react'

import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const Link = styled.a`
  font-size: ${cssTheme.fonts.size.xs};
  font-weight: normal;
  color: ${cssTheme.colors.content.neutral.c600};
  text-decoration: underline;
  &:visited,
  &:hover,
  &:focus {
    color: ${cssTheme.colors.content.neutral.c800};
  }
`

const AgreementWrapper = styled.div`
  margin-top: ${cssTheme.layout.spacing.s500};
  text-align: center;
`

export const Agreement = () => {
  const brand = useSelector(getBrand)
  return (
    <AgreementWrapper>
      <Text variant="bodyXS" colorPath="content.neutral.c600">
        <FormattedMessageWrapper
          id="magicLinkModal.agreementMessage"
          defaultMessage="By continuing, you agree with our <TermsOfUse></TermsOfUse> and <PrivacyPolicy></PrivacyPolicy>."
          values={{
            TermsOfUse: () => (
              <Link href={brand?.termsOfUseUrl} target="_blank">
                <FormattedMessageWrapper
                  id="termsOfUse"
                  defaultMessage="Terms of Use"
                />
              </Link>
            ),
            PrivacyPolicy: () => (
              <Link href={brand?.privacyPolicyUrl} target="_blank">
                <FormattedMessageWrapper
                  id="privacyPolicy"
                  defaultMessage="Privacy Policy"
                />
              </Link>
            )
          }}
        />
      </Text>
    </AgreementWrapper>
  )
}
