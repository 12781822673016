import {Category as BoVioErrorCategory} from '../../api-types/bovio/response/error'
import {bookingErrorMessages} from '../messages'
import {ErrorCategory} from '../types/ErrorCategory'
import {BookingError} from '../types/ErrorResponse'

export const NETWORK_ERROR = 'Network Error'

/**
 * Checks if the provided booking error corresponds to a generic payment declined.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.GenericPaymentDeclined`.
 * @returns `true` if the error category is `GenericPaymentDeclined`; otherwise, `false`.
 */
export const getIsGenericPaymentDeclined = (error?: BookingError) => {
  return (
    error?.data?.details?.category === BoVioErrorCategory.GenericPaymentDeclined
  )
}

/**
 * Checks if the provided booking error corresponds to a generic fraud declined.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.GenericFraudDeclined`.
 * @returns `true` if the error category is `GenericFraudDeclined`; otherwise, `false`.
 */
export const getIsGenericFraudDeclined = (error?: BookingError) => {
  return (
    error?.data?.details?.category === BoVioErrorCategory.GenericFraudDeclined
  )
}

/**
 * Checks if the provided booking error corresponds to a incorrect card number.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.IncorrectNumber`.
 * @returns `true` if the error category is `IncorrectNumber`; otherwise, `false`.
 */
export const getIsWrongCCError = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.IncorrectNumber
}

/**
 * Checks if the provided booking error corresponds to a incorrect CVC Number.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.IncorrectCvc`.
 * @returns `true` if the error category is `IncorrectCvc`; otherwise, `false`.
 */
export const getIsWrongCVCError = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.IncorrectCvc
}

/**
 * Checks if the provided booking error corresponds to a insufficient funds.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.InsufficientFunds`.
 * @returns `true` if the error category is `InsufficientFunds`; otherwise, `false`.
 */
export const getIsInsufficientFunds = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.InsufficientFunds
}

/**
 * Checks if the provided booking error corresponds to a card not supported.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.CardNotSupported`.
 * @returns `true` if the error category is `CardNotSupported`; otherwise, `false`.
 */
export const getIsCardNotSupported = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.CardNotSupported
}

/**
 * Checks if the provided booking error corresponds to a price mismatch.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.PriceMismatch`.
 * @returns `true` if the error category is `PriceMismatch`; otherwise, `false`.
 */
export const getIsPriceMismatch = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.PriceMismatch
}

/**
 * Checks if the provided booking error corresponds to a invalid email address.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.InvalidEmail`.
 * @returns `true` if the error category is `InvalidEmail`; otherwise, `false`.
 */
export const getIsInvalidEmail = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.InvalidEmail
}

/**
 * Determines whether the provided booking error represents a connection error.
 *
 * @param error - The optional booking error object. The function checks if the `status`
 *                is `500` or if the `data` (cast as a string) matches the `NETWORK_ERROR` constant.
 * @returns `true` if the error indicates a connection error; otherwise, `false`.
 */
export const getIsConnectionError = (error?: BookingError) => {
  return (
    error?.status === 500 ||
    (error?.data as unknown as string) === NETWORK_ERROR
  )
}

/**
 * Checks if the provided booking error corresponds to a other category.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.Other`.
 * @returns `true` if the error category is `Other`; otherwise, `false`.
 */
export const getIsGenericSupplierOrBackendError = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.Other
}

/**
 * Checks if the provided booking error corresponds to a sold out.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.SoldOut`.
 * @returns `true` if the error category is `SoldOut`; otherwise, `false`.
 */
export const getIsSoldOutError = (error?: BookingError) => {
  return error?.data?.details?.category === BoVioErrorCategory.SoldOut
}

/**
 * Checks if the provided booking error corresponds to a payment not authorized.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.PaymentNotAuthorized`.
 * @returns `true` if the error category is `PaymentNotAuthorized`; otherwise, `false`.
 */
export const getIsPaymentNotAuthorized = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.PaymentNotAuthorized
}

/**
 * Checks if the provided booking error corresponds to a duplicate booking.
 *
 * @param error - The optional booking error object that contains error details.
 *                The function specifically checks the `category` field in the `details` object
 *                to determine if it matches `ErrorCategory.DuplicateBooking`.
 * @returns `true` if the error category is `DuplicateBooking`; otherwise, `false`.
 */
export const getIsDuplicateBooking = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.DuplicateBooking
}

/**
 * Maps a given booking error to its corresponding error category, based on specific error checks.
 *
 * @param error - The booking error object (optional). This object contains details that determine
 *                the type of error encountered. Specific error checks such as
 *                `getIsGenericPaymentDeclined` or `getIsCardNotSupported` are used
 *                to identify the error category.
 * @returns The mapped error category from `BoVioErrorCategory` or `ErrorCategory`,
 *          a network error constant (`NETWORK_ERROR`), or `undefined` if no matching category is found.
 * @example
 * ```typescript
 * const error = {
 *   data: {
 *     details: {
 *       category: BoVioErrorCategory.GenericFraudDeclined
 *     }
 *   }
 * };
 * const result = getMappedBookingErrorToErrorCategory(error);
 * // Expected output: BoVioErrorCategory.GenericFraudDeclined
 * const noError = undefined;
 * const result = getMappedBookingErrorToErrorCategory(noError);
 * // Expected output: undefined
 * ```
 */
export const getMappedBookingErrorToErrorCategory = (error?: BookingError) => {
  if (getIsGenericPaymentDeclined(error)) {
    return BoVioErrorCategory.GenericPaymentDeclined
  } else if (getIsGenericFraudDeclined(error)) {
    return BoVioErrorCategory.GenericFraudDeclined
  } else if (getIsPaymentNotAuthorized(error)) {
    return ErrorCategory.PaymentNotAuthorized
  } else if (getIsWrongCCError(error)) {
    return BoVioErrorCategory.IncorrectNumber
  } else if (getIsWrongCVCError(error)) {
    return BoVioErrorCategory.IncorrectCvc
  } else if (getIsInsufficientFunds(error)) {
    return BoVioErrorCategory.InsufficientFunds
  } else if (getIsCardNotSupported(error)) {
    return BoVioErrorCategory.CardNotSupported
  } else if (getIsPriceMismatch(error)) {
    return BoVioErrorCategory.PriceMismatch
  } else if (getIsDuplicateBooking(error)) {
    return ErrorCategory.DuplicateBooking
  } else if (getIsInvalidEmail(error)) {
    return BoVioErrorCategory.InvalidEmail
  } else if (getIsConnectionError(error)) {
    return NETWORK_ERROR
  }
  return undefined
}

/**
 * Retrieves the appropriate error message and description for recoverable booking errors
 * based on the provided booking error object.
 *
 * @param error - The booking error object containing details about the issue encountered.
 *                This object should have a `data.details.category` property to determine the error type.
 * @returns An object containing `title` and `description` properties for the recoverable error,
 *          or `null` if the error category is not recoverable.
 * @example
 * ```typescript
 * const error = {
 *   data: {
 *     details: {
 *       category: BoVioErrorCategory.CardNotSupported
 *     }
 *   }
 * };
 * const result = getRecoverableErrorMessages(error);
 * // Expected output:
 * // {
 * //   title: { id: 'errors.cardNotSupportedTitle', defaultMessage: 'Card not supported' },
 * //   description: { id: 'errors.cardNotSupportedDescription', defaultMessage: 'Choose another card type and try again' }
 * // }
 * ```
 */
export const getRecoverableErrorMessages = (error: BookingError) => {
  const bookingErrorCategory = getMappedBookingErrorToErrorCategory(error)

  if (bookingErrorCategory === BoVioErrorCategory.CardNotSupported) {
    return {
      title: bookingErrorMessages.cardNotSupportedTitle,
      description: bookingErrorMessages.cardNotSupportedDescription
    }
  }
  if (bookingErrorCategory === BoVioErrorCategory.InsufficientFunds) {
    return {
      title: bookingErrorMessages.insufficientFundsTitle,
      description: bookingErrorMessages.insufficientFundsDescription
    }
  }
  if (bookingErrorCategory === BoVioErrorCategory.IncorrectCvc) {
    return {
      title: bookingErrorMessages.incorrectCVCTitle,
      description: bookingErrorMessages.incorrectCVCDescription
    }
  }
  if (bookingErrorCategory === BoVioErrorCategory.IncorrectNumber) {
    return {
      title: bookingErrorMessages.incorrectCCTitle,
      description: bookingErrorMessages.incorrectCCDescription
    }
  }
  return null
}
