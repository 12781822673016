import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled} from '@linaria/react'
import useParams from 'components/data/URLParams/useParams'
import UserSideMenuWithStore from 'components/UserSideMenuWithStore'
import {Component} from 'middlewares/sideEffects/analyticsV2'
import {trackEventV1} from 'modules/analytics/actions'
import {
  EventAction,
  EventCategory,
  EventName
} from 'modules/analytics/actions/trackEventV1'
import {getPageSpace} from 'modules/analytics/trackEventV1Helpers'
import {changeCurrency} from 'modules/localization/actions/changeCurrency'
import {changeLanguage} from 'modules/localization/actions/changeLanguage'
import {getAnonymousId} from 'modules/meta/selectors'
import {
  changeMembershipVisibility,
  changePreferencesVisibility,
  changeReferralVisibility
} from 'modules/overlay/actions'
import {getPreferencesVisibility} from 'modules/overlay/selectors'
import {toggle} from 'opticks'
import Settings from 'Settings'
import {getMyBookingsUrl} from 'utils/urls'

import {CompactHeader} from '@daedalus/atlas/CompactHeader'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/CompactHeader'
import {Divider} from '@daedalus/atlas/Divider'
import {Overlay} from '@daedalus/atlas/Overlay'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {
  getEnabledCurrencies,
  splitPopularAndOtherCurrencies
} from '@daedalus/core/src/localization/business/currency'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import availableLanguages from '@daedalus/core/src/localization/services/locale'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {getDevicePlatform} from '@daedalus/core/src/utils/userAgent'
import {useLogOut} from '@daedalus/shared/src/authentication/hooks/useLogOut'
import {PreferenceSelector} from '@daedalus/shared/src/user/preferences/PreferenceSelector'

type PropsType = {
  currentCurrency: string
  currentLanguage: string
  hasLanguageSelector?: boolean
  hasCurrencySelector?: boolean
  preferenceTitle?: JSX.Element
  hideMyBooking?: boolean
  hideGemsMembership?: boolean
  hideDebugPanel?: boolean
  hideDebugScreen?: boolean
  hidePrivacyPolicy?: boolean
  hideShareYourFeedback?: boolean
  hideNotification?: boolean
  hideWatchlist?: boolean
  hideReferral?: boolean
  pageName?: Page
  onCurrencyChange?: (currency: string) => void
}

const OverlaySection = styled.section`
  height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

const LogoutButton = styled.div`
  margin-top: auto;
  text-align: center;
  margin-bottom: ${cssTheme.layout.spacing.s700};
`

const SignOutText = styled(Text)`
  color: ${cssTheme.colors.content.danger.c600};
`

export const PreferencesOverlay = ({
  currentCurrency,
  currentLanguage,
  hasLanguageSelector,
  hasCurrencySelector,
  preferenceTitle,
  hideMyBooking,
  hideGemsMembership,
  hideDebugPanel,
  hideDebugScreen,
  hidePrivacyPolicy,
  hideNotification,
  hideWatchlist,
  hideReferral,
  pageName,
  onCurrencyChange
}: PropsType) => {
  const isPaymentPage = pageName === Page.Payment
  const dispatch = useDispatch()
  const urlParams = useParams()
  const {brand} = useBrand()

  const space = getPageSpace(window.location.pathname)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isPreferencesVisible = Boolean(useSelector(getPreferencesVisibility))
  const devicePlatform = getDevicePlatform()
  const logOut = useLogOut()
  const {trackMenuItemClick} = useTrackMenuItemClick()

  const {locale, userCountry} = urlParams
  const anonymousId = useSelector(getAnonymousId)
  const searchParams = {anonymousId, locale, userCountry}
  const myBookingsUrl = getMyBookingsUrl(brand, searchParams)
  const isWebview = isReactNativeWebView()

  /*
   * The My Bookings is hidden on the menu of the Native App
   */
  const isMyBookingsShown = !isReactNativeWebView() && isAuthenticated

  const handleLogoutClick = useCallback(async () => logOut(), [logOut])

  const enabledCurrencies = getEnabledCurrencies(
    Settings.get('REACT_APP_ENABLED_CURRENCIES')
  )
  const {currenciesList, popularCurrenciesList} =
    splitPopularAndOtherCurrencies(enabledCurrencies)

  const handleHidePreferences = () =>
    dispatch(changePreferencesVisibility(false, false, false)())

  const handleShowMembership = () => {
    trackMenuItemClick(MenuItemComponent.GemsMembership, Team.Retention)
    dispatch(changeMembershipVisibility(true)())
  }

  const handleShowReferralOverlay = () => {
    trackMenuItemClick(MenuItemComponent.Referral, Team.Retention)

    dispatch(changeReferralVisibility(true)())
  }

  const onChangeLanguage = async (language: string) => {
    dispatch(
      trackEventV1({
        name: EventName.LANGUAGE_CHANGE,
        category: EventCategory.USER_ACTION,
        space,
        action: EventAction.CLICK,
        value: language,
        urlParams
      })
    )

    if (isPaymentPage) {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.LanguageChange,
          action: Action.Submitted,
          page: Page.Payment,
          component: Component.Preferences,
          payload: {newValue: language}
        })
      )
    }

    setTimeout(() => {
      dispatch(changeLanguage(language))
    }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
  }

  const onChangeCurrency = (value: string) => {
    dispatch(
      trackEventV1({
        name: EventName.CURRENCY_CHANGE,
        category: EventCategory.USER_ACTION,
        space,
        action: EventAction.CLICK,
        value,
        urlParams
      })
    )

    if (isPaymentPage) {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.CurrencyChange,
          action: Action.Submitted,
          page: Page.Payment,
          component: Component.Preferences,
          payload: {newValue: value}
        })
      )
      onCurrencyChange?.(value)
    } else {
      setTimeout(() => {
        dispatch(changeCurrency(value))
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
    }
  }

  const onClickLanguage = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.MenuItem,
        action: Action.Clicked,
        page: Page.Payment,
        component: Component.Language
      })
    )
  }

  const onClickCurrency = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.MenuItem,
        action: Action.Clicked,
        page: Page.Payment,
        component: Component.Currency
      })
    )
  }

  return (
    <Overlay isOpen={isPreferencesVisible}>
      <CompactHeader
        centerContent={
          <Text variant="titleM">
            {preferenceTitle ? (
              preferenceTitle
            ) : (
              <FormattedMessageWrapper id="profile" defaultMessage="Profile" />
            )}
          </Text>
        }
        brand={brand}
        onBackButtonClick={handleHidePreferences}
        hasShadow={false}
        hasDivider
      />
      <OverlaySection>
        <UserSideMenuWithStore />
        <Divider />
        <PreferenceSelector
          availableLanguages={availableLanguages}
          currenciesList={currenciesList}
          currentLanguage={currentLanguage}
          currentCurrency={currentCurrency}
          devicePlatform={devicePlatform}
          hasCurrencySelector={hasCurrencySelector}
          hasLanguageSelector={hasLanguageSelector && !isWebview}
          myBookingsUrl={isMyBookingsShown ? myBookingsUrl : ''}
          popularCurrenciesList={popularCurrenciesList}
          showNotifications={toggle('d5a70091-notifications', false, true)}
          showPrivacyPolicy={toggle('09a2a5c7-privacy-policy', false, true)}
          showShareYourFeedback={isReactNativeWebView()}
          onChangeCurrency={onChangeCurrency}
          onClickCurrency={onClickCurrency}
          onClickLanguage={onClickLanguage}
          onChangeLanguage={onChangeLanguage}
          onShowMembershipOverlay={handleShowMembership}
          onShowReferralOverlay={handleShowReferralOverlay}
          hideMyBooking={hideMyBooking}
          hideGemsMembership={hideGemsMembership}
          hideDebugPanel={hideDebugPanel}
          hideDebugScreen={hideDebugScreen}
          hidePrivacyPolicy={hidePrivacyPolicy}
          hideShareYourFeedback={true}
          hideNotification={hideNotification}
          hideWatchlist={hideWatchlist}
          hideReferral={hideReferral}
        />
        {isAuthenticated && (
          <LogoutButton onClick={handleLogoutClick}>
            <SignOutText variant="labelM">
              <FormattedMessageWrapper
                dataId="LogOutProfileLink"
                id="userWidget.signOut"
                defaultMessage="Sign Out"
              />
            </SignOutText>
          </LogoutButton>
        )}
      </OverlaySection>
    </Overlay>
  )
}
