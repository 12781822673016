import React from 'react'
import {styled} from '@linaria/react'

import {PdLocked, PdUnlocked} from '@findhotel/atlas-assets'

export interface Props {
  /** Whether the Lock is opened or closed */
  isLocked?: boolean
  /** The size of the lock */
  size?: number
  /** The color of the lock */
  color?: string
}

const WrapperElement = styled.span<{size: number}>`
  font-size: ${({size}) => size}px;
  display: inline-flex;
  align-items: center;
`

export const Lock = ({
  isLocked = true,
  size = 16,
  color = 'currentColor'
}: Props) => {
  return (
    <WrapperElement size={size}>
      {isLocked ? (
        <PdLocked size={size} color={color} />
      ) : (
        <PdUnlocked size={size} color={color} />
      )}
    </WrapperElement>
  )
}
