import React, {ReactElement, useContext} from 'react'
import {useSelector} from 'react-redux'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'

import {Card} from '@daedalus/atlas/Card'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Divider} from '@daedalus/atlas/Divider'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {linariaMq} from '@daedalus/atlas/utils/breakpoints'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReferralShareButton} from '../../../../user/ReferralShareButton'
import {ReferralLinkContext} from '../../ReferralLinkProvider'
import {GemsBoxSvg} from '../GemsBoxImage'
import {CopyLinkButton} from './CopyLinkButton'

const CONTENT_MAX_WIDTH = 340
const ILLUSTRATION_DESKTOP_HEIGHT = 138
const ILLUSTRATION_MOBILE_HEIGHT = 102

const CardContent = styled.div`
  padding: ${cssTheme.layout.spacing.s900} ${cssTheme.layout.spacing.s700};
  &.isMobile {
    padding: ${cssTheme.layout.spacing.s700} ${cssTheme.layout.spacing.s600};
  }
`

const IllustrationWrapper = styled.div`
  height: ${ILLUSTRATION_MOBILE_HEIGHT}px;
  ${linariaMq.desktopSm} {
    padding: ${cssTheme.layout.spacing.s700};
    height: ${ILLUSTRATION_DESKTOP_HEIGHT}px;
  }
`

const TextWrapper = styled.div`
  margin-bottom: ${cssTheme.layout.spacing.s800};
  margin-top: ${cssTheme.layout.spacing.s500};
  text-align: center;
  ${linariaMq.desktopSm} {
    margin-top: 0;
    text-align: initial;
  }
`

const Title = styled(Text)`
  margin-bottom: ${cssTheme.layout.spacing.s500};
`

const StyledText = styled(Text)`
  ${linariaMq.desktopSm} {
    max-width: ${CONTENT_MAX_WIDTH}px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin-left: auto;
  margin-right: auto;
  ${linariaMq.desktopSm} {
    margin-left: 0;
  }
`

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${cssTheme.colors.content.neutral.c500};
  margin-top: ${cssTheme.layout.spacing.s600};
  margin-bottom: ${cssTheme.layout.spacing.s600};
  > p {
    margin-left: ${cssTheme.layout.spacing.s300};
    margin-right: ${cssTheme.layout.spacing.s300};
  }
`

const DividerContainer = styled.div`
  flex-grow: 1;
`

const DividerWithText = ({children}: {children: ReactElement}) => (
  <DividerWrapper>
    <DividerContainer>
      <Divider />
    </DividerContainer>
    <Text variant={'bodyS'}>{children}</Text>
    <DividerContainer>
      <Divider />
    </DividerContainer>
  </DividerWrapper>
)

const useContainerFlexDirection = () => {
  const {isDesktopXs, isMobile} = useDeviceLayout()
  if (isMobile) return 'column-reverse'
  return isDesktopXs ? 'column-reverse' : 'row'
}

const useCanRefer = () => {
  const {referralShortLink} = useContext(ReferralLinkContext)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  return isAuthenticated && referralShortLink
}

export const ReferralProgram = () => {
  const {isMobile} = useDeviceLayout()
  const showLinkCopyButton = useCanRefer()
  const {referralShortLink} = useContext(ReferralLinkContext)
  const containerFlexDirection = useContainerFlexDirection()
  const cardBorderRadius = isMobile ? undefined : 'md'
  const cardShadow = isMobile ? undefined : 'action'

  // Displaying it also in dev mode to make sure it's always visible in mobile-emulation for easier testing
  const canShare =
    Boolean(navigator.share) || process.env.NODE_ENV === 'development'

  return (
    <div>
      <Card borderRadius={cardBorderRadius} shadow={cardShadow}>
        <CardContent className={cx(isMobile && 'isMobile')}>
          <Grid
            container
            direction={containerFlexDirection}
            justify="space-between"
            alignItems="center"
          >
            <Grid mobileSm={12} desktopSm={8}>
              <TextWrapper>
                <Title variant="title2XL">
                  <FormattedMessageWrapper
                    id="membership.LetYourFriendsInOnTheSecret"
                    defaultMessage="Let your friends in on the secret..."
                  />
                </Title>
                <StyledText variant="bodyM">
                  {toggle(
                    'c9f92dea-new-tags-poc',
                    <FormattedMessageWrapper
                      id="membership.ShareThisLinkToGive"
                      defaultMessage="Share this link to give your friends instant access to Gems deals and savings of up to 50%."
                    />,
                    <FormattedMessageWrapper
                      id="vioDeals.shareLinkToFreeAccess"
                      defaultMessage="Share this link to give your friends free access to insider hotel deals and savings of up to 45% on their stays."
                    />
                  )}
                </StyledText>
              </TextWrapper>
              <ButtonsWrapper>
                {canShare && (
                  <>
                    {isMobile && (
                      <ReferralShareButton
                        isLoading={!showLinkCopyButton}
                        referralShortLink={referralShortLink}
                      />
                    )}
                    {isMobile && (
                      <DividerWithText>
                        <FormattedMessageWrapper
                          id="referralProgram.orCopy"
                          defaultMessage="or copy the link"
                        />
                      </DividerWithText>
                    )}
                  </>
                )}
                <CopyLinkButton
                  isLoading={!showLinkCopyButton}
                  referralShortLink={referralShortLink}
                />
              </ButtonsWrapper>
            </Grid>
            <IllustrationWrapper>
              <GemsBoxSvg />
            </IllustrationWrapper>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
