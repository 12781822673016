import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {styled} from '@linaria/react'
import {AnimatePresence, motion} from 'framer-motion'
import noop from 'lodash/noop'

import {useOnClickOutside} from '@daedalus/atlas/hooks/useOnClickOutside'
import {cssTheme} from '@daedalus/atlas/themes'
import {linariaMq} from '@daedalus/atlas/utils/breakpoints'
import {
  fadeInOutAndHide,
  transitions
} from '@daedalus/atlas/utils/framerTransitions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  TrackEventPayload
} from '@daedalus/core/src/analytics/types/Events'

import {CookieConsentBanner} from './CookieConsentBanner'
import {CookieBannerClickComponent, useCookieConsent} from './useCookieConsent'

const CookieBannerWrapper = styled(motion.div)`
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 101;
  padding: ${cssTheme.layout.spacing.s300} ${cssTheme.layout.spacing.s400};
  display: flex;
  justify-content: center;
  ${linariaMq.desktopXs} {
    padding: ${cssTheme.layout.spacing.s600};
  }
`

const shouldCookieBannerBeShown = (): boolean =>
  window.Osano?.cm?.dialogOpen ?? false

export interface CookieBannerProps {
  /**
   * Callback invoked when a user accepts the cookies, either in the
   * banner or the preference center
   */
  onAccept?: (acceptedByUser: boolean) => void
  trackEvent: (payload: TrackEventPayload) => void
}

export const CookieConsentBannerContainer = ({
  onAccept: setCookiesAcceptedByUser = noop,
  trackEvent
}: CookieBannerProps) => {
  const {locale} = useIntl()
  const ref = useRef(null)

  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(
    shouldCookieBannerBeShown
  )

  const onAcceptCookies = useCallback(() => {
    setIsCookieBannerVisible(false)
    setCookiesAcceptedByUser(true)
  }, [setIsCookieBannerVisible, setCookiesAcceptedByUser])

  const {
    trackBannerSubmitted,
    acceptAll,
    denyAll,
    openPreferencesCenter,
    cookieConsentContext,
    isPreferencesCenterVisible
  } = useCookieConsent(onAcceptCookies, trackEvent)

  const handleAcceptAllCookies = useCallback(() => {
    acceptAll()
    trackBannerSubmitted(CookieBannerClickComponent.AcceptButton)
  }, [acceptAll, setCookiesAcceptedByUser, trackBannerSubmitted])

  const handleRejectAllCookies = useCallback(
    (component?: CookieBannerClickComponent) => {
      if (isCookieBannerVisible) {
        denyAll()
        setIsCookieBannerVisible(false)
        setCookiesAcceptedByUser(false)
        trackBannerSubmitted(component)
      }
    },
    [
      isCookieBannerVisible,
      denyAll,
      setCookiesAcceptedByUser,
      trackBannerSubmitted
    ]
  )

  const trackBannerShown = () => {
    if (!isCookieBannerVisible) return

    trackEvent({
      category: Category.System,
      entity: Entity.CookieBanner,
      action: Action.Displayed,
      analyticsContext: {
        [AnalyticsContext.CookieConsentContext]: cookieConsentContext
      }
    })
  }

  useOnClickOutside(ref, () => {
    if (!isPreferencesCenterVisible) {
      handleRejectAllCookies(CookieBannerClickComponent.AppPage)
    }
  })

  useEffect(trackBannerShown, [isCookieBannerVisible, trackEvent])
  useEffect(() => {
    const sanitizedLocale = locale.split('-')[0]
    if (window.Osano?.cm) {
      window.Osano.cm.locale = sanitizedLocale
    }
  }, [locale])

  return (
    <AnimatePresence>
      {isCookieBannerVisible && (
        <CookieBannerWrapper
          key="CookieBannerWrapper"
          ref={ref}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={fadeInOutAndHide}
          transition={transitions.easeInOut}
        >
          <CookieConsentBanner
            openPreferencesCenter={openPreferencesCenter}
            acceptAllCookies={handleAcceptAllCookies}
            rejectAllCookies={handleRejectAllCookies}
          />
        </CookieBannerWrapper>
      )}
    </AnimatePresence>
  )
}
