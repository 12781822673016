import {MetaType} from 'types/Meta'
import {$PropertyType} from 'utility-types'

import {DeviceType} from '@findhotel/sapi'

export const TRACK_PAGE_VIEW = 'TRACK_PAGEVIEW'
export const TRACK_CIO_PAGE_VIEW = 'TRACK_CIO_PAGE_VIEW'

interface Meta extends MetaType {
  anonymousId: string
  userId?: string
}

export type ActionType = {
  meta: Meta
  pageName: string
  type: typeof TRACK_PAGE_VIEW
}

type CIOPageViewParamsType = {
  anonymousId?: string
  cancellationDeadline?: number | null
  checkIn?: number
  checkOut?: number
  currency: string
  hotelAddress?: string
  hotelId?: string
  hotelName?: string
  id?: string
  isUserSearch?: boolean
  locale: string
  nights?: number
  offerId?: string
  hotelImageUrl?: string
  pageUrl: string
  providerCode?: string
  roomName?: string
  rooms?: string
  savingPercentage?: number
  savingTotal?: number
  starRating?: number
  totalPrice?: number
  isSoldOutOrError?: boolean
  deviceType?: DeviceType
}

export type CIOActionType = {
  params: CIOPageViewParamsType
  pageName: string
  type: typeof TRACK_CIO_PAGE_VIEW
}

export const trackPageView = (
  pageName: $PropertyType<ActionType, 'pageName'>,
  meta: $PropertyType<ActionType, 'meta'>
): ActionType => ({
  meta,
  pageName,
  type: TRACK_PAGE_VIEW
})

export const trackCIOPageView = (
  pageName: $PropertyType<CIOActionType, 'pageName'>,
  params: $PropertyType<CIOActionType, 'params'>
): CIOActionType => ({
  params,
  pageName,
  type: TRACK_CIO_PAGE_VIEW
})
