import React from 'react'
import {styled} from '@linaria/react'

import {CompactHeader} from '@daedalus/atlas/CompactHeader'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/CompactHeader'
import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {cssTheme} from '@daedalus/atlas/themes'
import {Brand} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {AccountContent} from './AccountContent'
import {TopBarSubmitButton} from './TopBarSubmitButton'

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid ${cssTheme.colors.border.neutral.c100};
`

const HeaderContentWrapper = styled.div`
  font-family: ${cssTheme.fonts.family.default};
  font-weight: ${cssTheme.fonts.weight.lg};
  line-height: ${cssTheme.fonts.lineHeight.xs};
  font-size: ${cssTheme.fonts.size.md};
  color: ${cssTheme.colors.content.neutral.c950};
`

const OverlaySection = styled.section`
  height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

interface AccountOverlayPropsType {
  isOpen: boolean
  onClose: () => void
  brand: Brand
}

export const AccountOverlay = ({
  isOpen,
  onClose,
  brand
}: AccountOverlayPropsType) => (
  <Overlay
    isOpen={isOpen}
    animationType={ANIMATION_TYPES.SLIDE_RIGHT}
    shadeBackground={false}
    onClose={onClose}
  >
    <HeaderWrapper>
      <CompactHeader
        brand={brand}
        onBackButtonClick={onClose}
        endContent={<TopBarSubmitButton />}
        centerContent={
          <HeaderContentWrapper>
            <FormattedMessageWrapper
              id="account.editAccount"
              defaultMessage="Edit Account"
            />
          </HeaderContentWrapper>
        }
      />
    </HeaderWrapper>
    <OverlaySection>
      <AccountContent brand={brand} />
    </OverlaySection>
  </Overlay>
)
