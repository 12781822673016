import React, {Suspense} from 'react'
import {useSelector} from 'react-redux'
import {Router} from 'react-router-dom'
// eslint-disable-next-line no-restricted-imports
import {CacheProvider, ThemeProvider} from '@emotion/react'
import GetTheme from 'components/data/GetTheme'
import useParams from 'components/data/URLParams/useParams'
import ErrorOverlayMonitor from 'components/errorMonitors/ErrorOverlayMonitor'
import NetworkMonitor from 'components/errorMonitors/NetworkMonitor'
import {FetchIpInfo} from 'components/FetchIpInfo'
import InitializeABTesting from 'components/InitializeABTesting'
import InitializeSentry from 'components/InitializeSentry'
import {InjectHeadData} from 'components/InjectHeadData'
import {PasswordlessLoginWrapper} from 'components/PasswordlessLoginWrapper'
import UnknownErrorBoundary from 'components/UnknownErrorBoundary'
import Settings from 'Settings'
import {ThemeType} from 'types/Theme'
import {history} from 'utils/history'
import {publicUrl} from 'utils/urls'

import {
  DeviceLayoutProvider,
  useDeviceLayout
} from '@daedalus/atlas/context/deviceLayout'
import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import FontsLoader from '@daedalus/atlas/helpers/FontsLoader'
import {CSSThemeProvider} from '@daedalus/atlas/themes/CSSThemeProvider'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {RouterProvider} from '@daedalus/core/src/_web/router/context/RouterProvider'
import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {useSyncToCookieFromUrlParam} from '@daedalus/core/src/_web/utils/cookies/hooks/useSyncToCookieFromUrlParam'
import {evaluateInRuntime} from '@daedalus/core/src/_web/utils/css/emotion-rtl'
import InitializeDataDogClient from '@daedalus/core/src/analytics/components/DataDogClient'
import {InjectFullStoryBodyClass} from '@daedalus/core/src/analytics/components/FullStoryLoader'
import {InitializeCustomerIo} from '@daedalus/core/src/analytics/components/InitializeCustomerIo'
import {TeamContextProvider} from '@daedalus/core/src/analytics/components/TeamContext'
import TrackScreenshot from '@daedalus/core/src/analytics/components/TrackScreenshot'
import {TrackWebVitals} from '@daedalus/core/src/analytics/components/TrackWebVitals'
import {Team} from '@daedalus/core/src/analytics/types/Events'
import {FetchUser} from '@daedalus/core/src/auth/components/FetchUser'
import SyncStateWithPrivilege from '@daedalus/core/src/auth/components/SyncStateWithPrivilege'
import TrackUserAuthentication from '@daedalus/core/src/auth/components/TrackUserAuthentication'
import OpticksProvider, {
  experimentVariationsStore
} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import ConfigureI18n from '@daedalus/core/src/localization/components/ConfigureI18n'
import InjectMetaDescription from '@daedalus/core/src/localization/components/InjectMetaDescription'
import LanguageConfig from '@daedalus/core/src/localization/components/LanguageConfig'
import {getTextDirection} from '@daedalus/core/src/localization/services/locale'
import {GetBoVioConfiguration} from '@daedalus/core/src/offer/services/offerConfigurationApi/GetBoVioConfiguration'
import {DebugPanelModules, DebugPanelProvider} from '@daedalus/debug-panel'
import {SusiProvider} from '@daedalus/shared/src/authentication/SUSI/bottomSheet/SusiBottomSheet/SusiProvider'
import {CookieConsentBannerWithStore} from '@daedalus/shared/src/search/cookies'

import {getAnonymousId} from './modules/meta/selectors'
import Routes from './routes'

const DEFAULT_LANGUAGE = 'en'
const FORCE_DISABLE_OPTIMIZELEY_LOG_KEY = 'force-disable-optimizely-logs'

const App = () => {
  const urlParams = useParams()
  const {brand, brandIsCookieConsentEnabled, brandThemeName} = useBrand()
  const {locale: urlLocale, userCountry} = urlParams
  const locale = urlLocale || getCookie('locale') || DEFAULT_LANGUAGE
  const {isRtl} = getTextDirection(locale)
  const anonymousId = useSelector(getAnonymousId)
  useSyncToCookieFromUrlParam('locale', urlLocale)
  const appEnv = Settings.get('REACT_APP_ENV')
  const customerIoSiteId = Settings.get('WEBSITE_CUSTOMER_IO_TRACKING_SITE_ID')
  const boVioEndpoint = Settings.get('REACT_APP_BOFH_ENDPOINT')

  const {isMobile} = useDeviceLayout()
  const datafile = useSelector(getDataFile) as {
    featureFlags: Array<{key: string}>
  }
  const optimizelyFlags = datafile?.featureFlags?.map(({key}) => key) || []

  const forceDisableOptimizelyLog =
    localStorage.getItem(FORCE_DISABLE_OPTIMIZELEY_LOG_KEY) === 'true'

  return (
    <>
      <InjectHeadData />
      <InitializeDataDogClient
        clientToken={Settings.get('REACT_APP_DD_CLIENT_TOKEN')}
        applicationId={Settings.get('REACT_APP_DD_RUM_APP_ID')}
        appVersion={Settings.get('REACT_APP_VERSION')}
        appEnv={Settings.get('REACT_APP_ENV')}
        sampleRate={Number(Settings.get('REACT_APP_DD_SAMPLE_RATE'))}
        premiumSampleRate={Number(
          Settings.get('REACT_APP_DD_REPLAY_SAMPLE_RATE')
        )}
        service="checkout"
      />
      <TrackScreenshot />
      <TrackWebVitals />
      <Suspense fallback="">
        <OpticksProvider
          dataFileUrl={Settings.get('REACT_APP_OPTIMIZELY_DATAFILE_URL')}
          disableOptimizelyLogs={forceDisableOptimizelyLog}
        >
          <FetchIpInfo>
            <InitializeABTesting>
              <LanguageConfig locale={locale} />
              <CacheProvider value={evaluateInRuntime(isRtl)}>
                <ConfigureI18n languageCode={locale} countryCode={userCountry}>
                  <DeviceLayoutProvider>
                    <GetTheme themeName={brandThemeName}>
                      {(theme: ThemeType) => (
                        <ThemeProvider theme={theme}>
                          <CSSThemeProvider isGlobal themeName={brandThemeName}>
                            <InitializeSentry />
                            <InitializeCustomerIo
                              publicUrl={publicUrl}
                              customerIoSiteId={customerIoSiteId}
                            />
                            <CssBaseline />
                            <GridGlobalStyles />
                            <NetworkMonitor />
                            <FontsLoader brand={brand} languageCode={locale} />
                            <InjectFullStoryBodyClass />
                            <UnknownErrorBoundary>
                              <InjectMetaDescription brandName={brand.name} />
                              <Router history={history}>
                                <RouterProvider>
                                  <SyncStateWithPrivilege />
                                  <SusiProvider
                                    brand={brand}
                                    userCountryCode={userCountry}
                                  >
                                    <DebugPanelProvider
                                      isMobile={isMobile}
                                      appEnv={appEnv}
                                      thirdPartyExperiments={optimizelyFlags}
                                      meta={{anonymousId: anonymousId || ''}}
                                      variationsStoreReader={
                                        experimentVariationsStore
                                      }
                                      modulesConfig={[
                                        DebugPanelModules.EXPERIMENTS,
                                        DebugPanelModules.SETTINGS
                                      ]}
                                      isSyntheticTest={false}
                                    >
                                      <Routes />
                                    </DebugPanelProvider>
                                  </SusiProvider>
                                  <ErrorOverlayMonitor />
                                  <PasswordlessLoginWrapper
                                    brand={brand}
                                    languageCode={locale}
                                  />
                                  <TeamContextProvider team={Team.Select}>
                                    {brandIsCookieConsentEnabled && (
                                      <CookieConsentBannerWithStore />
                                    )}
                                  </TeamContextProvider>
                                  <FetchUser publicUrl={publicUrl} />
                                  <TrackUserAuthentication />
                                  <GetBoVioConfiguration
                                    boVioUrl={boVioEndpoint}
                                    brandCode={brand.code}
                                    appEnv={appEnv}
                                    urlParams={urlParams}
                                  />
                                </RouterProvider>
                              </Router>
                            </UnknownErrorBoundary>
                          </CSSThemeProvider>
                        </ThemeProvider>
                      )}
                    </GetTheme>
                  </DeviceLayoutProvider>
                </ConfigureI18n>
              </CacheProvider>
            </InitializeABTesting>
          </FetchIpInfo>
        </OpticksProvider>
      </Suspense>
    </>
  )
}

export default App
