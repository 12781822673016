import React, {ReactNode} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import * as iconsList from '@findhotel/atlas-assets'

import {cssTheme} from '../../../themes'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import {Icon, IconSize} from '../Icon'

export const BADGE_VARIANTS = [
  'info',
  'neutral',
  'success',
  'warning',
  'danger'
] as const

export const BADGE_SIZES = ['xs', 'sm', 'md'] as const

export type BadgeVariant = (typeof BADGE_VARIANTS)[number]
export type BadgeSize = (typeof BADGE_SIZES)[number]

interface Props {
  /** Content to be displayed in the Badge */
  children?: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Icon used instead of children font */
  iconName?: keyof typeof iconsList
  /** The size of the component */
  size?: BadgeSize
  /** The variation of styling the component will have */
  variant?: BadgeVariant
}

interface StyleProps {
  iconName: Props['iconName']
  size: BadgeSize
  variant: BadgeVariant
}

interface SizeMapItem {
  iconSize: IconSize
  iconPadding: number
  size: number
}

const sizeMap: Record<string, SizeMapItem> = {
  xs: {iconSize: 'xs', iconPadding: 2, size: 16},
  sm: {iconSize: 'sm', iconPadding: 2, size: 20},
  md: {iconSize: 'sm', iconPadding: 4, size: 24}
}

const getSizeStyles = (size: BadgeSize) => {
  return `
    &--size-${size} {
      height: ${sizeMap[size].size}px;
      min-width: ${sizeMap[size].size}px;
      &.withIcon {
        padding: 0 ${sizeMap[size].iconPadding}px;
      }
    }
  `
}

const getVariantStyles = (variant: BadgeVariant) => {
  return `
    &--variant-${variant} {
      color: ${cssTheme.colors.badge[variant].content};
      background-color: ${cssTheme.colors.badge[variant].background};
    }
  `
}

export const Wrapper = styled.div<StyleProps>`
  ${BADGE_VARIANTS.map(getVariantStyles).join('')}
  ${BADGE_SIZES.map(getSizeStyles).join('')}
  ${cssTheme.typography.text.labelXS}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${cssTheme.layout.radius.rounded};
  padding: 0 ${cssTheme.layout.spacing.s200};
`

export const Badge = ({
  children,
  iconName,
  size = 'xs',
  variant = 'info',
  className
}: Props) => (
  <Wrapper
    iconName={iconName}
    size={size}
    variant={variant}
    className={cx(
      `${getLinariaClassName(Wrapper)}--variant-${variant}`,
      `${getLinariaClassName(Wrapper)}--size-${size}`,
      iconName && 'withIcon',
      className
    )}
  >
    {iconName ? (
      <Icon name={iconName} size={sizeMap[size].iconSize} />
    ) : (
      children
    )}
  </Wrapper>
)
