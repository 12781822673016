import React from 'react'
import {styled} from '@linaria/react'

import {cssTheme} from '@daedalus/atlas/themes'
import {hexToRGBAString} from '@daedalus/core/src/utils/css'

type ToasterMessagePropsType = {
  children: React.ReactNode
}

const Wrapper = styled('div')`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  overflow: hidden;
  background: ${hexToRGBAString(cssTheme.colors.background.neutral.c000, 0.4)};
`

const Message = styled('div')`
  color: ${cssTheme.colors.background.neutral.c000};
  line-height: ${cssTheme.fonts.lineHeight.lg};
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${cssTheme.colors.background.neutral.c600};
  padding: ${cssTheme.layout.spacing.s500};
`

const ToasterMessage = ({children}: ToasterMessagePropsType) => {
  return (
    <Wrapper>
      <Message>{children}</Message>
    </Wrapper>
  )
}

export default ToasterMessage
