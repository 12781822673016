import React, {ReactNode} from 'react'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {Text, TextVariant} from '../Text'

interface Props {
  /** Pass through className to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the radio is checked or not */
  checked: boolean
  /** Whether the radio is disabled or not */
  disabled?: boolean
  /** Whether the radio container should render full width */
  fullWidth?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** Content to render in label next to the radio */
  label?: ReactNode
  /** Name used for html input element */
  name: string
  /** The Radio change handler. Fires when user change state of Radio */
  onChange?: (e: React.SyntheticEvent) => void
  /** Value used for html input element */
  value?: string
  /** Use larger touch area */
  hasTouchArea?: boolean
  /** Label size */
  labelSize?: Extract<TextVariant, 'bodyM' | 'bodyS'>
}

const RADIO_SIZE = 20
const RADIO_DOT_SIZE = 10
const LABEL_MARGIN_LEFT = 10
const TOUCHAREA_SIZE_LG = 12

const RadioElement = styled.div`
  display: flex;
`

export const RadioContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${LABEL_MARGIN_LEFT}px;
`

export const RadioIcon = styled.div`
  position: relative;
  width: ${RADIO_SIZE}px;
  min-width: ${RADIO_SIZE}px;
  height: ${RADIO_SIZE}px;
  background-color: ${cssTheme.colors.inputControls.default.background};
  border: 1px solid ${cssTheme.colors.inputControls.default.border};
  border-radius: ${cssTheme.layout.radius.rounded};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: '';
    width: ${RADIO_DOT_SIZE}px;
    height: ${RADIO_DOT_SIZE}px;
    background-color: ${cssTheme.colors.inputControls.default.background};
    border-radius: ${cssTheme.layout.radius.rounded};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
  }
`

export const RadioInput = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: ${RADIO_SIZE + TOUCHAREA_SIZE_LG}px;
  opacity: 0;
  width: 0;
  height: 0;

  ${linariaMq.desktopXs} {
    &:focus-visible + ${RadioIcon} {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:active:not(:disabled) {
    & + ${RadioIcon} {
      background-color: ${cssTheme.colors.inputControls.default.active
        .background};
      border-color: ${cssTheme.colors.inputControls.default.active.border};
    }

    &:checked + ${RadioIcon} {
      background-color: ${cssTheme.colors.inputControls.checked.active
        .background};
      border-color: ${cssTheme.colors.inputControls.checked.active.border};
    }

    &[data-field-error='true'] + ${RadioIcon} {
      background-color: ${cssTheme.colors.inputControls.error.active
        .background};
      border-color: ${cssTheme.colors.inputControls.error.active.border};

      &:after {
        background-color: ${cssTheme.colors.inputControls.error.active.border};
      }
    }
  }

  &:checked + ${RadioIcon} {
    background-color: ${cssTheme.colors.inputControls.checked.background};
    border-color: ${cssTheme.colors.inputControls.checked.border};
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &[data-field-error='true'] + ${RadioIcon} {
    background-color: ${cssTheme.colors.inputControls.error.background};
    border-color: ${cssTheme.colors.inputControls.error.border};

    &:after {
      background-color: ${cssTheme.colors.inputControls.error.border};
    }
  }

  &:disabled + ${RadioIcon} {
    background-color: ${cssTheme.colors.inputControls.disabled.background};
    border-color: ${cssTheme.colors.inputControls.disabled.border};
    cursor: not-allowed;

    &:after {
      background-color: ${cssTheme.colors.inputControls.disabled.icon};
    }
  }
  &:disabled ~ ${RadioContent} {
    cursor: not-allowed;
  }
`

const getRadioBodyMargin = (
  hasTouchArea?: boolean,
  labelSize?: TextVariant
) => {
  if (!hasTouchArea) return `0`

  if (hasTouchArea && labelSize === 'bodyM')
    return `${cssTheme.layout.spacing.s300} 0`

  if (hasTouchArea && labelSize === 'bodyS')
    return `${cssTheme.layout.spacing.s400} 0 0`

  return `${cssTheme.layout.spacing.s100}`
}

export const RadioBody = styled.label<
  Pick<Props, 'hasTouchArea' | 'fullWidth' | 'labelSize'>
>`
  display: flex;
  position: relative;
  padding: ${cssTheme.layout.spacing.s100};
  margin: ${({hasTouchArea, labelSize}) =>
    getRadioBodyMargin(hasTouchArea, labelSize)};

  ${({fullWidth}) => (fullWidth ? 'flex: 1;' : '')}

  ${linariaMq.desktopXs} {
    &:hover {
      ${RadioInput}:not(:disabled):not(:active) {
        & + ${RadioIcon} {
          border-color: ${cssTheme.colors.inputControls.default.hover.border};
        }

        &:checked + ${RadioIcon} {
          background-color: ${cssTheme.colors.inputControls.checked.hover
            .background};
          border-color: ${cssTheme.colors.inputControls.checked.hover.border};
        }

        &[data-field-error='true'] + ${RadioIcon} {
          background-color: ${cssTheme.colors.inputControls.error.hover
            .background};
          border-color: ${cssTheme.colors.inputControls.error.hover.border};
          &:after {
            background-color: ${cssTheme.colors.inputControls.error.hover
              .border};
          }
        }
      }
    }
  }

  @supports selector(:has(input:disabled)) {
    cursor: pointer;
    :has(input:disabled) {
      cursor: not-allowed;
    }
  }
`

export const StyledText = styled(Text)`
  line-height: ${RADIO_SIZE}px;
`

export const Radio = ({
  checked,
  value,
  name,
  dataId,
  onChange,
  label,
  disabled,
  fullWidth = false,
  hasError = false,
  hasTouchArea = false,
  className,
  labelSize = 'bodyS'
}: Props) => (
  <RadioElement className={className}>
    <RadioBody
      hasTouchArea={hasTouchArea}
      fullWidth={fullWidth}
      labelSize={labelSize}
    >
      <RadioInput
        type="radio"
        name={name}
        data-id={dataId}
        checked={checked}
        value={value}
        disabled={disabled}
        data-field-error={hasError}
        onChange={onChange}
      />
      <RadioIcon />
      {Boolean(label) && (
        <RadioContent>
          <StyledText variant={labelSize} tag="span">
            {label}
          </StyledText>
        </RadioContent>
      )}
    </RadioBody>
  </RadioElement>
)
