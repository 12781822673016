import {RoomPrice} from '@findhotel/sapi'

import {Price as BookingPrice} from '../../api-types/bovio/response/booking'
import {
  PayAtHotel,
  Price as OfferCheckPrice
} from '../../api-types/bovio/response/offer_check'
import {Chargeable, OfferPrice} from '../../offer/types/offer'
import PriceTypes from '../../offer/types/PriceTypes'
import {toFloatWithTwoDecimals} from '../../utils/number'

export type Price = OfferPrice | BookingPrice | OfferCheckPrice | RoomPrice

export enum ShortCurrencySymbols {
  EUR = '€',
  GBP = '£',
  ILS = '₪',
  INR = '₹',
  JPY = '¥',
  KRW = '₩',
  PHP = '₱',
  USD = '$'
}

export const HOTEL_FEES_RESORT_FEE = 'resort_fee'
export const HOTEL_FEES_TAX = 'tax'
export const HOTEL_FEES_OTHER = 'other'

type HotelFee =
  | typeof HOTEL_FEES_RESORT_FEE
  | typeof HOTEL_FEES_TAX
  | typeof HOTEL_FEES_OTHER

interface HasPayAtHotel {
  payAtHotel?: PayAtHotel
}

// Type guard to check if payAtHotel exists
export const hasPayAtHotel = (
  price: Price | RoomPrice['chargeable'] | undefined
): price is Price & HasPayAtHotel => {
  return Boolean(price && 'payAtHotel' in price)
}

// Type guard to check if chargeable has currency
const hasCurrency = (
  chargeable: Chargeable | RoomPrice['chargeable']
): chargeable is Chargeable & {currency: string} => {
  return 'currency' in chargeable
}

// Type guard to check if chargeable has currencyCode
const hasCurrencyCode = (
  chargeable: Chargeable | RoomPrice['chargeable']
): chargeable is Chargeable & {currencyCode: string} => {
  return 'currencyCode' in chargeable
}

/**
 * Since BoVio and SAPI return the currency in different ways, this helper conditionally extracts the currency code from the price object.
 * @param price - The price object.
 * @returns The currency code as a string, or undefined if not available.
 */
export const getPriceCurrencyCode = (price?: Price): string | undefined => {
  if (price?.chargeable) {
    const {chargeable} = price

    if (hasCurrency(chargeable)) {
      return chargeable.currency
    }
    if (hasCurrencyCode(chargeable)) {
      return chargeable.currencyCode
    }
  }

  return price?.currencyCode
}

export const getPriceByType = <T extends Price>(
  type: string,
  prices: T[]
): T | undefined => {
  return prices.find(price => price.type === type)
}

/**
 * Check whether is there a Currency Conversion
 * A currency conversion is detected when a set of prices contains a DISPLAY_CURRENCY or USER_CURRENCY price type
 * @param prices - The list of offer/booking prices
 */
export const isThereACurrencyConversion = (prices: Price[]) => {
  return Boolean(
    getPriceByType(PriceTypes.DISPLAY_PRICE, prices) ||
      getPriceByType(PriceTypes.USER_CURRENCY, prices)
  )
}

/**
Returns the price the user sees. Not necessarily the price in which
he/she was or will be charged
* @param prices - The list of offer/booking prices
 */
export const getDisplayPrice = <T extends Price>(
  prices: T[]
): T | undefined => {
  if (isThereACurrencyConversion(prices)) {
    return (
      getPriceByType(PriceTypes.DISPLAY_PRICE, prices) ||
      getPriceByType(PriceTypes.USER_CURRENCY, prices)
    )
  }

  return getChargeableCurrencyPrice(prices)
}

/**
 * Returns the CHARGEABLE_PRICE if available, otherwise falls back to CHARGEABLE_CURRENCY.
 * @param prices - List of offer/booking prices
 */
export const getChargeableCurrencyPrice = <T extends Price>(
  prices: T[]
): T | undefined => {
  return (
    getPriceByType(PriceTypes.CHARGEABLE_PRICE, prices) ||
    getPriceByType(PriceTypes.CHARGEABLE_CURRENCY, prices)
  )
}

/**
 Returns the total price including hotel fees regardless of tax display logic
 * @param price - The price object
 */
export const getTotalPrice = (price?: Price | RoomPrice) => {
  const total = Number(price?.chargeable.base) + Number(price?.chargeable.taxes)
  const hotelFees = Number(price?.hotelFees?.total) || 0
  return toFloatWithTwoDecimals(total + hotelFees) || 0
}

/**
 * Returns the nightly price from any total
 */
export const getNightlyPrice = (
  price: number | null | undefined | string,
  numberOfNights: number,
  numberOfRooms = 1
) => {
  if (Number.isNaN(price)) return null

  return toFloatWithTwoDecimals(Number(price) / numberOfNights / numberOfRooms)
}

/**
 * to convert price types.
 * This converts the price types we receive in the sapi.rooms() and /bookings request to the ones we receive in /offer-check
 * The objective of this function is to provide backwards compatibility to the old price types
 * @internal
 * @param price - The list of offer prices
 */
export const convertPricesToNewFormat = <T extends Price>(price: T): T => {
  const conversionList = [
    {from: PriceTypes.CHARGEABLE_CURRENCY, to: PriceTypes.CHARGEABLE_PRICE},
    {
      from: PriceTypes.USER_CURRENCY,
      to: PriceTypes.DISPLAY_PRICE
    }
  ]

  const copyPrice = {...price}
  for (const mappingItem of conversionList) {
    if (copyPrice.type === mappingItem.from) {
      // eslint-disable-next-line fp/no-mutation
      copyPrice.type = mappingItem.to
    }
  }
  return copyPrice
}

/**
 Returns the total price excluding hotel fees regardless of tax display logic, unless it is a pay at property deal, then it returns 0
 */
export const getTotalUpFrontPrice = (
  price?: Price,
  isPayAtProperty?: boolean
) => {
  if (isPayAtProperty || !price) return 0
  return toFloatWithTwoDecimals(
    Number(price.chargeable.base) + Number(price.chargeable.taxes)
  )
}

/**
 Returns the total hotel fees, unless it is a pay at property deal, then it returns the total price
 * @param price - an offer price object
 * @param isPayAtProperty - a boolean that represents if the offer is pay at property
 */
export const getTotalAtProperty = (
  price?: Price,
  isPayAtProperty?: boolean
) => {
  if (isPayAtProperty) return getTotalPrice(price)
  return toFloatWithTwoDecimals(price?.hotelFees?.total || 0)
}

/**
 * Returns the total price to be paid upfront in cents
 * @param price - an offer price object
 * @param canPayLater - a boolean that represents if the offer is pay at property
 */
export const getTotalUpFrontPriceAsCents = (
  price: Price,
  canPayLater: boolean
) => {
  return Math.round(Number(getTotalUpFrontPrice(price, canPayLater)) * 100)
}

const getHotelFeeByType = (type: HotelFee, price?: Price) =>
  Number(
    price?.hotelFees?.breakdown?.find(fee => fee.type === type)?.total || 0
  )

export const getHotelFeesResortFee = (price?: Price) =>
  getHotelFeeByType(HOTEL_FEES_RESORT_FEE, price)
export const getHotelFeesTax = (price?: Price) =>
  getHotelFeeByType(HOTEL_FEES_TAX, price)
export const getHotelFeesOther = (price?: Price) =>
  getHotelFeeByType(HOTEL_FEES_OTHER, price)

/**
 * Returns the different pricing and currency information for an offer
 * @param prices - List of prices for an offer
 * @param isPayAtProperty - a boolean that represents if the offer is pay at property
 */
export const getPriceDetails = (prices: Price[], isPayAtProperty: boolean) => {
  const displayPrice = getDisplayPrice(prices)

  if (!displayPrice)
    return {
      totalDisplayPrice: 0,
      totalUpFrontDisplayPrice: 0,
      totalAtPropertyDisplayPrice: 0,
      totalUpFrontChargeablePrice: 0,
      totalAtPropertyChargeablePrice: 0,
      displayCurrencyCode: undefined,
      chargeableCurrencyCode: undefined,
      taxesDisplayPrice: 0,
      hotelFeesResortFee: 0,
      hotelFeesTax: 0,
      hotelFeesOther: 0,
      hotelFeesTotal: 0
    }

  const {taxes: taxesDisplayPrice} = displayPrice.chargeable

  // SAPI returns currency as currencyCode and BoVio returns as `currency`
  const displayCurrencyCode = getPriceCurrencyCode(displayPrice)

  const totalDisplayPrice = getTotalPrice(displayPrice)
  const hotelFeesResortFee = getHotelFeesResortFee(displayPrice) ?? 0
  const hotelFeesTax = getHotelFeesTax(displayPrice) ?? 0
  const hotelFeesOther = getHotelFeesOther(displayPrice) ?? 0

  const chargeablePrice = getChargeableCurrencyPrice(prices)
  const chargeableCurrencyCode = getPriceCurrencyCode(chargeablePrice)

  const payAtHotel = hasPayAtHotel(chargeablePrice)
    ? chargeablePrice.payAtHotel
    : undefined

  const totalAtPropertyDisplayPrice = getTotalAtProperty(
    displayPrice,
    isPayAtProperty
  )
  const totalUpFrontDisplayPrice = getTotalUpFrontPrice(
    displayPrice,
    isPayAtProperty
  )

  const totalUpFrontChargeablePrice = getTotalUpFrontPrice(
    chargeablePrice,
    isPayAtProperty
  )

  const totalAtPropertyChargeablePrice = payAtHotel
    ? payAtHotel.total
    : getTotalAtProperty(chargeablePrice, isPayAtProperty)

  return {
    totalDisplayPrice: totalDisplayPrice ?? 0,
    totalUpFrontDisplayPrice: totalUpFrontDisplayPrice ?? 0,
    totalAtPropertyDisplayPrice: totalAtPropertyDisplayPrice ?? 0,
    totalUpFrontChargeablePrice: totalUpFrontChargeablePrice ?? 0,
    totalAtPropertyChargeablePrice: totalAtPropertyChargeablePrice ?? 0,
    displayCurrencyCode,
    chargeableCurrencyCode,
    taxesDisplayPrice: toFloatWithTwoDecimals(taxesDisplayPrice) ?? 0,
    hotelFeesResortFee,
    hotelFeesTax,
    hotelFeesOther,
    hotelFeesTotal: hotelFeesResortFee + hotelFeesTax + hotelFeesOther
  }
}

/**
 * Calculates the nightly price, taking into account the number of rooms and whether
 * the search is for multi-room bundles.
 *
 * @param nightlyPrice - The nightly price.
 * @param isMultiRoomBundlesSearch - Indicates whether the search includes multi-room bundles.
 * @param numberOfRooms - The total number of rooms in the search. Defaults to 1.
 */
export const getNightlyPriceForAllRooms = (
  price: number | null | undefined | string,
  isMultiRoomBundlesSearch: boolean,
  numberOfRooms = 1
) => {
  if (!price || Number.isNaN(price)) return null
  if (!isMultiRoomBundlesSearch && numberOfRooms > 1)
    return Number(price) * numberOfRooms
  return Number(price)
}
