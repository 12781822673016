import React from 'react'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'

import {Badge} from '@daedalus/atlas/Badge'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {linariaMq} from '@daedalus/atlas/utils/breakpoints'
import {Brand} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {BrandLogo} from '../../../../../whiteLabel/BrandLogo'
import {PrivateDealTag} from '../../../../PrivateDealTag'
import {DiscoverGemsButton} from '../DiscoverGemsButton'
import gemsBoxImage from './images/gemsBox.svg'
import whatIsGemsImage from './images/whatIsGemsMobile.svg'

const VioLogo = styled(BrandLogo)`
  height: ${cssTheme.layout.spacing.s700};
  margin-top: ${cssTheme.layout.spacing.s600};
`

const GemsIcon = styled(Icon)`
  margin-top: ${cssTheme.layout.spacing.s400};
`

const GemsBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    button {
      margin-left: ${cssTheme.layout.spacing.s200};
      margin-right: ${cssTheme.layout.spacing.s200};
    }
    svg {
      display: flex;
    }
  }
`

const GemsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${cssTheme.colors.background.neutral.c000} url(${gemsBoxImage})
    right bottom no-repeat;
  border: 1px solid ${cssTheme.colors.border.neutral.c100};
  border-radius: ${cssTheme.layout.spacing.s300};
  padding: ${cssTheme.layout.spacing.s600} ${cssTheme.layout.spacing.s500};
  margin-top: ${cssTheme.layout.spacing.s600};
  width: 100%;
  ${GemsBoxRow} {
    margin-bottom: ${cssTheme.layout.spacing.s600};
  }
  ${linariaMq.desktopXs} {
    margin-top: ${cssTheme.layout.spacing.s800};
  }
`

const Title = styled(Text)`
  margin: ${cssTheme.layout.spacing.s600};
`

const Description = styled(Text)`
  text-align: center;
  ${linariaMq.desktopXs} {
    text-align: left;
  }
`

const ContentWrapper = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GemsBadge = styled(Badge)`
  color: ${cssTheme.colors.content.special.c600};
  background-color: ${cssTheme.colors.background.special.c100};
  margin-right: ${cssTheme.layout.spacing.s300};
`

const WhatIsGemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${cssTheme.colors.background.neutral.c000} url(${whatIsGemsImage})
    0 0 / 100% auto no-repeat;
  padding: ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s400};
  ${linariaMq.desktopXs} {
    padding: ${cssTheme.layout.spacing.s800} ${cssTheme.layout.spacing.s400};
    border: 1px solid ${cssTheme.colors.border.neutral.c100};
    border-radius: ${cssTheme.layout.radius.lg};
  }
`

export const PrivateDealTagWrapper = styled.div`
  margin-top: ${cssTheme.layout.spacing.s500};
`
export const InlinePrivateDealTagWrapper = styled.div`
  padding: 0 ${cssTheme.layout.spacing.s200};
`

interface Props {
  brand: Brand
  anonymousId: string
}

export const WhatIsGems = ({brand, anonymousId}: Props) => (
  <WhatIsGemsContainer>
    <ContentWrapper>
      <VioLogo brand={brand} />
      {toggle(
        'c9f92dea-new-tags-poc',
        <GemsIcon name="GemsLogo" size="xl" colorPath="content.special.c600" />,
        <PrivateDealTagWrapper>
          <PrivateDealTag
            brandCode={brand.code}
            message={{id: 'vioDeals.member', defaultMessage: 'Vio Member'}}
          />
        </PrivateDealTagWrapper>
      )}
      <Title variant="titleXL">
        <FormattedMessageWrapper
          id="membership.sameStayEvenBetterPrice"
          defaultMessage="Same stay, even better price."
        />
      </Title>
      <Description variant="bodyM">
        {toggle(
          'c9f92dea-new-tags-poc',
          <FormattedMessageWrapper
            id="membership.AsAGemsMemberYouHaveAccess"
            defaultMessage="As a {gems} member, you have access to deals you won’t find anywhere else."
            values={{gems: 'Gems'}}
          />,
          <FormattedMessageWrapper
            id="vioDeals.getJoiningFreeAccess"
            defaultMessage="By joining Vio.com you’ve got free access to insider hotel deals and savings up to 45%"
          />
        )}
      </Description>
      <GemsBox>
        <GemsBoxRow>
          <GemsBadge size="md" variant="info">
            1
          </GemsBadge>
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="membership.CompareTheMarketsBestRates"
              defaultMessage="Compare the market’s best rates"
            />
          </Text>
        </GemsBoxRow>
        <GemsBoxRow>
          <GemsBadge size="md" variant="info">
            2
          </GemsBadge>
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="membership.AccessPricesThatOtherSitesKeepSecret"
              defaultMessage="Access prices that other sites keep secret"
            />
          </Text>
        </GemsBoxRow>
        <GemsBoxRow>
          <GemsBadge size="md" variant="info">
            3
          </GemsBadge>
          <Text variant="bodyM">
            {toggle(
              'c9f92dea-new-tags-poc',
              <FormattedMessageWrapper
                id="membership.LookForGemsToSaveUpTo50"
                defaultMessage="Look for {gems} to save up to 50%"
                values={{
                  gems: (
                    <PrivateDealTag
                      brandCode="vio"
                      message={{
                        id: 'membership.membershipLabel',
                        defaultMessage: 'Gems Member'
                      }}
                    />
                  )
                }}
              />,
              <FormattedMessageWrapper
                id="vioDeals.LookForGemsToSaveUpTo50"
                defaultMessage="Look for {gems} to save up to 45%"
                values={{
                  gems: (
                    <InlinePrivateDealTagWrapper>
                      <PrivateDealTag
                        brandCode={brand.code}
                        message={{
                          id: 'vioLabelFinal',
                          defaultMessage: 'Only on Vio'
                        }}
                      />
                    </InlinePrivateDealTagWrapper>
                  )
                }}
              />
            )}
          </Text>
        </GemsBoxRow>
        <DiscoverGemsButton anonymousId={anonymousId} />
      </GemsBox>
    </ContentWrapper>
  </WhatIsGemsContainer>
)
