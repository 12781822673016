import React from 'react'
import {styled} from '@linaria/react'

import {Button, ButtonSize} from '@daedalus/atlas/Button'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  /** Specify button sizes */
  size: ButtonSize
  /** Callback for Login click */
  onLogInClick: () => void
  /** Callback for Signup click */
  onSignUpClick: () => void
}

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  > button + button {
    margin-top: ${cssTheme.layout.spacing.s400};
  }
`

export const AuthenticationButtons = ({
  size,
  onLogInClick,
  onSignUpClick
}: Props) => (
  <ButtonsWrapper>
    <Button fullWidth size={size} dataId="LogInButton" onClick={onLogInClick}>
      <FormattedMessageWrapper
        id="userWidget.signIn"
        defaultMessage="Sign in"
      />
    </Button>
    <Button
      fullWidth
      variant="secondary"
      dataId="SignUpButton"
      size={size}
      onClick={onSignUpClick}
    >
      <FormattedMessageWrapper
        id="userWidget.createAccount"
        defaultMessage="Create an account"
      />
    </Button>
  </ButtonsWrapper>
)
