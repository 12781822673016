import React, {useEffect, useRef} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {AnimatePresence, motion} from 'framer-motion'
import {SizesType} from 'types/Sizes'

import {hexToRGBAString} from '@daedalus/core/src/utils/css'

import {cssTheme} from '../../../themes'
import {closeOnEscape} from '../../../utils/closeOnEscape'
import transitions from '../../../utils/framerTransitions'
import {Button} from '../Button'
import {BottomBar} from '../helpers/BottomBar'
import {Portal} from '../helpers/Portal'
import {TitleBar, TitleBarProps, TitleBarVariant} from '../helpers/TitleBar'
import {Icon} from '../Icon'
import {ANIMATION_TYPES} from '../Overlay'
import useBodyScrollLock from '../Overlay/useBodyScrollLock'

type PopUpSize = Extract<SizesType, 'md' | 'lg'>
interface Props {
  /** The body of the pop-up */
  children: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The callback for the close button click */
  closeAction?: () => void
  /** The footer of the pop-up. Commonly used for action button placement */
  footer?: React.ReactNode
  /** Whether the BodyWrapper should have paddings around it */
  noPaddings?: boolean
  /** The height of the pop-up. This will be limited by the window height. */
  height?: number
  /** Whether the pop-up is open or not */
  isOpen: boolean
  /** The max-width of the pop-up. This will be limited by the default prop value. */
  width?: number
  /** The size of the PopUp header */
  size?: PopUpSize
  /** All the props from the TitleBar can be applied here, check [TitleBar](/?path=/docs/helpers-titlebar--default) docs  */
  titleBarProps?: TitleBarProps
  /** Determine the variant for the Header (using TitleBar underneath)  */
  headerVariant?: TitleBarVariant
  /** The content of the overlay sub header */
  subHeaderContent?: React.ReactNode
  /** Whether the PopUp should call the onClose closeAction when the Esc key is pressed (true by default) */
  closeOnEsc?: boolean
  /** Whether the PopUp should call the onClose closeAction when the user clicks outside the PopUp (true by default) */
  closeOnOutsideClick?: boolean
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledPopUp = styled(PopUp)` */
  style?: React.CSSProperties
}

export const TRANSITION_DURATION = 300 // ms

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${hexToRGBAString(cssTheme.colors.overlay, 0.6)};
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: ${cssTheme.layout.spacing.s800};
  }
`

export const Content = styled(motion.div)<{maxWidth: number; height?: number}>`
  height: ${({height}) => (height ? `${height}px` : 'auto')};
  max-height: ${({height}) =>
    height || `calc(100vh - ${cssTheme.layout.spacing.s950})`};
  max-width: ${({maxWidth}) => `${maxWidth}px`};
  margin: 0 ${cssTheme.layout.spacing.s300};
  border-radius: ${cssTheme.layout.radius.md};
  background: ${cssTheme.colors.background.neutral.c000};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const HeaderWrapper = styled.div<{header?: React.ReactNode}>`
  height: ${({header}) => (!header ? '0px' : 'auto')};
`

const getPaddings = (
  hasHeader: boolean,
  noPaddings: boolean,
  footer: boolean
) => {
  if (noPaddings) {
    return '0'
  } else if (hasHeader && !footer) {
    return `0 ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s500}`
  } else if (hasHeader && footer) {
    return `0 ${cssTheme.layout.spacing.s500}`
  }
  return `${cssTheme.layout.spacing.s400} ${cssTheme.layout.spacing.s500}`
}

export const BodyWrapper = styled.div<{
  hasHeader: boolean
  noPaddings: boolean
  footer: boolean
  size: PopUpSize
}>`
  padding: ${({hasHeader, noPaddings, footer}) =>
    getPaddings(hasHeader, noPaddings, footer)};
  &.--size-lg {
    padding-left: calc(
      ${cssTheme.layout.spacing.s500} + ${cssTheme.layout.spacing.s200}
    );
    padding-right: calc(
      ${cssTheme.layout.spacing.s500} + ${cssTheme.layout.spacing.s200}
    );
  }

  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const Body = styled.div`
  flex: 0 1 auto;
  ${cssTheme.typography.text.bodyM};
`

export const Footer = styled.div`
  padding-top: ${cssTheme.layout.spacing.s500};
  ${cssTheme.typography.text.bodyM};
  text-align: center;
`

export const PopUp = ({
  children,
  className,
  closeAction,
  closeOnEsc = true,
  closeOnOutsideClick = true,
  footer,
  headerVariant,
  height,
  isOpen,
  noPaddings = false,
  size = 'md',
  subHeaderContent,
  titleBarProps,
  width = 418,
  style
}: Props) => {
  const popUpRef = useRef<HTMLDivElement>(null)
  const isCloseVisible = Boolean(closeAction)

  const popUpHeight = () => {
    if (window.innerHeight > 1040 && height && height >= 960) {
      return 960
    } else if (height && height > window.innerHeight) {
      return window.innerHeight - 80
    }
    return height
  }

  const closeButton = (
    <Button
      variant={headerVariant === 'inverse' ? 'transparent' : 'quiet'}
      size="lg"
      hasTouchArea
      dataId="PopUpCloseButton"
      iconEnd={
        <Icon
          name="Close"
          size="lg"
          colorPath={
            headerVariant === 'inverse'
              ? 'content.neutral.c000'
              : 'content.neutral.c950'
          }
        />
      }
      onClick={closeAction}
    />
  )
  const endContentButton = closeButton

  useBodyScrollLock({ref: popUpRef, isEnabled: isOpen})

  useEffect(() => {
    if (closeAction && closeOnEsc && isOpen) {
      closeOnEscape({onClose: closeAction})
    }
  }, [closeAction, closeOnEsc, isOpen])

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <Wrapper
            className={className}
            variants={transitions.overlayFade}
            initial="exited"
            animate="entered"
            exit="exited"
            onClick={closeOnOutsideClick ? closeAction : () => null}
            style={style}
          >
            <div>
              <Content
                maxWidth={width}
                height={popUpHeight()}
                variants={transitions[ANIMATION_TYPES.SLIDE_DOWN]}
                initial="exited"
                animate="entered"
                exit="exited"
                onClick={e => e.stopPropagation()}
                transition={{
                  duration: TRANSITION_DURATION
                }}
              >
                <HeaderWrapper
                  header={titleBarProps && titleBarProps.centerContent}
                >
                  <TitleBar
                    endContent={isCloseVisible ? endContentButton : undefined}
                    bottomContent={subHeaderContent}
                    variant={headerVariant}
                    centerContent={titleBarProps?.centerContent}
                    size={size}
                    {...titleBarProps}
                  />
                </HeaderWrapper>

                <BodyWrapper
                  ref={popUpRef}
                  hasHeader={Boolean(titleBarProps)}
                  noPaddings={noPaddings}
                  footer={Boolean(footer)}
                  size={size}
                  className={cx(size === 'lg' && '--size-lg')}
                >
                  <Body>{children}</Body>
                </BodyWrapper>

                {footer && (
                  <BottomBar sticky size={size}>
                    {footer}
                  </BottomBar>
                )}
              </Content>
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </Portal>
  )
}
