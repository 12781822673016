import React from 'react'
import {styled} from '@linaria/react'

import {Alert} from '@daedalus/atlas/Alert'
import {Button} from '@daedalus/atlas/Button'
import {CookieIcon} from '@daedalus/atlas/foundation/illustrations/CookieIcon'
import {NoStyleLink} from '@daedalus/atlas/helpers/NoStyleLink'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {linariaMq} from '@daedalus/atlas/utils/breakpoints'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {CookieBannerClickComponent} from './useCookieConsent'

const CookieBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${cssTheme.layout.spacing.s400};
`

const CookieIconWrapper = styled.div`
  display: flex;
  margin-right: ${cssTheme.layout.spacing.s300};
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${linariaMq.desktopSm} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const SubTitleWrapper = styled.div`
  display: flex;

  ${linariaMq.desktopSm} {
    padding-right: ${cssTheme.layout.spacing.s600};
  }
`

const SubTitle = styled(Text)`
  ${cssTheme.typography.text.bodyS}

  ${linariaMq.desktopSm} {
    ${cssTheme.typography.text.bodyM};
  }
`

const SettingsLink = styled(NoStyleLink)`
  text-decoration: underline;
  font-weight: inherit;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: ${cssTheme.layout.spacing.s400};
  gap: ${cssTheme.layout.spacing.s400};

  ${linariaMq.desktopSm} {
    padding-top: 0;
  }
`

const ActionButton = styled(Button)`
  width: 100%;
  ${linariaMq.desktopSm} {
    width: 190px;
  }
`

const StyledAlertBannerFullWidth = styled(Alert)`
  max-width: 1150px;
`

interface Props {
  acceptAllCookies: () => void
  openPreferencesCenter: () => void
  rejectAllCookies: (component?: CookieBannerClickComponent) => void
}

const CookieConsentBannerComponent = ({
  acceptAllCookies,
  openPreferencesCenter,
  rejectAllCookies
}: Props) => {
  return (
    <StyledAlertBannerFullWidth
      onClose={rejectAllCookies}
      title={
        <TitleWrapper>
          <CookieIconWrapper>
            <CookieIcon />
          </CookieIconWrapper>
          <Text variant="labelM">
            <FormattedMessageWrapper
              id="cookieProBannerTitle"
              defaultMessage="We use cookies"
            />
          </Text>
        </TitleWrapper>
      }
    >
      <CookieBannerWrapper>
        <BodyWrapper>
          <SubTitleWrapper>
            <SubTitle>
              <FormattedMessageWrapper
                id="cookieProBannerMessage"
                defaultMessage="We use cookies to give you the best experience on our website and also for analytics purposes."
              />{' '}
              <SettingsLink onClick={openPreferencesCenter}>
                <FormattedMessageWrapper
                  id="cookieProBannerSettingsLink"
                  defaultMessage="Learn more and adjust settings."
                />
              </SettingsLink>
            </SubTitle>
          </SubTitleWrapper>

          <ButtonContainer>
            <ActionButton
              fullWidth
              iconStart={<Icon name="Checkmark" size="md" />}
              dataId="acceptAllCookiesButton"
              size="lg"
              variant="secondary"
              onClick={acceptAllCookies}
            >
              <FormattedMessageWrapper
                id="acceptAllCookiesButton"
                defaultMessage="Accept"
              />
            </ActionButton>
            <ActionButton
              fullWidth
              iconStart={<Icon name="Close" size="md" />}
              dataId="rejectAllCookiesButton"
              size="lg"
              variant="secondary"
              onClick={() =>
                rejectAllCookies(CookieBannerClickComponent.RejectButton)
              }
            >
              <FormattedMessageWrapper
                id="rejectAllCookiesButton"
                defaultMessage="Reject"
              />
            </ActionButton>
          </ButtonContainer>
        </BodyWrapper>
      </CookieBannerWrapper>
    </StyledAlertBannerFullWidth>
  )
}

export const CookieConsentBanner = React.memo(CookieConsentBannerComponent)
