import React from 'react'
import {styled} from '@linaria/react'
import {PreferencesWidgetWithStore} from 'components/PreferencesWidgetWithStore'
import UserWidgetWithStore from 'components/UserWidgetWithStore'

import {Header} from '@daedalus/atlas/Header'
import {cssTheme} from '@daedalus/atlas/themes'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {SourceComponentType} from '@daedalus/core/src/auth/types/Auth'

interface Props {
  /** Disable the default logo click which redirects to home */
  disableLogoClick: boolean
  disablePreferences: boolean
  hasCurrencySelector?: boolean
  hasUserWidget: boolean
  hideMyBookings?: boolean
  hideManageAccount?: boolean
  selectedCurrency?: string
  page?: Page
  onCurrencyChange?: (currency: string) => void
}

const RightComponentWrapper = styled('div')`
  display: flex;
  padding-right: ${cssTheme.layout.spacing.s600};
  gap: ${cssTheme.layout.spacing.s300};
  align-items: inherit;
`

export const DesktopHeader = ({
  disableLogoClick,
  disablePreferences,
  hasCurrencySelector,
  selectedCurrency,
  page,
  hasUserWidget = true,
  hideMyBookings = false,
  hideManageAccount = false,
  onCurrencyChange
}: Props) => {
  const {brand} = useBrand()

  const isPaymentPage = page === Page.Payment

  return (
    <Header
      showLogo={true}
      brand={brand}
      hasShadow={false}
      hasDivider={true}
      disableLogoClick={disableLogoClick}
      logoUrl={brand.address}
      RightComponent={
        <RightComponentWrapper>
          {!disablePreferences && (
            <PreferencesWidgetWithStore
              hasLanguage={!isPaymentPage}
              hasCurrency={hasCurrencySelector}
              selectedCurrency={selectedCurrency}
              onCurrencyChange={onCurrencyChange}
            />
          )}
          {hasUserWidget && (
            <UserWidgetWithStore
              hideMyBookings={hideMyBookings}
              hideManageAccount={hideManageAccount}
              referralComponent={
                isPaymentPage ? SourceComponentType.PaymentPage : undefined
              }
            />
          )}
        </RightComponentWrapper>
      }
    />
  )
}
