import {
  Breakdown,
  HotelFees,
  Price
} from '@daedalus/core/src/api-types/bovio/response/booking'
import {
  getDisplayPrice,
  isThereACurrencyConversion
} from '@daedalus/core/src/price/business/price'

export const addStringNumbers = (num1: string, num2: string) => {
  return parseFloat(num1) + parseFloat(num2)
}

export const combineHotelFees = (
  fees1: HotelFees,
  fees2: HotelFees
): HotelFees => {
  const combinedBreakdown: Breakdown[] = [
    ...fees1.breakdown,
    ...fees2.breakdown
  ].reduce((acc: Breakdown[], {type, total}) => {
    const existing = acc.find(item => item.type === type)
    if (existing) {
      return acc.map(item =>
        item.type === type
          ? {
              ...item,
              total: addStringNumbers(item.total, total).toString()
            }
          : item
      )
    }
    return [...acc, {type, total}]
  }, [])

  const total = combinedBreakdown
    .reduce((sum, {total}) => sum + parseFloat(total), 0)
    .toString()

  return {breakdown: combinedBreakdown, currency: fees1.currency, total}
}

/**
 * This is a temporary logic for summing up prices for Lite split booking solution
 * to avoid summing up proces for two separate bookings on backend side. This should be removed
 * when BoVIO will bundle two bookings together with calculated total price.
 *
 * Sums all components of two given sets of OfferPrice arrays
 * @param prices - Array of OfferPrices
 * @returns combined set of two
 */
export const sumBookingPrices = (prices: Price[][]): Price[] => {
  if (prices.length === 1) {
    return prices[0]
  }

  const price1 = prices[0][0]
  const price2 = prices[1][0]

  const hotelFees1 = price1.hotelFees
  const hotelFees2 = price2.hotelFees
  // Grouping two sets of breakdown elements by their type, summing the total value
  const hotelFees = combineHotelFees(hotelFees1, hotelFees2)

  return [
    {
      ...price1,
      chargeable: {
        ...price1.chargeable,
        base: addStringNumbers(
          price1.chargeable.base,
          price2.chargeable.base
        ).toString(),
        taxes: addStringNumbers(
          price1.chargeable.taxes,
          price2.chargeable.taxes
        ).toString(),
        total: addStringNumbers(
          price1.chargeable.total,
          price2.chargeable.total
        ).toString()
      },
      hotelFees
    }
  ]
}

/**
 * sumBookingPrices is only calculating chargeable prices, this function is used
 * to sum up both chargeable and user price. This is added as part of 3c85ed3b-update-payment-timeline experiment.
 * This should be removed when BoVIO will bundle two bookings together with calculated total price.
 * @param prices - Array of OfferPrices
 * @returns combined set of two
 */
export const sumBookingPricesWithDisplayPrice = (
  prices: Price[][]
): Price[] => {
  const chargeablePricesSum = sumBookingPrices(prices)

  const hasConversion = isThereACurrencyConversion(prices[0])
  if (!hasConversion) return chargeablePricesSum

  const displayPrice1 = getDisplayPrice(prices[0]) as Price
  const displayPrice2 = getDisplayPrice(prices[1]) as Price

  const hotelFees1 = displayPrice1.hotelFees
  const hotelFees2 = displayPrice2.hotelFees

  // Grouping two sets of breakdown elements by their type, summing the total value
  const hotelFees = combineHotelFees(hotelFees1, hotelFees2)

  return [
    ...chargeablePricesSum,
    {
      ...displayPrice1,
      chargeable: {
        ...displayPrice1.chargeable,
        base: addStringNumbers(
          displayPrice1.chargeable.base,
          displayPrice2.chargeable.base
        ).toString(),
        taxes: addStringNumbers(
          displayPrice1.chargeable.taxes,
          displayPrice2.chargeable.taxes
        ).toString(),
        total: addStringNumbers(
          displayPrice1.chargeable.total,
          displayPrice2.chargeable.total
        ).toString()
      },
      hotelFees
    }
  ]
}
