import React from 'react'
import {styled} from '@linaria/react'

export type Props = {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Country code of the flag that should be displayed */
  countryCode?: string
  /** The size of the flag */
  size?: number
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledFlag = styled(Flag)` */
  style?: React.CSSProperties
}

const StyledFlag = styled.img<{size: number}>`
  width: ${({size}) => size}px;
`

export const Flag = ({
  countryCode = 'gb',
  size = 20,
  className,
  style
}: Props) => {
  const lowercaseCode = countryCode.toLowerCase()

  // To not crash entire app if we will not have icon for specific country
  try {
    return (
      <StyledFlag
        alt={lowercaseCode}
        src={require(`../../../foundation/flags/${lowercaseCode}.svg`)}
        size={size}
        className={className}
        style={style}
      />
    )
  } catch {
    return null
  }
}
