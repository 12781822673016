import React from 'react'
import {css} from '@linaria/core'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'
import {ColorPath} from 'types/Theme'

import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {cssTheme} from '../../../../themes'
import {linariaMq} from '../../../../utils/breakpoints'
import {Badge} from '../../Badge'
import {Icon} from '../../Icon'
import {Tag} from '../../Tag'

export type InputBaseSize = Extract<SizesType, 'sm' | 'md' | 'lg'>

export enum TextTransform {
  uppercase = 'uppercase',
  capitalize = 'capitalize',
  lowercase = 'lowercase',
  none = 'none'
}

export enum InputTextType {
  text = 'text',
  password = 'password',
  email = 'email',
  tel = 'tel',
  search = 'search',
  textarea = 'textarea'
}

export const CLEAR_BUTTON_MIN_WIDTH = 14

interface StyleProps {
  showInvalid?: boolean
  showValid?: boolean
  isResizable?: boolean
  isRounded?: boolean
  sizeVariant?: InputBaseSize
  textTransformType?: TextTransform
  withBorder?: boolean
  withStartIcon?: boolean
  withEndIcon?: boolean
}

interface ValidationBadgeProps {
  isVerified?: boolean
  showInvalid?: boolean
  showValid?: boolean
}

interface IconsWrapperProps {
  isRounded?: boolean
  sizeVariant?: InputBaseSize
}

interface WithInsideLabelIconsWrapperProps {
  sizeVariant: InputBaseSize
  withRightShifting?: boolean
}

interface CaratWrapperProps {
  isOpen: boolean
  disabled?: boolean
  sizeVariant: InputBaseSize
}

interface IconSizeMapItem {
  padding: number
  rightIconWidth: number
  height: number
}

export const SM_INPUT_HEIGHT = 30
export const MD_INPUT_HEIGHT = 42
export const LG_INPUT_HEIGHT = 48

export const MD_INPUT_WITH_INSIDE_LABEL_HEIGHT = 48
export const LG_INPUT_WITH_INSIDE_LABEL_HEIGHT = 56

export const iconsSizeMap: Record<InputBaseSize, IconSizeMapItem> = {
  sm: {
    padding: 8,
    rightIconWidth: 30,
    height: SM_INPUT_HEIGHT
  },
  md: {
    padding: 12,
    rightIconWidth: 40,
    height: MD_INPUT_HEIGHT
  },
  lg: {
    padding: 12,
    rightIconWidth: 44,
    height: LG_INPUT_HEIGHT
  }
}

const iconsWithInsideLabelSizeMap: Record<InputBaseSize, IconSizeMapItem> = {
  sm: {
    padding: 12,
    rightIconWidth: 40,
    height: MD_INPUT_WITH_INSIDE_LABEL_HEIGHT
  },
  md: {
    padding: 12,
    rightIconWidth: 40,
    height: MD_INPUT_WITH_INSIDE_LABEL_HEIGHT
  },
  lg: {
    padding: 12,
    rightIconWidth: 44,
    height: LG_INPUT_WITH_INSIDE_LABEL_HEIGHT
  }
}

const FS_PRIVACY_MASK_CLASS = 'fs-mask'
export const ICON_SIZE = 20
const VALIDATION_ICON_SIZE = 16

export const getBaseInputIconPaddingStyles = ({
  sizeVariant = 'lg',
  isRounded = false,
  withStartIcon = false,
  withEndIcon = true,
  showInvalid = false,
  showValid = false
}: StyleProps) => {
  const gap = cssTheme.layout.spacing.s250
  const roundedPadding = isRounded ? cssTheme.layout.spacing.s200 : '0px'
  const size = iconsSizeMap[sizeVariant]
  const withValidationIcon = showInvalid || showValid

  const paddingLeft = `calc(${size.padding}px + ${withStartIcon ? `${ICON_SIZE}px + ${gap}` : '0px'})`
  const paddingRight = `calc(${roundedPadding} + ${withEndIcon ? `${size.rightIconWidth}px` : '0px'} + ${withValidationIcon ? `${VALIDATION_ICON_SIZE}px + ${gap}` : '0px'})`

  return {
    paddingLeft,
    paddingRight
  }
}

export const getBaseInsideLabelIconPaddingStyles = ({
  sizeVariant = 'lg',
  withStartIcon = false,
  withEndIcon = true,
  showInvalid = false,
  showValid = false
}: StyleProps) => {
  const gap = cssTheme.layout.spacing.s250
  const size = iconsWithInsideLabelSizeMap[sizeVariant]
  const withValidationIcon = showInvalid || showValid

  const paddingLeft = `calc(${size.padding}px + ${withStartIcon ? `${ICON_SIZE}px + ${gap}` : '0px'})`
  const paddingRight = `calc(${withEndIcon ? `${size.rightIconWidth}px` : '0px'} + ${withValidationIcon ? `${VALIDATION_ICON_SIZE}px + ${gap}` : '0px'})`

  return {
    paddingLeft,
    paddingRight
  }
}

const BaseInsideLabelIconsWrapper = styled.div<WithInsideLabelIconsWrapperProps>`
  pointer-events: none;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  height: ${({sizeVariant = 'lg'}) =>
    iconsWithInsideLabelSizeMap[sizeVariant].height}px;
`

export const WithInsideLabelStartIconsWrapper = styled(
  BaseInsideLabelIconsWrapper
)<WithInsideLabelIconsWrapperProps>`
  top: 0;
  left: 0;
  padding-left: ${({sizeVariant}) =>
    iconsWithInsideLabelSizeMap[sizeVariant].padding}px;
`

export const WithInsideLabelEndIconsWrapper = styled(
  BaseInsideLabelIconsWrapper
)<WithInsideLabelIconsWrapperProps>`
  top: 0;
  right: ${({withRightShifting}) =>
    withRightShifting ? cssTheme.layout.spacing.s500 : 0};
  gap: ${cssTheme.layout.spacing.s200};
`

const BaseIconsWrapper = styled.div<IconsWrapperProps>`
  pointer-events: none;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  height: ${({sizeVariant = 'lg'}) => iconsSizeMap[sizeVariant].height}px;
`

export const StartIconsWrapper = styled(BaseIconsWrapper)<
  IconsWrapperProps & {sizeVariant: InputBaseSize}
>`
  top: 0;
  left: 0;
  padding-left: ${({sizeVariant = 'lg'}) =>
    iconsSizeMap[sizeVariant].padding}px;
`

export const EndIconsWrapper = styled(BaseIconsWrapper)<IconsWrapperProps>`
  top: 0;
  right: 0;
  padding-right: ${({isRounded}) =>
    isRounded ? cssTheme.layout.spacing.s200 : 0};
`

export const focusShadowStyles = `
  box-shadow: 0px 0px 0px 1px ${cssTheme.colors.input.default.focus.border};
`

export const baseInputShadowStyles = `
  &:focus {
    ${focusShadowStyles};
  }
`

export const baseInputRadiusStyles = `
  border-radius: ${cssTheme.layout.radius.lg};
  &--size-sm {
    border-radius: ${cssTheme.layout.radius.sm};
  }
  &.isRounded {
    border-radius: ${cssTheme.layout.radius.rounded};
  }
`

export const baseInputBaseLayoutStyles = `
  display: inline-flex;
  align-items: center;
  position: relative;
  outline: none;
  width: 100%;
  gap: ${cssTheme.layout.spacing.s250};
  border: 1px solid ${cssTheme.colors.input.default.border};
  color: ${cssTheme.colors.input.default.text};
  background: ${cssTheme.colors.input.default.background};
  -webkit-appearance: none;

  ::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    color: ${cssTheme.colors.input.default.placeholder};
    text-transform: none;
  }
`

export const defaultBorderStyles = `
  border-color: ${cssTheme.colors.input.default.border};
`

const activeBorderStyles = `
  border-color: ${cssTheme.colors.input.default.active.border};
`

export const focusBorderStyles = `
  border-color: ${cssTheme.colors.input.default.focus.border};
`

const hoverBorderStyles = `
  border-color: ${cssTheme.colors.input.default.hover.border};
`

const invalidBorderStyles = `
  border-color: ${cssTheme.colors.input.error.border};
  z-index: 1;
`

export const baseInputBorderStyles = `
  ${defaultBorderStyles}

  ${linariaMq.desktopXs} {
    &:hover {
      ${hoverBorderStyles}
    }
  }

  &:active {
    ${activeBorderStyles}
  }

  &:focus {
    ${focusBorderStyles}
  }
`

export const baseInputBackgroundStyles = `
  ${linariaMq.desktopXs} {
    &:hover {
      background: ${cssTheme.colors.input.default.hover.background};
    }
  }

  &:active {
    background: ${cssTheme.colors.input.default.active.background};
  }
`

export const baseInputTypographyStyles = css`
  &--size-sm {
    ${cssTheme.typography.text.bodyXS};
  }
  &--size-md {
    ${cssTheme.typography.text.bodyM};

    ${linariaMq.desktopXs} {
      ${cssTheme.typography.text.bodyS};
    }
  }
  &--size-lg {
    ${cssTheme.typography.text.bodyM};

    ${linariaMq.desktopXs} {
      ${cssTheme.typography.text.bodyS};
    }
  }
`

export const baseInputSizeStyles = css`
  &--size-sm {
    min-height: ${SM_INPUT_HEIGHT}px;
    padding: 0 ${cssTheme.layout.spacing.s300};
  }
  &--size-md {
    min-height: ${MD_INPUT_HEIGHT}px;
    padding: 0 ${cssTheme.layout.spacing.s400};
  }
  &--size-lg {
    min-height: ${LG_INPUT_HEIGHT}px;
    padding: 0 ${cssTheme.layout.spacing.s400};
  }
`

export const baseInputInsideLabelSizeStyles = css`
  &--size-md {
    height: ${MD_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
    min-height: ${MD_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
    padding: 0 ${cssTheme.layout.spacing.s400};
  }
  &--size-lg {
    height: ${LG_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
    min-height: ${LG_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
    padding: 0 ${cssTheme.layout.spacing.s400};
  }
`

export const baseInputDisabledStyles = `
  &:disabled {
    color: ${cssTheme.colors.input.disabled.text};
    border-color: ${cssTheme.colors.input.disabled.border};
    background-color: ${cssTheme.colors.input.disabled.background};
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const InputWithInsideLabelWrapper = styled.div`
  width: 100%;
`

export const baseInputInvalidStateStyles = `
  &.isInvalid {
    ${invalidBorderStyles}
    &:active {
      ${invalidBorderStyles}
    }
    &:focus {
      ${focusBorderStyles};
    }
    ${linariaMq.desktopXs} {
      &:hover {
        ${invalidBorderStyles}
      }
    }
  }
`

export const CaptionWrapper = styled.div<{showInvalid?: boolean}>`
  padding: ${cssTheme.layout.spacing.s200} 0;
  color: ${({showInvalid}) =>
    showInvalid
      ? cssTheme.colors.input.error.caption
      : cssTheme.colors.input.default.caption};
`

const VerifiedTag = () => (
  <Tag
    variant="success"
    size="sm"
    iconStart={<Icon name="Checkmark" size="xs" />}
  >
    <FormattedMessageWrapper
      id="InputText.verified"
      defaultMessage="Verified"
    />
  </Tag>
)

export const ValidationBadge = ({
  isVerified = false,
  showInvalid,
  showValid
}: ValidationBadgeProps) => {
  if (!showInvalid && !showValid) return null

  const iconsName = showValid ? 'Checkmark' : 'WarningMark'
  const variant = showValid ? 'success' : 'danger'

  return isVerified ? (
    <VerifiedTag />
  ) : (
    <Badge iconName={iconsName} size="xs" variant={variant} />
  )
}

export const getClassName = (isPrivate?: boolean) =>
  isPrivate ? FS_PRIVACY_MASK_CLASS : undefined

export const ClearButtonWrapper = styled.div`
  pointer-events: auto;
  min-width: ${CLEAR_BUTTON_MIN_WIDTH}px;
`

export const CaratWrapper = styled.div<CaratWrapperProps>`
  transition: transform 0.2s ease 0s;
  display: flex;
  justify-content: center;
  transform: ${({isOpen}) => (isOpen ? 'rotateZ(0)' : 'rotateZ(180deg)')};
  color: ${({disabled}) =>
    disabled
      ? cssTheme.colors.input.disabled.carat
      : cssTheme.colors.input.default.carat};
  width: ${({sizeVariant}) => `${iconsSizeMap[sizeVariant].rightIconWidth}px`};
`

export const baseInputAppearanceNoneStyles = `
  appearance: none;
`

interface GetInsideLabelTextColorPathParams {
  isFocused?: boolean
  isDisabled?: boolean
  hasError?: boolean
  hasValue?: boolean
  defaultOverride?: ColorPath
}

/**
 * Get the color path for the inside label text based on the input state
 * @param isFocused - is the input focused
 * @param isDisabled - is the input disabled
 * @param hasError - does the input have an error
 * @param hasValue - does the input have a value
 * @param defaultOverride - override the default color path
 * @returns the color path for the inside label text
 */
export const getInsideLabelTextColorPath = ({
  isFocused,
  isDisabled,
  hasError,
  hasValue,
  defaultOverride
}: GetInsideLabelTextColorPathParams): ColorPath => {
  if (isFocused) {
    return 'input.default.text'
  }
  if (isDisabled) {
    return 'input.disabled.text'
  }
  if (hasError) {
    return 'input.error.caption'
  }
  if (hasValue) {
    return 'input.default.caption'
  }

  return defaultOverride || 'input.default.placeholder'
}
