import React from 'react'
import {styled} from '@linaria/react'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'
import {cssTheme} from '@daedalus/atlas/themes'

const SkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: ${cssTheme.layout.spacing.s600} ${cssTheme.layout.spacing.s500};
`

export const MobileMenuUserSectionSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonLoader width={90} height={90} circle />
    <ContentWrapper paddingY="s200" paddingX="s500">
      <SkeletonLoader width={90} height={20} />
      <SkeletonLoader width={150} height={20} />
      <SkeletonLoader width={100} height={20} />
    </ContentWrapper>
  </SkeletonWrapper>
)
