import React, {ReactElement, ReactNode, useEffect, useState} from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {motion} from 'framer-motion'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {Button} from '../Button'
import {Flex} from '../helpers/Flex'
import {Icon} from '../Icon'
import {Text} from '../Text'

type IconAlignment = 'flex-start' | 'center'

export interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** An icon placed before the Toast content. It can be any type of React Element */
  icon?: ReactElement
  /** The content of the Toast, a React component or pure text */
  children: ReactNode
  /** The header content of the Toast, a React component or pure text */
  title?: string
  /** Pass through alignIcon to allow vertically center aligned icon for example */
  alignIcon?: IconAlignment
  /** Toast closing will trigger the callback function. It will be called both on press close button and on self-closing */
  onClose?: () => void
  /** Toast click will trigger the callback function. */
  onClick?: (event: React.SyntheticEvent) => void
  /** Callback function that will fire when the user clicks on the close button. If an onClose callback is passed in, it will be called along with this callback. */
  onCloseButtonClick?: () => void
  /** Determines whether the Toast will be auto closed or not.*/
  autoClose?: boolean
  /** Determines whether the Toast will be closed on outside click or not.*/
  closeOnOutsideClick?: boolean
  /** Whether to show the close button. */
  showCloseButton?: boolean
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledToast = styled(Toast)` */
  style?: React.CSSProperties
}

const ToastContainer = styled(motion.div)`
  border-radius: ${cssTheme.layout.radius.md};
  box-shadow: ${cssTheme.shadows.floating};
  margin: 0 ${cssTheme.layout.spacing.s500};
  width: calc(100vw - 2 * ${cssTheme.layout.spacing.s500});
  overflow: hidden;
  animation: slide-in 0.2s;
  pointer-events: auto;

  ${linariaMq.desktopXs} {
    min-width: 366px;
    max-width: 712px;
  }
`

const ToastWrapper = styled(Flex.Row)`
  background: ${cssTheme.colors.toast.default.background};
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding: ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s600};
  &.--has-close-button {
    padding-right: ${cssTheme.layout.spacing.s800};
  }
  &.--has-icon {
    padding-left: ${cssTheme.layout.spacing.s400};
  }
`

const IconWrapper = styled(Flex.Row)`
  color: ${cssTheme.colors.toast.default.icon};
  height: ${cssTheme.layout.spacing.s600};
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: ${cssTheme.layout.spacing.s200};
`

const ContentWrapper = styled.div`
  flex-grow: 1;
`

const Content = styled.div`
  ${cssTheme.typography.text.bodyS}
  color: ${cssTheme.colors.toast.default.text};
`

const HIDE_TIMEOUT = 3000

export const Toast = ({
  icon,
  onClose,
  onCloseButtonClick,
  onClick,
  children,
  title,
  alignIcon = 'flex-start',
  showCloseButton = false,
  autoClose = true,
  closeOnOutsideClick = false,
  className,
  style
}: Props) => {
  const [timeoutId, setTimeoutId] = useState<number>()

  useEffect(() => {
    if (autoClose) {
      setTimeoutId(window.setTimeout(toggleToast(false), HIDE_TIMEOUT))
    }

    return () => window.clearTimeout(timeoutId)
  }, [])

  const toggleToast = (to: boolean) => () => {
    if (!to && onClose) {
      window.setTimeout(onClose, 200)
    }
  }

  const handleMouseEnter = (): void => {
    window.clearTimeout(timeoutId)
  }

  const handleMouseLeave = (): void => {
    if (autoClose) {
      setTimeoutId(window.setTimeout(toggleToast(false), HIDE_TIMEOUT))
    }
  }

  const handleCloseClick = () => {
    if (onCloseButtonClick) onCloseButtonClick()
    if (onClose) onClose()
  }

  const handleOutsideClick = () => {
    if (closeOnOutsideClick && onClose) onClose()
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <ToastContainer
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0}}
        layout
        className={className}
        style={style}
      >
        <ToastWrapper
          wrap="nowrap"
          alignItems={alignIcon}
          className={cx(
            showCloseButton && '--has-close-button',
            icon && '--has-icon'
          )}
          gap={icon ? 's400' : 's000'}
        >
          {icon && <IconWrapper alignItems="center">{icon}</IconWrapper>}
          <ContentWrapper>
            {title && (
              <Text variant="labelM" colorPath="toast.default.title">
                {title}
              </Text>
            )}
            <Content>{children}</Content>
          </ContentWrapper>
          {showCloseButton && (
            <ButtonWrapper>
              <Button
                hasTouchArea
                variant="transparent"
                size="md"
                iconStart={
                  <Icon name="Close" size="sm" colorPath="toast.default.icon" />
                }
                onClick={handleCloseClick}
              />
            </ButtonWrapper>
          )}
        </ToastWrapper>
      </ToastContainer>
    </OutsideClickHandler>
  )
}
