import React, {ReactElement, ReactNode} from 'react'
import {css, cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'

import {cssTheme} from '../../../themes'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import minus from '../../../utils/minus'
import {Text} from '../../designSystem/Text'

const TAG_VARIANTS = [
  'info',
  'neutral',
  'success',
  'warning',
  'accent'
] as const

export type TagVariant = (typeof TAG_VARIANTS)[number]

export type TagSize = Extract<SizesType, 'sm' | 'md'>

interface Props {
  /** Content to be displayed in the Tag */
  children?: ReactNode
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** The button click handler */
  onClick?: () => void
  /** The size of the tag */
  size?: TagSize
  /** An Icon to display on the left side of the input. Inverted direction for RTL  */
  iconStart?: ReactElement
  /** An Icon to display on the right side of the input. Inverted direction for RTL */
  iconEnd?: ReactElement
  /** The variation of styling the component will have */
  variant?: TagVariant
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Extra element to be displayed on the right side (left if RTL) */
  annotation?: ReactNode
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledTag = styled(Tag)` */
  style?: React.CSSProperties
}

const getTagVariant = (variant: TagVariant) => `
  &--variant-${variant} {
    color: ${cssTheme.colors?.tag?.[variant].content};
    background-color: ${cssTheme.colors?.tag?.[variant].background};
  }
`

export const BasicTagWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: ${cssTheme.layout.radius.md};
  gap: ${cssTheme.layout.spacing.s200};
  transition:
    color 0.2s,
    background-color 0.2s;

  ${TAG_VARIANTS.map(variant => getTagVariant(variant)).join('')}

  ${cssTheme.typography.text.labelXS};
  padding: ${cssTheme.layout.spacing.s200} ${cssTheme.layout.spacing.s300};
  min-height: ${cssTheme?.layout.spacing.s600};
  &--size-md {
    ${cssTheme.typography.text.labelS}
    padding: ${cssTheme.layout.spacing.s250} ${cssTheme.layout.spacing.s300};
    min-height: ${cssTheme?.layout.spacing.s700};
  }
`

const getAnnotationVariantStyles = (variant: TagVariant) => {
  const styles = cssTheme.colors?.tag?.[variant]
  return `
    &--variant-${variant} {
      &:before {
        border-color: transparent transparent transparent ${styles.background};
        [dir='rtl'] & {
          border-color: transparent ${styles.background} transparent transparent;
        }
      }
    }
  `
}

export const AnnotationLabelStyles = css`
  display: flex;
  position: relative;
  align-items: center;
  align-self: stretch;
  padding-top: ${cssTheme.layout.spacing.s200};
  padding-bottom: ${cssTheme.layout.spacing.s200};
  padding-right: ${cssTheme.layout.spacing.s300};
  padding-left: ${cssTheme.layout.spacing.s400};
  border-radius: 0 ${cssTheme.layout.radius.md} ${cssTheme.layout.radius.md} 0;
  transition:
    background-color 0.2s,
    color 0.2s;
  &.hasAnnotation {
    margin-left: ${minus(cssTheme.layout.spacing.s100)};
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-width: 12px 0 12px 5px;
    border-style: solid;
    transition: border-color 0.2s;
  }

  ${TAG_VARIANTS.map(variant => getAnnotationVariantStyles(variant)).join('')}

  [dir='rtl'] & {
    border-radius: ${cssTheme.layout.radius.md} 0 0 ${cssTheme.layout.radius.md};
  }
`

const getWrapperVariantStyles = (variant: TagVariant) => {
  const styles = cssTheme.colors?.tag?.[variant]
  return `
    &--variant-${variant} {
      background-color: ${styles.background};
      color: ${styles.content};
      .${AnnotationLabelStyles} {
        background-color: ${styles.annotationBackground};
        color: ${styles.annotationContent};
      }
    }
  `
}

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: ${cssTheme.layout.radius.md};
  transition:
    background-color 0.2s,
    color 0.2s;
  ${TAG_VARIANTS.map(variant => getWrapperVariantStyles(variant)).join('')}
  ${BasicTagWrapper} {
    border-radius: ${cssTheme.layout.radius.md} 0 0 ${cssTheme.layout.radius.md};
    [dir='rtl'] & {
      border-radius: 0 ${cssTheme.layout.radius.md} ${cssTheme.layout.radius.md}
        0;
    }
  }
`

export const BasicTag = ({
  children,
  dataId,
  onClick,
  size = 'sm',
  iconStart,
  iconEnd,
  variant = 'info',
  className,
  style
}: Props) => (
  <BasicTagWrapper
    data-id={dataId}
    onClick={onClick}
    className={cx(
      `${getLinariaClassName(BasicTagWrapper)}--size-${size}`,
      `${getLinariaClassName(BasicTagWrapper)}--variant-${variant}`,
      className
    )}
    style={style}
  >
    {iconStart}

    {Boolean(children) && <span>{children}</span>}

    {iconEnd}
  </BasicTagWrapper>
)

export const Tag = ({
  annotation,
  children,
  className,
  dataId,
  iconEnd,
  iconStart,
  onClick,
  size = 'sm',
  variant = 'info',
  style
}: Props) => {
  const hasAnnotation = Boolean(annotation)
  if (!hasAnnotation) {
    return (
      <BasicTag
        className={className}
        dataId={dataId}
        iconEnd={iconEnd}
        iconStart={iconStart}
        onClick={onClick}
        size={size}
        variant={variant}
        style={style}
      >
        {children}
      </BasicTag>
    )
  }

  return (
    <Wrapper
      className={cx(`${getLinariaClassName(Wrapper)}--variant-${variant}`)}
    >
      <BasicTag
        className={className}
        dataId={dataId}
        iconEnd={iconEnd}
        iconStart={iconStart}
        onClick={onClick}
        size={size}
        variant={variant}
        style={style}
      >
        {children}
      </BasicTag>
      <div
        className={cx(
          AnnotationLabelStyles,
          `${AnnotationLabelStyles}--variant-${variant}`,
          hasAnnotation && 'hasAnnotation'
        )}
      >
        <Text variant="labelXS">{annotation}</Text>
      </div>
    </Wrapper>
  )
}
