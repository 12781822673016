import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'

import {useIsReferralProgramEnabled} from '@daedalus/atlas/hooks/useIsReferralProgramEnabled'
import {Icon} from '@daedalus/atlas/Icon'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {Team} from '@daedalus/core/src/analytics/types/Events'
import {trackLoginClick} from '@daedalus/core/src/auth/modules/actions'
import {
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {Brand} from '@daedalus/core/src/brand/types'
import {
  checkIsBrandVio,
  getLocalizedHelpUrl
} from '@daedalus/core/src/brand/utils'
import {getLanguageCode} from '@daedalus/core/src/localization/services/locale'

import {useLogIn} from '../../../authentication/hooks/useLogIn'
import {AccountSideMenuItem} from './AccountSideMenuItem'

interface Props {
  currentPageId: string
  brand: Brand
  anonymousId: string
}

const PARENT_URL = '/user'

const SideMenuWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const AccountSideMenu = ({currentPageId, brand, anonymousId}: Props) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = useSelector(selectIsLoading)
  const logIn = useLogIn()
  const languageCode = useSelector(getLanguageCode)
  const isReferralProgramEnabled = useIsReferralProgramEnabled(brand)
  const {trackMenuItemClick} = useTrackMenuItemClick()

  const isHoldOutGroup = toggle(
    'a87807d8-price-watch-toggle-holdout',
    true,
    false
  )

  const shouldEnableAlertsTab =
    !isHoldOutGroup && toggle('feature-alerts-management', false, true)

  const onMenuItemClick = (menuItem: MenuItemComponent) => () => {
    trackMenuItemClick(menuItem, Team.Retention)
  }

  const isVio = checkIsBrandVio(brand)

  const onAccountClick = (e: React.MouseEvent) => {
    if (!isLoading && !isAuthenticated) {
      e.preventDefault()
      const component = SourceComponentType.AccountSideBar
      dispatch(trackLoginClick(component))

      return logIn({
        sourceComponent: component,
        flow: AuthenticationFlows.Login,
        anonymousId
      })
    } else {
      trackMenuItemClick(MenuItemComponent.Account, Team.Retention)
    }
  }

  const onGetHelpClick = (e: React.MouseEvent) => {
    e.preventDefault()
    trackMenuItemClick(MenuItemComponent.GetHelp, Team.Retention)
    window.open(getLocalizedHelpUrl(brand, languageCode))
  }

  return (
    <SideMenuWrapper>
      <AccountSideMenuItem
        currentPageId={currentPageId}
        pageId="account"
        label="Account"
        onClick={onAccountClick}
        to={`${PARENT_URL}/account`}
      />

      {shouldEnableAlertsTab && (
        <AccountSideMenuItem
          currentPageId={currentPageId}
          pageId="alerts"
          label="Alerts"
          textId="priceAlertTabLink"
          to={`${PARENT_URL}/alerts`}
          onClick={onMenuItemClick(MenuItemComponent.AlertsManagement)}
        />
      )}

      {isReferralProgramEnabled && (
        <AccountSideMenuItem
          currentPageId={currentPageId}
          pageId="membership.InviteFriendsToGems"
          label="Invite Friends to {gems}"
          to={`${PARENT_URL}/membership/referral`}
          values={{gems: <Icon size="lg" name="GemsLogo" />}}
          onClick={onMenuItemClick(MenuItemComponent.Referral)}
        />
      )}

      {isVio && (
        <>
          <AccountSideMenuItem
            currentPageId={currentPageId}
            pageId="membership"
            label="{gems} Membership"
            textId="membership.GemsMembership"
            to={`${PARENT_URL}/membership`}
            values={{gems: <Icon size="lg" name="GemsLogo" />}}
            onClick={onMenuItemClick(MenuItemComponent.GemsMembership)}
          />

          <AccountSideMenuItem
            currentPageId={currentPageId}
            pageId="getHelp"
            label="Get Help"
            onClick={onGetHelpClick}
          />
        </>
      )}
    </SideMenuWrapper>
  )
}
