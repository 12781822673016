import React from 'react'
import {useHistory} from 'react-router-dom'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowRow} from '../ArrowButton'
import {IconWrapper, Label, Link, RowWrapper} from '../commonStyles'

interface PropsType {
  onClick: () => void
  alertsManagementLink: string
}

export const Alerts = ({onClick, alertsManagementLink}: PropsType) => {
  const history = useHistory()
  const handleOnClick = () => {
    history.push(alertsManagementLink)
    onClick?.()
  }

  return (
    <Link onClick={handleOnClick}>
      <RowWrapper>
        <ArrowRow>
          <Label>
            <IconWrapper>
              <Icon name="Bell" />
            </IconWrapper>
            <Text variant="labelM">
              <FormattedMessageWrapper
                id="priceAlertTabLink"
                defaultMessage="Alerts"
              />
            </Text>
          </Label>
        </ArrowRow>
      </RowWrapper>
    </Link>
  )
}
