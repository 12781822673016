import React from 'react'
import {styled} from '@linaria/react'

import {Button} from '@daedalus/atlas/Button'
import {GenericError} from '@daedalus/atlas/GenericError'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'

import {ErrorMessage} from './CrashErrorMessageContainer'

const Title = styled(Text)`
  margin-bottom: ${cssTheme.layout.spacing.s300};
`

type Props = {
  t: ErrorMessage
  onButtonClick?: () => void
}

export const CrashErrorMessage = ({t, onButtonClick}: Props) => (
  <GenericError withIllustration>
    <ContentWrapper padding="s900">
      <Title variant="titleXL" dataId="error-page-heading" align="center">
        {t.heading}
      </Title>
      <Grid container direction="column" gap="s700" justify="center">
        <Text variant="bodyM">{t.message}</Text>
        <Button onClick={onButtonClick}>{t.button}</Button>
      </Grid>
    </ContentWrapper>
  </GenericError>
)
