import {toggle} from 'opticks'

import {Booking} from '../api-types/bovio/response/booking'
import {brands} from '../brand/config'
import {BrandCode} from '../brand/types'
import {Offer} from '../offer/types/offer'
import {addDaysToDate} from '../utils/date'
import {getIsProductionAppEnv} from '../utils/environment'

import type {OfferCheckAPIResponse} from '../offer/types/OfferCheck'

/**
 * Returns BoVio profile API key. The key is different for each brand and app environment.
 * Can be forced with forceBovioApiKey query url param
 */
export const getBovioApiKey = ({
  brandCode,
  appEnv,
  urlParams
}: {
  brandCode: BrandCode
  appEnv: string
  urlParams: Record<string, unknown>
}): string => {
  // Temporarily define experimental API keys here
  // During cleanup of def60428-enforce-3ds-check-v2:
  //  - These keys should be completely removed
  //  - Update logic in BE corresponding to A or B side of experiment's results
  const BOFH_PROFILE_ENFORCE_3DS_EXPERIMENT_STAGING =
    'RW5mb3JjZSAzZHMgYWZ0ZXIgYSBGcmF1ZCB_test'
  const BOFH_PROFILE_ENFORCE_3DS_EXPERIMENT_PROD =
    'RW5mb3JjZSAzZHMgYWZ0ZXIgYSBGcmF1ZCB'
  const isProdEnv = getIsProductionAppEnv(appEnv)

  if (!isProdEnv && urlParams.forceBovioApiKey) {
    return String(urlParams.forceBovioApiKey)
  }

  const profileKeyName = isProdEnv ? 'bovioProdApiKey' : 'bovioStagingApiKey'
  const profileKey =
    brands[brandCode]?.[profileKeyName] ||
    (brands.vio[profileKeyName] as string)

  // During cleanup of def60428-enforce-3ds-check-v2: Remove following block
  if (
    brandCode === 'vio' &&
    toggle('def60428-enforce-3ds-check-v2', false, true)
  ) {
    return isProdEnv
      ? BOFH_PROFILE_ENFORCE_3DS_EXPERIMENT_PROD
      : BOFH_PROFILE_ENFORCE_3DS_EXPERIMENT_STAGING
  }
  return profileKey
}

// A type-guard function that checks if the given argument is valid booking data
export const isExistingBooking = (
  bookingData?: OfferCheckAPIResponse | Offer | Booking
): bookingData is Booking => {
  return !!(bookingData as Booking)?.confirmationId
}

interface BookAgainDates {
  bookAgainCheckIn: string
  bookAgainCheckOut: string
}

/**
 * Returns a consequent check in and check out date when provided the original checkout date
 * ie.: 2022-07-23
 * @param checkOutDate - The string that represents a date when a stay ends
 * @returns -
 */
export const getBookAgainCheckInCheckOutDates = (
  checkOutDate: string
): BookAgainDates => {
  const bookAgainCheckIn = checkOutDate
  const bookAgainCheckOut = addDaysToDate(bookAgainCheckIn, 1)
  return {bookAgainCheckIn, bookAgainCheckOut}
}
