import {Brand, BrandCode} from '../types'
import {brands} from './brands'

interface DefaultBrand extends Omit<Brand, 'helpUrl'> {
  helpUrl: string
}

export {brands}

export const defaultBrandCode = 'vio' // TODO (@Gerrit88) Replace all usage of defaultBrand.code with defaultBrandCode
export const defaultBrand = brands[defaultBrandCode] as DefaultBrand

export const getBrandByCode = (code: string): Brand => brands[code as BrandCode]
