import React, {useCallback} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {NoStyleButton} from '@daedalus/atlas/helpers/NoStyleButton'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'

import {TabsContext} from './Tabs'

interface Props {
  value: string
}

const ActiveUnderline = styled.span`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${cssTheme.colors.border.neutral.c950};
  opacity: 0;
  transition: all 0.3s;
  &:not(.isActive) {
    background-color: ${cssTheme.colors.content.neutral.c600};
  }
`

//We can't have 18px margins from theme spacing, needs to be combined 16+2 to match the design (confirmed with @Design)
const AdditionalMargin = styled.div`
  padding: ${cssTheme.layout.spacing.s100} 0;
`

const TabWrapper = styled.li`
  display: flex;
  list-style: none;
  position: relative;
  &:hover {
    > ${ActiveUnderline} {
      opacity: 1;
    }
  }
  &.isActive {
    > ${ActiveUnderline} {
      opacity: 1;
    }
  }
  &:not(.isActive) {
    color: ${cssTheme.colors.content.neutral.c600};
  }
`

const StyledButton = styled(NoStyleButton)`
  padding: ${cssTheme.layout.spacing.s500} 0;
`

export const Tab: React.FC<Props> = ({value, children}) => {
  const {onChange, value: activeTab} = React.useContext(TabsContext)

  const onClick = useCallback(() => {
    onChange(value)
  }, [onChange, value])

  const isActive = activeTab === value

  return (
    <TabWrapper className={cx(isActive && 'isActive')}>
      <ActiveUnderline className={cx(isActive && 'isActive')} />
      <StyledButton onClick={onClick}>
        <AdditionalMargin>
          <Text variant="labelS">{children}</Text>
        </AdditionalMargin>
      </StyledButton>
    </TabWrapper>
  )
}
