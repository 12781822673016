import React from 'react'
import {useLocation} from 'react-router-dom'
import {styled} from '@linaria/react'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {cssTheme} from '@daedalus/atlas/themes'
import {AuthenticationMethod} from '@daedalus/core/src/auth/types/Auth'
import {AuthenticationProvider} from '@daedalus/core/src/auth/types/Cognito'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {isApple} from '@daedalus/core/src/utils/userAgent'

import {HandleLogin, SusiHistoryState} from '../types'

const ButtonsWrapper = styled.div`
  > button + button {
    margin-top: ${cssTheme.layout.spacing.s400};
  }
`

interface Props {
  handleLogin: HandleLogin
}

export const SignInButtons = ({handleLogin}: Props) => {
  const location = useLocation<{susi: SusiHistoryState}>()
  const analyticsData = location.state?.susi?.analyticsData
  const isAppleDevice = isApple()

  return (
    <ButtonsWrapper>
      {isAppleDevice && (
        <Button
          fullWidth
          dataId="SocialLoginApple"
          size="xl"
          iconStart={<Icon name="Apple" />}
          variant="secondary"
          onClick={handleLogin(
            AuthenticationMethod.Apple,
            AuthenticationProvider.Apple,
            analyticsData
          )}
        >
          <FormattedMessageWrapper
            id="SUSI.continueWith"
            defaultMessage="Continue with {channel}"
            values={{channel: 'Apple'}}
          />
        </Button>
      )}

      <Button
        fullWidth
        variant="secondary"
        dataId="SocialLoginGoogle"
        size="xl"
        iconStart={<Icon name="GoogleColor" />}
        onClick={handleLogin(
          AuthenticationMethod.Google,
          AuthenticationProvider.Google,
          analyticsData
        )}
      >
        <FormattedMessageWrapper
          id="SUSI.continueWith"
          defaultMessage="Continue with {channel}"
          values={{channel: 'Google'}}
        />
      </Button>

      <Button
        fullWidth
        variant="secondary"
        dataId="SocialLoginFacebook"
        size="xl"
        iconStart={<Icon name="FacebookColor" />}
        onClick={handleLogin(
          AuthenticationMethod.Facebook,
          AuthenticationProvider.Facebook,
          analyticsData
        )}
      >
        <FormattedMessageWrapper
          id="SUSI.continueWith"
          defaultMessage="Continue with {channel}"
          values={{channel: 'Facebook'}}
        />
      </Button>
    </ButtonsWrapper>
  )
}
