import {$Values} from 'utility-types'

export const PRESENTER_TYPES = {
  CheckoutFormErrorMonitor: 1,
  UnknownError: 2,
  PopUpPaymentError: 3,
  GenericError: 4,
  UnauthorizedBookingError: 5
}

export type PresenterTypesType = $Values<typeof PRESENTER_TYPES>

export const FETCH_ROOMS_PRESENTER_ERRORS = {
  price_mismatch: PRESENTER_TYPES.UnknownError,
  sold_out: PRESENTER_TYPES.UnknownError,
  request_error: PRESENTER_TYPES.GenericError,
  other: PRESENTER_TYPES.GenericError,
  input_validation: PRESENTER_TYPES.GenericError
}

export const BOOKING_PRESENTER_ERRORS = {
  data_validation: PRESENTER_TYPES.PopUpPaymentError,
  input_validation: PRESENTER_TYPES.PopUpPaymentError,
  payment: PRESENTER_TYPES.PopUpPaymentError,
  order_declined: PRESENTER_TYPES.PopUpPaymentError,
  price_mismatch: PRESENTER_TYPES.UnknownError,
  sold_out: PRESENTER_TYPES.UnknownError,
  request_error: PRESENTER_TYPES.PopUpPaymentError,
  other: PRESENTER_TYPES.PopUpPaymentError
}
