import {createApi} from '@reduxjs/toolkit/query/react'

import {axiosBaseQuery} from '../../../utils/network/axiosBaseQuery'
import {
  OfferCheckApiRequestParams,
  OfferCheckAPIResponse,
  UnifiedOfferCheckData
} from '../../types/OfferCheck'

interface TransformResponseParams<T> {
  transformResponseFunc: (resp: T) => UnifiedOfferCheckData
}

type RegularOfferCheckBuilderType = OfferCheckApiRequestParams<
  UnifiedOfferCheckData,
  OfferCheckAPIResponse
>
export type SplitBookingOfferCheckBuilderType = OfferCheckApiRequestParams<
  UnifiedOfferCheckData,
  OfferCheckAPIResponse
>

export const offerCheckApi = createApi({
  reducerPath: 'offerCheckApi',
  baseQuery: axiosBaseQuery({
    baseUrl: ''
  }),
  serializeQueryArgs: ({endpointName}) => endpointName,
  endpoints: builder => ({
    getSplitBookingOfferCheck: builder.query<
      UnifiedOfferCheckData,
      SplitBookingOfferCheckBuilderType
    >({
      keepUnusedDataFor: 0,
      query: ({url, experimentApiKey, headers = {}}) => ({
        url,
        headers: {
          ...headers,
          'X-Api-Key': experimentApiKey
        }
      }),
      transformResponse: (
        response: OfferCheckAPIResponse,
        meta: {headers: Record<string, string>},
        {transformResponseFunc}: TransformResponseParams<OfferCheckAPIResponse>
      ): UnifiedOfferCheckData =>
        transformResponseFunc({
          ...response,
          httpRequestId: meta.headers['x-request-id']
        })
    }),
    getOfferCheck: builder.query<
      UnifiedOfferCheckData,
      RegularOfferCheckBuilderType
    >({
      keepUnusedDataFor: 0,
      query: ({url, experimentApiKey, headers = {}}) => ({
        url,
        headers: {
          ...headers,
          'X-Api-Key': experimentApiKey
        }
      }),
      transformResponse: (
        response: OfferCheckAPIResponse,
        meta: {headers: Record<string, string>},
        {transformResponseFunc}: TransformResponseParams<OfferCheckAPIResponse>
      ): UnifiedOfferCheckData =>
        transformResponseFunc({
          ...response,
          httpRequestId: meta.headers['x-request-id']
        })
    })
  })
})

export const {useGetOfferCheckQuery, useGetSplitBookingOfferCheckQuery} =
  offerCheckApi

export const OFFER_CHECK_API_ERROR = 'offerCheckApi/executeQuery/rejected'
export const SPLIT_BOOKING_OFFER_CHECK_API_ERROR =
  'splitBookingOfferCheck/executeQuery/rejected'

// We have a custom query key but there is no type support for that.
// So we cast that key type to request params type.
export const GET_OFFER_CHECK_QUERY_KEY =
  'getOfferCheck' as unknown as RegularOfferCheckBuilderType

export const GET_SPLIT_BOOKING_OFFER_CHECK_QUERY_KEY =
  'getSplitBookingOfferCheck' as unknown as SplitBookingOfferCheckBuilderType
