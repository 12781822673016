import {mergeDeepRight} from 'ramda'
import {CONFIRMATION_PAGE_PATH, PAYMENT_PAGE_PATH} from 'routes'
import {
  getSessionStorageItem,
  setSessionStorageItem
} from 'services/sessionStorage'
import {history} from 'utils/history'
import {urlParams} from 'utils/urlParams'

import buildUrl from './buildUrl'

type UpdateHistoryLocationPropsType = {
  url?: string
  search?: Record<string, unknown>
  replaceSearchParams?: boolean
  replace?: boolean
}

const locationsHistory: string[] = []

export const getLastVisitedPage = () => {
  // It means the customer refreshed the page, so we get the first result
  if (locationsHistory.length === 1) {
    return locationsHistory?.[0]
  }

  return locationsHistory?.[1]
}

const pushLocationHistory = (pathName: string[]) => {
  locationsHistory.unshift(...pathName)
}

const identifyLastPage = (pathname?: string) => {
  const {referrer} = document
  const SRPOriginatedClick = 'SRPOriginatedClick'
  const cameFromSRP = getSessionStorageItem(SRPOriginatedClick)
  let SRPPath
  let currentPage

  // Tries to identify if the customer came via SRP first and adds SRP to the entries
  if (referrer.search(/findhotel.|vio.com|etrip.net/) !== -1 && !cameFromSRP) {
    setSessionStorageItem(SRPOriginatedClick, true)
    SRPPath = 'SRP'
  }

  switch (pathname) {
    case PAYMENT_PAGE_PATH:
      currentPage = 'PP'
      break
    case CONFIRMATION_PAGE_PATH:
      currentPage = 'CP'
      break
    default:
      break
  }

  if (SRPPath && currentPage) {
    return [currentPage, SRPPath]
  } else if (currentPage) {
    return [currentPage]
  }

  return []
}

history.listen(historyEntry => {
  const currentPage = identifyLastPage(historyEntry.pathname)
  pushLocationHistory(currentPage)
})

pushLocationHistory(identifyLastPage(window.location.pathname))

const updateHistoryLocation = ({
  url = '',
  search = {},
  replaceSearchParams = false,
  replace = false
}: UpdateHistoryLocationPropsType = {}) => {
  const params = urlParams()
  delete params.profile
  const objectToStringify = replaceSearchParams
    ? search
    : mergeDeepRight(params, search)

  const newUrl = buildUrl(url, objectToStringify, {arrayFormat: 'indices'})

  if (replace) {
    history.replace(newUrl)
  } else {
    history.push(newUrl)
  }

  return newUrl
}

export default updateHistoryLocation
