import React, {SyntheticEvent} from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {Team} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowRow} from '../ArrowButton'
import {IconWrapper, Label, Link, RowWrapper} from '../commonStyles'

const TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY = 500

interface Props {
  myBookingsUrl: string
}

export const MyBookingsLink = ({myBookingsUrl}: Props) => {
  const {trackMenuItemClick} = useTrackMenuItemClick()

  const handleMyBookingsClick = (event: SyntheticEvent) => {
    event.preventDefault()
    trackMenuItemClick(MenuItemComponent.MyBookings, Team.Retention)

    setTimeout(() => {
      window.location.href = myBookingsUrl
    }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
  }

  return (
    <Link onClick={handleMyBookingsClick}>
      <RowWrapper>
        <ArrowRow>
          <Label>
            <IconWrapper>
              <Icon name="PersonWithLuggage" />
            </IconWrapper>
            <Text variant="labelM">
              <FormattedMessageWrapper
                id="myBookings"
                defaultMessage="My bookings"
              />
            </Text>
          </Label>
        </ArrowRow>
      </RowWrapper>
    </Link>
  )
}
