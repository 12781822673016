import React from 'react'
import {styled} from '@linaria/react'

import {NoStyleButton} from '@daedalus/atlas/helpers/NoStyleButton'
import {Icon} from '@daedalus/atlas/Icon'
import {User} from '@daedalus/core/src/auth/types/Cognito'
import {UserAvatar} from '@daedalus/shared/src/user/account/UserAvatar'

type Props = {
  onShowPreferences?: () => void
  shouldShowUserAvatar: boolean
  user?: User
  hideBurgerMenu?: boolean
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileHeaderMenu = ({
  onShowPreferences,
  shouldShowUserAvatar,
  user,
  hideBurgerMenu = false
}: Props) => {
  return (
    <Wrapper>
      <NoStyleButton
        onClick={onShowPreferences}
        data-id="ShowPreferencesButton"
      >
        {shouldShowUserAvatar && user ? (
          <UserAvatar user={user} />
        ) : (
          !hideBurgerMenu && <Icon name="Menu" size="md" />
        )}
      </NoStyleButton>
    </Wrapper>
  )
}
