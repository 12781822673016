import React, {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@linaria/react'
import ToasterMessage from 'components/presentational/ToasterMessage'
import {useNetworkStatus} from 'hooks/useNetworkStatus'

import useBodyScrollLock from '@daedalus/atlas/Overlay/useBodyScrollLock'
import {Spinner} from '@daedalus/atlas/Spinner'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

const SpinnerWrapper = styled('div')`
  margin: 0 ${cssTheme.layout.spacing.s300} 0 ${cssTheme.layout.spacing.s500};
`

const OfflineComponent = () => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)

  useBodyScrollLock({ref})

  useEffect(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.OfflineStatusMessage,
        action: Action.Viewed,
        payload: {
          navigatorOnline: navigator.onLine
        }
      })
    )
  }, [dispatch])
  return (
    <div ref={ref}>
      <ToasterMessage>
        <Text>
          <FormattedMessageWrapper
            id="offlineMessage"
            defaultMessage="No internet connection. We are trying to reconnect…"
          />
        </Text>
        <SpinnerWrapper>
          <Spinner size={24} colorPath="content.neutral.c000" />
        </SpinnerWrapper>
      </ToasterMessage>
    </div>
  )
}

const NetworkMonitor = () => {
  const isOnline = useNetworkStatus()
  if (isOnline) return null
  return <OfflineComponent />
}

export default NetworkMonitor
