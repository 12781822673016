import React from 'react'
import {styled} from '@linaria/react'
import noop from 'lodash/noop'

import {cssTheme} from '@daedalus/atlas/themes'

const Flex = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  gap: ${cssTheme.layout.spacing.s800};
`

export const TabsContext = React.createContext<Props>({
  value: '',
  onChange: noop
})

interface Props {
  value?: string
  onChange: (value: string) => void
}

export const Tabs: React.FC<Props> = ({value, onChange, children}) => {
  return (
    <TabsContext.Provider value={{value, onChange}}>
      <Flex>{children}</Flex>
    </TabsContext.Provider>
  )
}
