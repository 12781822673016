import React, {ReactNode} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {hexToRGBAString} from '@daedalus/core/src/utils/css'

import {cssTheme} from '../../../../themes'
import {linariaMq} from '../../../../utils/breakpoints'
import {Icon} from '../../Icon'
import {Text} from '../../Text'

export type ControlType = 'radio' | 'checkbox'

interface Props {
  /** Pass through className to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the control is checked or not */
  checked: boolean
  /** Whether the control is disabled or not */
  disabled?: boolean
  /** Whether the control container should render full width */
  fullWidth?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** Content to render in label next to the control */
  label?: ReactNode
  /** Name used for html input element */
  name: string
  /** The control change handler. Fires when user change state of the Control (radio or checkbox) */
  onChange?: (e: React.SyntheticEvent) => void
  /** Value used for html input element */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  /** Use larger touch area */
  hasTouchArea?: boolean
  /** Whether the type is radio or checkbox */
  type: ControlType
}

const CONTROL_SIZE = 20
const CONTROL_DOT_SIZE = 10
const LABEL_MARGIN_LEFT = 10
const TOUCHAREA_SIZE_SM = 2
const TOUCHAREA_SIZE_LG = 12

const ControlElement = styled.div`
  display: flex;
`

export const ControlBody = styled.label<LabelProps>`
  display: flex;
  position: relative;
  padding: ${({hasTouchArea}) =>
    hasTouchArea ? TOUCHAREA_SIZE_LG : TOUCHAREA_SIZE_SM}px;
  flex: ${({fullWidth}) => (fullWidth ? '1' : 'initial')};
  @supports selector(:has(input:disabled)) {
    cursor: pointer;
    :has(input:disabled) {
      cursor: not-allowed;
    }
  }
`

export const RadioContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${LABEL_MARGIN_LEFT}px;
  line-height: ${CONTROL_SIZE}px;
  .radio-content-text {
    line-height: ${CONTROL_SIZE}px;
  }
`

export const ControlIcon = styled.div`
  position: relative;
  width: ${CONTROL_SIZE}px;
  min-width: ${CONTROL_SIZE}px;
  height: ${CONTROL_SIZE}px;
  background-color: ${cssTheme.colors.background.neutral.c000};
  border: 1px solid ${cssTheme.colors.border.neutral.c200};
  border-radius: ${cssTheme.layout.radius.rounded};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 2px 4px
    ${hexToRGBAString(cssTheme.colors.background.special.c950, 0.05)};
  cursor: pointer;

  &:after {
    content: '';
    width: ${CONTROL_DOT_SIZE}px;
    height: ${CONTROL_DOT_SIZE}px;
    background-color: ${cssTheme.colors.background.neutral.c000};
    border-radius: ${cssTheme.layout.radius.rounded};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
  }
`

export const ControlInput = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: ${CONTROL_SIZE + TOUCHAREA_SIZE_LG}px;
  opacity: 0;
  width: 0;
  height: 0;

  ${linariaMq.desktopXs} {
    &:focus-visible + ${ControlIcon} {
      box-shadow:
        0 0 0 1px ${cssTheme.colors.border.neutral.c000},
        0 0 0 3px ${cssTheme.colors.inputControls.default.focus.border};
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:active:not(:disabled) {
    & + ${ControlIcon} {
      background-color: ${cssTheme.colors.inputControls.default.active
        .background};
      border-color: ${cssTheme.colors.border.neutral.c500};
      box-shadow: 0 0 0 2px ${cssTheme.colors.background.interactive.c200};
    }

    &:checked + ${ControlIcon} {
      background-color: ${cssTheme.colors.inputControls.checked.background};
      border-color: ${cssTheme.colors.border.neutral.c900};
      box-shadow: 0 0 0 2px ${cssTheme.colors.background.interactive.c100};
    }

    &[data-field-error='true'] + ${ControlIcon} {
      background-color: ${cssTheme.colors.inputControls.error.background};
      border-color: ${cssTheme.colors.inputControls.error.border};
      box-shadow: 0 0 0 2px ${cssTheme.colors.background.danger.c200};
    }
  }

  &[data-field-error='false']:checked + ${ControlIcon} {
    background-color: ${cssTheme.colors.inputControls.checked.background};
    border: none;
  }

  &[data-field-error='true'] + ${ControlIcon} {
    background-color: ${cssTheme.colors.inputControls.error.background};
    border-color: ${cssTheme.colors.inputControls.error.border};
  }

  &:disabled:checked + ${ControlIcon} {
    background-color: ${cssTheme.colors.inputControls.disabled.background};
    border-color: ${cssTheme.colors.inputControls.disabled.border};
    box-shadow: none;
    cursor: not-allowed;

    &.disabledChecked {
      background-color: ${cssTheme.colors.inputControls.disabled.background};
      border: 1px solid ${cssTheme.colors.inputControls.disabled.border};
    }
  }

  &:disabled ~ ${RadioContentWrapper} {
    cursor: not-allowed;
  }
`

interface LabelProps {
  hasTouchArea: boolean
  fullWidth: boolean
  htmlFor: string
}

export const ControlWithCheckmark = ({
  checked,
  value,
  name,
  dataId,
  onChange,
  label,
  disabled,
  fullWidth = false,
  hasError = false,
  hasTouchArea = false,
  type = 'radio',
  className
}: Props) => {
  const disabledChecked = checked && disabled
  return (
    <ControlElement className={className}>
      <ControlBody
        hasTouchArea={hasTouchArea}
        fullWidth={fullWidth}
        htmlFor={value}
      >
        <ControlInput
          type={type}
          name={name}
          id={value}
          data-id={dataId}
          checked={checked}
          value={value}
          disabled={disabled}
          data-field-error={hasError}
          onChange={onChange}
        />
        <ControlIcon className={cx(disabledChecked && 'disabledChecked')}>
          {((checked && !hasError) || disabledChecked) && (
            <Icon
              name="Checkmark"
              size="sm"
              colorPath={
                disabledChecked
                  ? 'content.neutral.c500'
                  : 'content.neutral.c000'
              }
            />
          )}
        </ControlIcon>
        {Boolean(label) && (
          <RadioContentWrapper className="radio-content">
            <Text className="radio-content-text" variant="bodyS" as="span">
              {label}
            </Text>
          </RadioContentWrapper>
        )}
      </ControlBody>
    </ControlElement>
  )
}
