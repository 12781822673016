import {urlParams} from 'utils/urlParams'

import {getTaxDisplayLogic} from '@daedalus/core/src/localization/business/countries'

import {updateAllPrices} from '../business/updateAllPrices'

import type {Price} from '@daedalus/core/src/api-types/bovio/response/booking'
import type {TotalPrice} from '@daedalus/core/src/api-types/bovio/response/split_bookings/offer_check'
import type {OfferPrice} from '@daedalus/core/src/offer/types/offer'

export const updatePrices = (prices: Price[] | TotalPrice[]): OfferPrice[] => {
  const {userCountry} = urlParams()
  const taxDisplayLogic = getTaxDisplayLogic(userCountry)

  return prices.map(price => updateAllPrices(price, taxDisplayLogic))
}
