// @Todo - After A/B website-2 cleanup old error messages
import {defineMessages} from 'react-intl'
import {path} from 'ramda'

export const getErrorCategory = path(['errorResponse', 'details', 'category'])

export const genericMessages = defineMessages({
  customerSupport: {
    id: 'customerSupport',
    defaultMessage: 'Customer Support'
  },
  somethingWrong: {
    id: 'somethingWrong',
    defaultMessage: 'Oops, something went wrong'
  },
  bookingIncompleteMessage: {
    id: 'bookingIncompleteMessage',
    defaultMessage:
      "We couldn't book this stay. Please return to the previous page or contact us for assistance"
  },
  unknownErrorFirstLine: {
    id: 'unknownErrorFirstLine',
    defaultMessage: `Please return to the previous page or contact us for assistance.`
  },
  buttonCallToAction: {
    id: 'buttonCallToAction',
    defaultMessage: `Back to the previous page`
  }
})

export const buttonsMessage = defineMessages({
  backToSearch: {
    id: 'backToSearch',
    defaultMessage: 'Back to search'
  },
  tryAgain: {
    id: 'tryAgain',
    defaultMessage: 'Try Again'
  },
  chooseAnotherRoom: {
    id: 'chooseAnotherRoom',
    defaultMessage: 'Back to previous page'
  }
})

export const providerErrorMessagesFallback = defineMessages({
  genericError: {
    id: 'unexpectedError',
    defaultMessage: 'An unexpected error happened.'
  },
  priceMismatch: {
    id: 'priceMismatchMessage',
    defaultMessage: `The room price has changed since you started the checkout process, please try again. Don't worry you won't lose the form information.`
  }
})
