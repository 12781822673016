import * as React from 'react'
import {ThemeType} from 'types/Theme'

import {getTheme} from '@daedalus/atlas/themes'
import {BrandThemeName} from '@daedalus/core/src/brand/types'

type GetThemePropsType = {
  children(data: ThemeType): React.ReactElement
  themeName?: BrandThemeName
}

const GetTheme = React.memo<GetThemePropsType>(({children, themeName}) => {
  const websiteTheme: ThemeType = getTheme(themeName)
  return children(websiteTheme)
})

GetTheme.displayName = 'GetTheme'

export default GetTheme
