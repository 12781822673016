import React from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'

import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {getAuthenticationFlow} from '@daedalus/core/src/auth/modules/selectors'
import {AuthenticationFlows} from '@daedalus/core/src/auth/types/Auth'

const BottomSheetTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${cssTheme.layout.spacing.s100};
  text-align: center;
`

const DescriptionBox = styled.div`
  max-width: 240px;
  &.isFullWidth {
    max-width: none;
  }
`

const titles = defineMessages({
  default: {
    id: 'magicLinkModal.title.memberDeals',
    defaultMessage: 'Unlock member deals for FREE!'
  },
  [AuthenticationFlows.DealFreeze]: {
    id: 'susi.title.dealFreeze',
    defaultMessage: 'Freeze this price for free'
  },
  vioDeal: {
    id: 'vioDeals.unlock',
    defaultMessage: 'Unlock Vio deals'
  }
})

const descriptions = defineMessages({
  default: {
    id: 'magicLinkModal.unlockDetailsDesktop.joinMembers',
    defaultMessage:
      'Join over 2,000,000 members who are saving on every booking'
  },
  [AuthenticationFlows.DealFreeze]: {
    id: 'susi.description.dealFreeze',
    defaultMessage:
      "Lock in the current price and complete your booking when you're ready."
  },
  vioDeal: {
    id: 'vioDeals.getFreeAccess',
    defaultMessage:
      'Sign up to Vio.com to get free access to insider hotel deals and save up to 45% on your stays.'
  }
})
export const SignInTitle = () => {
  const {formatMessage} = useIntl()
  const authenticationFlow = useSelector(getAuthenticationFlow)
  const isDealFreezeFlow = authenticationFlow === AuthenticationFlows.DealFreeze

  const title = formatMessage(
    isDealFreezeFlow
      ? titles[authenticationFlow]
      : toggle('c9f92dea-new-tags-poc', titles.default, titles.vioDeal)
  )
  const description = formatMessage(
    isDealFreezeFlow
      ? descriptions[authenticationFlow]
      : toggle(
          'c9f92dea-new-tags-poc',
          descriptions.default,
          descriptions.vioDeal
        )
  )

  return (
    <BottomSheetTitle>
      <Text variant="titleXL" dataId="magicLinkModal.title.memberDeals">
        {title}
      </Text>
      <DescriptionBox
        className={cx(
          toggle(
            'c9f92dea-new-tags-poc',
            isDealFreezeFlow ? 'isFullWidth' : '',
            'isFullWidth'
          )
        )}
      >
        <Text>{description}</Text>
      </DescriptionBox>
    </BottomSheetTitle>
  )
}
