import React from 'react'

import {Button} from '@daedalus/atlas/Button'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const ACCOUNT_FORM_ID = 'account-form-id'

export const TopBarSubmitButton = () => (
  <Button size="md" type="submit" variant="quiet" form={ACCOUNT_FORM_ID}>
    <Text variant="labelM">
      <FormattedMessageWrapper
        id="preferencesPopup.button"
        defaultMessage="Save"
      />
    </Text>
  </Button>
)
