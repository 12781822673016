import React from 'react'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowButton} from '../ArrowButton'
import {IconWrapper, Label, RowWrapper} from '../commonStyles'
import {PropsType} from '../types'

const MembershipMessage = styled(FormattedMessageWrapper)`
  display: flex;
  align-items: center;
  line-height: ${cssTheme.layout.spacing.s600};
`

export const MembershipLink = ({
  onShowMembershipOverlay
}: Pick<PropsType, 'onShowMembershipOverlay'>) => (
  <RowWrapper>
    <ArrowButton onClick={onShowMembershipOverlay}>
      <Label>
        <IconWrapper>
          <Icon name="Sparkles" />
        </IconWrapper>
        <Text variant="labelM">
          {toggle(
            'c9f92dea-new-tags-poc',
            <MembershipMessage
              id="membership.GemsMembership"
              defaultMessage=" {gems} Membership"
              values={{
                gems: <Icon className="inline-icon" size="lg" name="GemsLogo" />
              }}
            />,
            <MembershipMessage
              id="vioDeals.membership"
              defaultMessage="Vio Membership"
            />
          )}
        </Text>
      </Label>
    </ArrowButton>
  </RowWrapper>
)
