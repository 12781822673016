import {useEffect} from 'react'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {BrandCode} from '@daedalus/core/src/brand/types'

const injectFavicon = (brandCode: BrandCode) => {
  const favicon = document.createElement('link')
  favicon.rel = 'icon'
  favicon.href = `${process.env.PUBLIC_URL}/${brandCode}_favicon.png`
  document.head.appendChild(favicon)
}

const injectTitle = (brandName: string) => {
  const title = document.createElement('title')
  title.innerText = `Checkout - ${brandName}`
  document.head.appendChild(title)
}

const injectCookieConsentScript = (brandCookieConsentId?: string) => {
  const cookieConsentScript = document.createElement('script')
  cookieConsentScript.src = `https://cmp.osano.com/AzZuEAUQXGtUb3YcB/${brandCookieConsentId}/osano.js`
  document.head.appendChild(cookieConsentScript)
}

export const InjectHeadData = () => {
  const {
    brandIsCookieConsentEnabled,
    brandCode,
    brandName,
    brandCookieConsentId
  } = useBrand()

  useEffect(() => {
    injectFavicon(brandCode)
    injectTitle(brandName)

    if (brandIsCookieConsentEnabled) {
      injectCookieConsentScript(brandCookieConsentId)
    }
  }, [brandCode, brandCookieConsentId, brandIsCookieConsentEnabled, brandName])
  return null
}
