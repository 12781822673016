import React, {useCallback} from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'

import {ArrowButton} from '../ArrowButton'
import {IconWrapper, Label, RowWrapper} from '../commonStyles'

export const DebugScreenLink = () => {
  const handleClick = useCallback(() => {
    if (isReactNativeWebView()) {
      postMessageToWebView({
        type: RNWebViewMessageTypes.NAVIGATE,
        payload: {screen: 'Debug'}
      })
    }
  }, [])

  return (
    <RowWrapper>
      <ArrowButton onClick={handleClick}>
        <Label>
          <IconWrapper>
            <Icon name="Settings" />
          </IconWrapper>
          <Text variant="labelM">
            <FormattedMessageWrapper
              id="debugScreen"
              defaultMessage="Debug Screen"
            />
          </Text>
        </Label>
      </ArrowButton>
    </RowWrapper>
  )
}
