import {pickAll} from 'ramda'

const configKeys = [
  'REACT_APP_SENTRY_DSN',
  'REACT_APP_SAPI_PROFILE_KEY',
  'REACT_APP_GTM_ID',
  'REACT_APP_ENABLED_CURRENCIES',
  'REACT_APP_VERSION',
  'REACT_APP_ENV',
  'REACT_APP_BOFH_ENDPOINT',
  'REACT_APP_ANALYTICS_API_KEY',
  'REACT_APP_ANALYTICS_ENDPOINT',
  'REACT_APP_ANALYTICS_RETENTION_ENDPOINT',
  'REACT_APP_ANALYTICS_RETENTION_API_KEY',
  'REACT_APP_ANALYTICS_CORE_WEB_VITALS_ENDPOINT',
  'REACT_APP_ANALYTICS_CORE_WEB_VITALS_API_KEY',
  'REACT_APP_ANALYTICS_OTA_ENDPOINT',
  'REACT_APP_ANALYTICS_OTA_API_KEY',
  'REACT_APP_USE_MOCKS',
  'PUBLIC_URL',
  'REACT_APP_DD_CLIENT_TOKEN',
  'REACT_APP_DD_RUM_APP_ID',
  'REACT_APP_DD_SAMPLE_RATE',
  'REACT_APP_KUSTOMER_API_KEY_VIO',
  'REACT_APP_KUSTOMER_BRAND_ID_PRE_SALES_VIO',
  'REACT_APP_KUSTOMER_BRAND_ID_POST_SALES_VIO',
  'REACT_APP_DD_REPLAY_SAMPLE_RATE',
  'REACT_APP_RAA_KEY',
  'REACT_APP_RAA_ENDPOINT',
  'REACT_APP_RAA_POLLING_TIMEOUT',
  'REACT_APP_RAA_REQUEST_TIMEOUT',
  'REACT_APP_PROVIDER_LOGO_PREFIX',
  'REACT_APP_PCI_PROXY_MERCHANT_ID',
  'REACT_APP_PCI_PROXY_SCRIPT_SRC',
  'REACT_APP_PCI_PROXY_API_ENDPOINT',
  'REACT_APP_ANALYTICS_CUSTOMER_DATA_ENDPOINT',
  'REACT_APP_ANALYTICS_CUSTOMER_DATA_API_KEY',
  'REACT_APP_EPS_PROFILE_KEY',
  'REACT_APP_EPS_PSD2_IFRAME_URL',
  'REACT_APP_OPTIMIZELY_DATAFILE_URL',
  'REACT_APP_STRIPE_API_KEY',
  'REACT_APP_GMAPS_API_KEY',
  'REACT_APP_SAPI_ENDPOINT',
  'REACT_APP_IMGPROXY_ENDPOINT_URL',
  'REACT_APP_IMGPROXY_ENDPOINT_SECRET',
  'REACT_APP_BOFH_PROFILE_DEFAULT',
  'WEBSITE_ENABLE_SIMULATOR',
  'REACT_APP_WHATSAPP_LINK_VIO',
  'REACT_APP_WHATSAPP_LINK_VIO_ZENDESK',
  'REACT_APP_FACEBOOK_MESSENGER_LINK_VIO',
  'WEBSITE_CUSTOMER_IO_TRACKING_SITE_ID',
  'WEBSITE_CUSTOMER_IO_TRACKING_API_KEY',
  'REACT_APP_MEMBERSHIP_API_ENDPOINT',
  'REACT_APP_IP_INFO_ENDPOINT',
  'REACT_APP_ANALYTICS_SELECT_ENDPOINT',
  'REACT_APP_ANALYTICS_SELECT_API_KEY',
  'REACT_APP_DISABLE_OPTIMIZELY_LOGS',
  'REACT_APP_ANALYTICS_SELECT_API_KEY'
] as const

export type SettingsKey = (typeof configKeys)[number]

class Settings {
  settingsKeys: Record<SettingsKey, string> = pickAll(
    configKeys,
    window._env || process.env
  )

  get(key: SettingsKey): string {
    return this.settingsKeys[key]
  }

  set(key: SettingsKey, value: string): void {
    this.settingsKeys[key] = value
  }
}

export default new Settings()
