import {CONFIRMATION_PAGE_PATH, PAYMENT_PAGE_PATH} from 'routes'

import {
  Page,
  TrackEventPayload,
  TrackEventProperties
} from '@daedalus/core/src/analytics/types/Events'

import getLeadContext from './contexts/getLeadContext'
import getMarketingContext from './contexts/getMarketingContext'
import getUserParametersContext from './contexts/getUserParametersContext'

interface ValidationError {
  field: string
  errorMsg: string
}
type FormValidationError = Record<string, string | Record<string, string>>
export interface ValidationErroredPayload {
  validationError: ValidationError[]
  bookingRequestId: string
}

export interface TrackEventMetadata {
  searchId: string | null
}

export const errorsToEventsPayload = (
  errors:
    | FormValidationError
    | FormValidationError[]
    | string
    | string[]
    | undefined,
  bookingRequestId: string
): ValidationErroredPayload => {
  if (!errors)
    return {
      validationError: [],
      bookingRequestId
    }

  const flattenedErrors = Array.isArray(errors)
    ? Object.entries(errors.flat()?.[0])
    : Object.entries(errors)

  const validationError = flattenedErrors.map(
    ([key, value]): ValidationError => ({
      field: key,
      errorMsg: typeof value === 'object' ? JSON.stringify(value) : value
    })
  )

  const errorPayload = {
    validationError,
    bookingRequestId
  }
  return errorPayload
}

export const getPageName = (pathname: string) => {
  switch (pathname) {
    case PAYMENT_PAGE_PATH:
      return Page.Payment

    case CONFIRMATION_PAGE_PATH:
      return Page.Confirmation

    default:
      return null
  }
}

export const getEventProperties = (
  trackEventPayload: TrackEventPayload,
  metaData: TrackEventMetadata
): TrackEventProperties & {name: string} => {
  const decoratedAnalyticsContext = {
    ...trackEventPayload?.analyticsContext,
    userParametersContext: getUserParametersContext(),
    leadContext: getLeadContext(metaData),
    marketingContext: getMarketingContext()
  }

  const decoratedTrackEventPayload = {
    ...trackEventPayload,
    page: trackEventPayload.page ?? getPageName(window.location.pathname),
    analyticsContext: decoratedAnalyticsContext
  }

  const eventProps = {
    ...decoratedTrackEventPayload,
    name: `${trackEventPayload.category}_${trackEventPayload.entity}_${trackEventPayload.action}`
  }

  return eventProps as TrackEventProperties & {name: string}
}
