import React, {ReactNode} from 'react'
import {styled} from '@linaria/react'

import {Card} from '@daedalus/atlas/Card'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {PageWidthContainer as PageWidthContainerElement} from '@daedalus/atlas/helpers/PageWidthContainer'
import {cssTheme} from '@daedalus/atlas/themes'
import {linariaMq} from '@daedalus/atlas/utils/breakpoints'
import {Brand} from '@daedalus/core/src/brand/types'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'

import {ProfilePicture} from '../../ProfilePicture'
import {DeleteAccountModal} from './DeleteAccountModal'
import {DeleteAccountProvider} from './DeleteAccountProvider'
import {DeleteUserProfile} from './DeleteUserProfile'
import {PersonalDetailsForm} from './PersonalDetailsForm'

const SIDEBAR_MAX_WIDTH = 294
const HEADER_HEIGHT_MOBILE = 65

const PageGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${cssTheme.layout.spacing.s800};
  padding: ${cssTheme.layout.spacing.s700} 0px ${cssTheme.layout.spacing.s600};

  ${linariaMq.desktopXs} {
    display: grid;
    grid-template-columns:
      ${SIDEBAR_MAX_WIDTH}px
      minmax(min-content, auto);
    gap: ${cssTheme.layout.spacing.s600};
    justify-content: normal;
    align-items: normal;
    padding: 0;
  }
`

const PageWidthContainer = styled(PageWidthContainerElement)`
  height: 100%;

  ${linariaMq.mobileSm} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${linariaMq.desktopXs} {
    padding: 0 ${cssTheme.layout.spacing.s500};
  }
`

const PageContainerWrapper = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
  flex: 1 auto;

  ${linariaMq.desktopXs} {
    height: auto;
    padding: ${cssTheme.layout.spacing.s500} 0;
    background: ${cssTheme.colors.background.neutral.c050};
  }
`

const MobileContentWrapper = styled.div`
  width: 100%;
`

interface ContentWrapperProps {
  isMobile: boolean
  children: React.ReactNode
}

const ContentWrapper = ({isMobile, children}: ContentWrapperProps) =>
  isMobile ? (
    <MobileContentWrapper>{children}</MobileContentWrapper>
  ) : (
    <div>
      <Card borderRadius="md" shadow="action">
        {children}
      </Card>
    </div>
  )

interface AccountContentPropTypes {
  accountSideMenu?: ReactNode
  brand: Brand
}

export const AccountContent = ({
  accountSideMenu,
  brand
}: AccountContentPropTypes) => {
  const {isMobile} = useDeviceLayout()
  const isVio = checkIsBrandVio(brand)

  return (
    <DeleteAccountProvider>
      <PageContainerWrapper>
        <PageWidthContainer>
          <PageGrid>
            <ContentWrapper isMobile={isMobile}>
              <ProfilePicture hasBottomIcon={isVio} />
              {accountSideMenu}
            </ContentWrapper>
            <ContentWrapper isMobile={isMobile}>
              <PersonalDetailsForm brand={brand} />
            </ContentWrapper>
          </PageGrid>
          {isMobile ? (
            <PageGrid>
              <div>
                <DeleteUserProfile />
              </div>
            </PageGrid>
          ) : null}
        </PageWidthContainer>
      </PageContainerWrapper>
      <DeleteAccountModal brand={brand} />
    </DeleteAccountProvider>
  )
}
