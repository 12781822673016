import React from 'react'
import {useIntl} from 'react-intl'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'
import {CurrencyType} from 'types/Currency'

import {Card} from '@daedalus/atlas/Card'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import {Radio} from '@daedalus/atlas/Radio'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {linariaMq} from '@daedalus/atlas/utils/breakpoints'
import {splitPopularAndOtherCurrencies} from '@daedalus/core/src/localization/business/currency'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {SearchInput} from '../../SearchInput'
import {SelectorList} from '../SelectorList/SelectorList'

const PriceDisplayTitle = styled(Text)`
  margin-bottom: ${cssTheme.layout.spacing.s300};
`

const CurrencyConversionDisclaimerWrapper = styled(Card)`
  margin-bottom: ${cssTheme.layout.spacing.s600};
`

const CurrencyConversionDisclaimerText = styled(ContentWrapper)`
  display: flex;
  gap: ${cssTheme.layout.spacing.s300};
`

const PriceDisplayWrapper = styled.div`
  padding: 0 ${cssTheme.layout.spacing.s200};
  margin-bottom: ${cssTheme.layout.spacing.s600};
`

const RadioGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${linariaMq.desktopXs} {
    flex-direction: row;
    align-items: center;
    gap: ${cssTheme.layout.spacing.s800};
  }
`

interface Props {
  isMobile?: boolean
  value: string
  onChange: (code: string) => void
  currencies: CurrencyType[]
  onSearchInputFocus?: () => void
  showPriceDisplaySetting?: boolean
  showTotalPrices?: boolean
  onChangePriceDisplay?: (showTotal: boolean) => void
}

const splitPopularAndOther = (items: CurrencyType[]) => {
  const {currenciesList, popularCurrenciesList} =
    splitPopularAndOtherCurrencies(items)

  return {
    itemsList: currenciesList.filter(Boolean),
    popularItemsList: popularCurrenciesList.filter(Boolean)
  }
}

export const CurrencySelector: React.FC<Props> = ({
  isMobile = false,
  currencies,
  onChange,
  value,
  onSearchInputFocus,
  showTotalPrices,
  showPriceDisplaySetting,
  onChangePriceDisplay
}) => {
  const {formatMessage} = useIntl()

  const showPriceConversionDisclaimer = toggle(
    '3c85ed3b-update-payment-timeline',
    false,
    true
  )

  const renderList = (items: CurrencyType[], isSearching = false) => (
    <>
      {showPriceConversionDisclaimer && (
        <CurrencyConversionDisclaimerWrapper
          borderColorPath="border.neutral.c100"
          borderRadius="lg"
        >
          <CurrencyConversionDisclaimerText padding="s300">
            <ContentWrapper paddingTop="s100">
              <Icon
                name="Information"
                colorPath="content.neutral.c600"
                size="sm"
              />
            </ContentWrapper>
            <Text variant="bodyS">
              <FormattedMessageWrapper
                defaultMessage="Prices will be displayed in your selected currency. The currency charged may vary, and foreign transaction fees may apply."
                id="priceDisplay.currencyConversionDisclaimer"
              />
            </Text>
          </CurrencyConversionDisclaimerText>
        </CurrencyConversionDisclaimerWrapper>
      )}
      {showPriceDisplaySetting && !isSearching && (
        <PriceDisplayWrapper>
          <PriceDisplayTitle variant={isMobile ? 'titleM' : 'titleL'}>
            <FormattedMessageWrapper
              id="priceDisplay"
              defaultMessage="Price display"
            />
          </PriceDisplayTitle>
          <RadioGroup>
            <div id="PriceDisplay--nightly">
              <Radio
                hasTouchArea
                fullWidth
                checked={!showTotalPrices}
                name={`reviewScore-any`}
                label={formatMessage({
                  id: 'priceDisplay.nightly',
                  defaultMessage: 'Nightly price incl tax & fees'
                })}
                onChange={() => onChangePriceDisplay?.(false)}
                labelSize="bodyM"
              />
            </div>
            <div id="PriceDisplay--total">
              <Radio
                hasTouchArea
                fullWidth
                checked={Boolean(showTotalPrices)}
                name={`reviewScore-any`}
                label={formatMessage({
                  id: 'priceDisplay.total',
                  defaultMessage: 'Total price incl tax & fees'
                })}
                onChange={() => onChangePriceDisplay?.(true)}
                labelSize="bodyM"
              />
            </div>
          </RadioGroup>
        </PriceDisplayWrapper>
      )}

      <SelectorList
        isMobile={isMobile}
        items={items}
        value={value}
        onChange={onChange}
        getValue={item => item.code}
        popularItemsTitle={formatMessage({
          id: 'popularCurrencies',
          defaultMessage: 'Popular currencies'
        })}
        otherItemsTitle={formatMessage({
          id: 'allCurrencies',
          defaultMessage: 'All currencies'
        })}
        splitPopularAndOther={splitPopularAndOther}
        renderItem={item => (
          <>
            <div>
              <Text variant={isMobile ? 'bodyM' : 'bodyS'}>
                {item.displayName}
              </Text>
              <Text
                variant={isMobile ? 'bodyM' : 'bodyS'}
                colorPath={'content.neutral.c600'}
                translate="no"
              >
                {item.code} {item.code !== item.symbol && <>- {item.symbol}</>}
              </Text>
            </div>
          </>
        )}
        isSearching={isSearching}
      />
    </>
  )
  if (isMobile) {
    return (
      <SearchInput
        items={currencies}
        itemMatcher={SearchInput.matchers.default([
          'displayName',
          'code',
          'symbol'
        ])}
        onFocus={onSearchInputFocus}
      >
        {renderList}
      </SearchInput>
    )
  }

  return renderList(currencies)
}
