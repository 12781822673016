import React, {useCallback, useEffect} from 'react'

import {useIsGDPRCountry} from '@daedalus/core/src/_web/utils/cookies/hooks/cookieComplianceGeolocation'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  TrackEventPayload
} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {CookieConsentBannerContainer} from './CookieConsentBannerContainer'

export interface CookieConsentBannerProps {
  /**
   * Callback invoked when a user accepts the cookies, either in the
   * banner or the preference center
   */
  onAccept?: (acceptedByUser: boolean) => void
  trackEvent: (payload: TrackEventPayload) => void
}

const shouldCookieBannerBeShown = (): boolean => {
  return window.Osano?.cm?.dialogOpen ?? false
}

export const CookieConsentBanner = ({
  onAccept,
  trackEvent
}: CookieConsentBannerProps) => {
  const isGDPRCountry = useIsGDPRCountry()
  const [isDialogVisible, setDialogVisible] = React.useState<boolean>(
    shouldCookieBannerBeShown
  )

  const handleCookieConsentScriptLoaded = useCallback(() => {
    trackEvent({
      category: Category.System,
      entity: Entity.CookieBannerScript,
      action: Action.Loaded
    })
  }, [trackEvent])

  useEffect(() => {
    window.Osano?.cm?.addEventListener(
      'osano-cm-initialized',
      handleCookieConsentScriptLoaded
    )

    const handleUiChanged = () => {
      setDialogVisible(shouldCookieBannerBeShown)
    }

    window.Osano?.cm?.addEventListener('osano-cm-ui-changed', handleUiChanged)

    return () => {
      window.removeEventListener(
        'osano-cm-initialized',
        handleCookieConsentScriptLoaded
      )
    }
  }, [handleCookieConsentScriptLoaded])

  if (isReactNativeWebView() || !isGDPRCountry || !isDialogVisible) {
    return null
  }

  return (
    <CookieConsentBannerContainer onAccept={onAccept} trackEvent={trackEvent} />
  )
}

export const CookieConsentBannerWithStore = (
  props: Omit<CookieConsentBannerProps, 'trackEvent'>
) => {
  const dispatchTeamEvent = useDispatchTeamEvent()
  const trackEventCallback = useCallback(
    (payload: TrackEventPayload) => dispatchTeamEvent(trackEvent(payload)),
    [dispatchTeamEvent]
  )
  return <CookieConsentBanner {...props} trackEvent={trackEventCallback} />
}
