import React, {ButtonHTMLAttributes, ReactNode} from 'react'
import {styled} from '@linaria/react'

import {Icon} from '@daedalus/atlas/Icon'
import {cssTheme} from '@daedalus/atlas/themes'

import {IconWrapper} from '../commonStyles'

const rowBaseStyles = `
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
`

const StyledRow = styled.div`
  ${rowBaseStyles}
`

const StyledButton = styled.button`
  ${rowBaseStyles};

  position: relative;
  text-decoration: none;
  text-align: left;
  color: ${cssTheme.colors.content.neutral.c950};

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${cssTheme.colors.content.neutral.c950};
  }
`

export const ArrowButton = ({
  children,
  ...restButtonProps
}: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'style'>) => (
  <StyledButton {...restButtonProps}>
    {children}
    <IconWrapper>
      <Icon name="ChevronRight" />
    </IconWrapper>
  </StyledButton>
)

export const ArrowRow = ({children}: {children: ReactNode}) => (
  <StyledRow>
    {children}
    <IconWrapper>
      <Icon name="ChevronRight" />
    </IconWrapper>
  </StyledRow>
)
