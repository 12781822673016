/**
 * BoFH API - Booking creation payload
 */
export interface BookingCreation {
  /**
   * Represents the person who performs the booking
   */
  customer: Customer
  customerAccount: CustomerAccount
  /**
   * @deprecated Use payments.fraudProtectionToken instead.
   */
  fraudProtectionToken?: string
  /**
   * Offer metadata
   */
  offerMetadata: OfferMetadata
  /**
   * Payment
   */
  payment: Payment
  /**
   * Rooms
   */
  rooms: Room[]
  /**
   * @deprecated Unique identifier for a customer booking session
   */
  sessionId?: string
  /**
   * A metadata JSON object with params to be sent to the 3DS provider. The structure vary by
   * 3DS provider.
   */
  threeDomainSecureParams?: {[key: string]: any}
}

/**
 * Represents the person who performs the booking
 */
export interface Customer {
  email: string
  firstName: string
  lastName: string
  phone: Phone
}

/**
 * Phone
 */
export interface Phone {
  /**
   * Phone country code
   */
  countryCode: string
  /**
   * Phone number
   */
  number: string
}

/**
 * Guest Customer Account
 *
 * Registered Customer Account
 */
export interface CustomerAccount {
  /**
   * Type of the account
   */
  type: CustomerAccountType
  /**
   * Authentication method used by the account
   */
  authenticationMethod?: string
  /**
   * The date and time when the account was created
   */
  createdAt?: string
  email?: string
  /**
   * Indicates if the customer account email was verified
   */
  emailVerified?: boolean
  /**
   * ID of the customer account
   */
  id?: string
  /**
   * Last date and time account was logged in
   */
  lastLogin?: string
  /**
   * Indicates if the customer is a member plus
   */
  memberPlus?: boolean
  /**
   * Last date and time the account was updated
   */
  updatedAt?: string
}

/**
 * Type of the account
 */
export enum CustomerAccountType {
  Guest = 'guest',
  Registered = 'registered'
}

/**
 * Offer metadata
 */
export interface OfferMetadata {
  /**
   * Information to help keep membership experience across the whole booking funnel
   */
  accessTier: string[]
}

/**
 * Payment
 */
export interface Payment {
  billing: Billing
  fraudProtectionToken?: string
  /**
   * Wallet payment
   */
  wallet?: Wallet
  /**
   * Credit card payment
   */
  creditCard?: CreditCard
}

/**
 * Billing details
 */
export interface Billing {
  /**
   * Billing contact address
   */
  address: Address
  email: string
  firstName?: string
  lastName?: string
  phone: Phone
}

/**
 * Billing contact address
 */
export interface Address {
  /**
   * Billing contact address city
   */
  city?: string
  /**
   * Billing contact address country code
   */
  countryCode: string
  line1?: string
  line2?: string
  /**
   * Billing contact address postal code
   */
  postalCode?: string
  /**
   * Billing contact address state code
   */
  stateCode?: string
}

/**
 * Credit card payment
 */
export interface CreditCard {
  /**
   * Credit card expiration month (2 characters)
   */
  expirationMonth: string
  /**
   * Credit card expiration year (4 characters)
   */
  expirationYear: string
  /**
   * Selected network to process the payment
   */
  network?: Network
  /**
   * PCI Proxy transaction ID
   */
  transactionId: number
  /**
   * Credit card type
   */
  type: CreditCardType
}

/**
 * Selected network to process the payment
 */
export enum Network {
  CartesBancaires = 'cartes_bancaires',
  MasterCard = 'master_card',
  Visa = 'visa'
}

/**
 * Credit card type
 */
export enum CreditCardType {
  Ax = 'AX',
  Bc = 'BC',
  CA = 'CA',
  Cu = 'CU',
  Dc = 'DC',
  Ds = 'DS',
  E = 'E',
  Jc = 'JC',
  R = 'R',
  T = 'T',
  To = 'TO',
  Vi = 'VI'
}

/**
 * Wallet payment
 */
export interface Wallet {
  /**
   * The unique ID associated to the wallet to use in the transaction.
   */
  id: string
}

/**
 * Room
 */
export interface Room {
  /**
   * Room bed type ID
   */
  bedTypeId: string
  email: string
  firstName: string
  /**
   * The number of guests. Rooms are separated by pipe (|). Inside rooms there are adults and
   * children are separated by colon (:). Children ages are separated by comma (,).
   */
  guests: string
  lastName: string
  phone: Phone
  /**
   * Room smoking preference
   */
  smoking?: boolean
  /**
   * Room special request
   */
  specialRequest?: string
}
