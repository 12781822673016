export interface Error {
  details: Details
  /**
   * Human readable error message
   */
  message: string
  providerCode?: null | string
  /**
   * Error type
   */
  type: Type
}

/**
 * Booking not found error details
 *
 * Hotel not found error details
 *
 * Input validation error details
 *
 * Link not found error details
 *
 * Payment error details
 *
 * Provider error details
 */
export interface Details {
  /**
   * Booking ID
   */
  bookingId?: string
  /**
   * Hotel ID
   */
  hotelId?: string
  /**
   * Input validation errors
   */
  errors?: ErrorElement[]
  /**
   * Link ID
   */
  linkId?: string
  /**
   * Payment error category
   *
   * Provider error category
   */
  category?: Category
  /**
   * User friendly message in the language indicated in the request.
   */
  message?: string
  /**
   * Provider error message (only for debug and tracking purposes).
   */
  reason?: {[key: string]: any}
  /**
   * Provider error links. For recoverable errors in /book endpoint requests, it includes a
   * new /book endpoint link to be used when retry the booking.
   */
  links?: Link[]
  /**
   * A recoverable error means the request can be successful if the error is corrected and the
   * request resubmitted.
   */
  recoverable?: boolean
}

/**
 * Payment error category
 *
 * Provider error category
 */
export enum Category {
  BookingIncomplete = 'booking_incomplete',
  BookingNotCancelled = 'booking_not_cancelled',
  BookingNotFound = 'booking_not_found',
  CardNotSupported = 'card_not_supported',
  DataValidation = 'data_validation',
  GenericCardError = 'generic_card_error',
  GenericFraudDeclined = 'generic_fraud_declined',
  GenericPaymentDeclined = 'generic_payment_declined',
  IncorrectCvc = 'incorrect_cvc',
  IncorrectNumber = 'incorrect_number',
  InsufficientFunds = 'insufficient_funds',
  InvalidEmail = 'invalid_email',
  OrderDeclined = 'order_declined',
  Other = 'other',
  Payment = 'payment',
  PriceMismatch = 'price_mismatch',
  RateLimit = 'rate_limit',
  RequestError = 'request_error',
  SoldOut = 'sold_out'
}

/**
 * Input validation error
 */
export interface ErrorElement {
  /**
   * Input validation error field
   */
  field: string
  /**
   * Input validation error validations
   */
  validations: Validation[]
}

/**
 * Input validation error validation
 */
export enum Validation {
  Cast = 'cast',
  DateRange = 'date_range',
  Exclusion = 'exclusion',
  Format = 'format',
  Inclusion = 'inclusion',
  Length = 'length',
  Number = 'number',
  Required = 'required'
}

/**
 * Provider error link
 */
export interface Link {
  /**
   * Provider error link href
   */
  href: string
  /**
   * Provider error link method
   */
  method: Method
  /**
   * Provider error link type
   */
  type: string
}

/**
 * Provider error link method
 */
export enum Method {
  Post = 'POST'
}

/**
 * Error type
 */
export enum Type {
  BookingAlreadyBeingFinalized = 'booking_already_being_finalized',
  BookingAlreadyCancelled = 'booking_already_cancelled',
  BookingAlreadyFinalized = 'booking_already_finalized',
  BookingNotFound = 'booking_not_found',
  BookingNotFrozen = 'booking_not_frozen',
  BookingStatusMetadataNotAvailable = 'booking_status_metadata_not_available',
  BookingUnauthorized = 'booking_unauthorized',
  BundleMetadata = 'bundle_metadata',
  BundleNotFound = 'bundle_not_found',
  CurrencyRates = 'currency_rates',
  Data = 'data',
  DataValidation = 'data_validation',
  HotelNotFound = 'hotel_not_found',
  InputValidation = 'input_validation',
  InvalidBookingID = 'invalid_booking_id',
  InvalidCurrency = 'invalid_currency',
  InvalidNotification = 'invalid_notification',
  LinkNotFound = 'link_not_found',
  OfferExpired = 'offer_expired',
  OfferMetadata = 'offer_metadata',
  OfferNotFound = 'offer_not_found',
  Payment = 'payment',
  PaymentNotFound = 'payment_not_found',
  PriceIntegrity = 'price_integrity',
  Provider = 'provider',
  ProviderBookingCreation = 'provider_booking_creation',
  ProviderBookingNotFound = 'provider_booking_not_found',
  ProviderLimitsReached = 'provider_limits_reached',
  ProviderNotFound = 'provider_not_found',
  ProviderPreBook = 'provider_pre_book',
  UserLimitsReached = 'user_limits_reached'
}
