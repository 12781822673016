import React, {useCallback} from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {Team} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'

import {ArrowButton} from '../ArrowButton'
import {IconWrapper, Label, RowWrapper} from '../commonStyles'

const TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY = 500

export const NotificationsLink = () => {
  const {trackMenuItemClick} = useTrackMenuItemClick()

  const handleClick = useCallback(() => {
    if (isReactNativeWebView()) {
      trackMenuItemClick(MenuItemComponent.ManageNotifications, Team.Retention)

      setTimeout(() => {
        postMessageToWebView({
          type: RNWebViewMessageTypes.NAVIGATE,
          payload: {screen: MenuItemComponent.ManageNotifications}
        })
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
    }
  }, [trackMenuItemClick])

  return (
    <RowWrapper>
      <ArrowButton onClick={handleClick}>
        <Label>
          <IconWrapper>
            <Icon name="Announcement" />
          </IconWrapper>
          <Text variant="labelM">
            <FormattedMessageWrapper
              id="notifications"
              defaultMessage="Notifications"
            />
          </Text>
        </Label>
      </ArrowButton>
    </RowWrapper>
  )
}
