import urlParamsMapper from 'components/data/URLParams/URLParamsMapper'
import {MiddlewareType} from 'middlewares'
import {
  CLEAR_REFRESHED_STATUS,
  HISTORY_BACK,
  NAVIGATE_TO,
  NAVIGATE_TO_CONFIRMATION_PAGE,
  NAVIGATE_TO_OUTSIDE,
  NAVIGATE_TO_SEARCH,
  navigateTo,
  REFRESH_PAGE,
  REFRESH_PAGE_ONCE,
  SCROLL_TO,
  SCROLL_TO_WINDOW_TOP,
  setPpLoaded,
  UPDATE_SEARCH_PARAMS
} from 'modules/browser/actions'
import {mergeDeepRight} from 'ramda'
import {CONFIRMATION_PAGE_PATH} from 'routes'
import {initForter} from 'services/forter'
import {
  getPageAsRefreshedStatus,
  setPageAsRefreshedStatus
} from 'services/sessionStorage'
import buildUrl from 'utils/buildUrl'
import {history} from 'utils/history'
import updateHistoryLocation from 'utils/updateHistoryLocation'
import {getSearchResultsUrl} from 'utils/urls'

import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import {
  scrollToElementOnCenter,
  scrollToWindowTop
} from '@daedalus/core/src/_web/utils/browser'
import {SIGN_UP_TOKEN} from '@daedalus/core/src/offer/business/privateDeals'

import {trackAll} from './analyticsV1'

const buildSearchParams = ({
  placeID,
  isAuthenticated,
  isAudience,
  params
}: {
  placeID?: string
  isAuthenticated?: boolean
  isAudience?: boolean
  params?: {
    checkIn?: string
    checkOut?: string
    rooms?: string
    optimizeRooms?: string
    utm_source?: string
    utm_campaign?: string
  }
}) => {
  // eslint-disable-next-line  new-cap
  const {searchWebsiteParams, metaParams} = urlParamsMapper()
  const authenticated = isAuthenticated ? 1 : undefined
  const token = isAudience ? SIGN_UP_TOKEN : undefined
  const {gsite} = metaParams

  const searchWebsiteParamsToBeMerged = {
    ...searchWebsiteParams,
    ...(placeID ? {placeId: placeID, hotelId: undefined} : {}),
    ...params
  }

  return mergeDeepRight(searchWebsiteParamsToBeMerged, {
    authenticated,
    token,
    gsite
  })
}

/* eslint-disable complexity */
const middleware: MiddlewareType = store => next => async action => {
  const {dispatch, getState} = store

  next(action)

  switch (action.type) {
    case HISTORY_BACK: {
      history.goBack()
      break
    }

    case REFRESH_PAGE: {
      history.go(0)
      break
    }

    case REFRESH_PAGE_ONCE: {
      if (!getPageAsRefreshedStatus()) {
        setPageAsRefreshedStatus(true)
        trackAll(`Checkout.forceRefreshPage`)
        window.setTimeout(() => {
          history.go(0)
        }, 1000)
      }

      break
    }

    case CLEAR_REFRESHED_STATUS: {
      setPageAsRefreshedStatus(false)
      break
    }

    case SCROLL_TO: {
      scrollToElementOnCenter(action.elementRef)
      break
    }

    case SCROLL_TO_WINDOW_TOP: {
      scrollToWindowTop()
      break
    }

    case NAVIGATE_TO_CONFIRMATION_PAGE: {
      dispatch(
        navigateTo(
          CONFIRMATION_PAGE_PATH,
          action.params.bookingParams,
          false,
          true
        )
      )
      break
    }

    case NAVIGATE_TO_SEARCH: {
      const {
        isAuthenticated,
        isAudience,
        isNewTab,
        checkIn,
        checkOut,
        rooms,
        optimizeRooms,
        utm_source,
        utm_campaign,
        placeID
      } = action.params

      const searchParams = {
        forceCurrencyChange: 1,
        forceLanguageChange: 1,
        ...buildSearchParams({
          placeID,
          isAudience,
          isAuthenticated,
          params: {
            checkIn,
            checkOut,
            rooms,
            optimizeRooms,
            utm_source,
            utm_campaign
          }
        })
      }

      const brand = getBrand(getState())

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isNewTab
        ? window.open(
            buildUrl(getSearchResultsUrl(brand), searchParams),
            '_blank'
          )
        : window.location.assign(
            buildUrl(getSearchResultsUrl(brand), searchParams)
          )

      break
    }

    case NAVIGATE_TO_OUTSIDE: {
      const {url, search} = action.params
      const {params} = urlParamsMapper()
      const newSearchParams = search ? mergeDeepRight(params, search) : null

      window.location = buildUrl(url, newSearchParams) as string & Location
      break
    }

    case NAVIGATE_TO: {
      const {url, search, replaceSearchParams, replace} = action.params
      updateHistoryLocation({url, search, replaceSearchParams, replace})
      break
    }

    case UPDATE_SEARCH_PARAMS: {
      const {search, replaceSearchParams} = action.params
      const url = history.location.pathname

      updateHistoryLocation({url, search, replaceSearchParams})
      break
    }

    case setPpLoaded.type: {
      initForter()

      break
    }

    default: {
      break
    }
  }
}

export default middleware
