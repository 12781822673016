import {assocPath, dissocPath, pathOr, pipe} from 'ramda'
import {urlParams} from 'utils/urlParams'

import {Price} from '@daedalus/core/src/api-types/bovio/response/booking'
import {TotalPrice} from '@daedalus/core/src/api-types/bovio/response/split_bookings/offer_check'
import {OfferPrice} from '@daedalus/core/src/offer/types/offer'
import {TaxDisplayLogic} from '@daedalus/core/src/offer/types/TaxDisplayLogic'
import {convertPricesToNewFormat} from '@daedalus/core/src/price/business/price'
import {
  basePath,
  getHotelFeesBreakdown,
  hotelFeesBreakdownPath,
  hotelFeesNightlyPath,
  hotelFeesPath,
  nightlyBasePath,
  nightlyDisplayTotalPath,
  nightlyTaxesPath,
  taxesPath
} from '@daedalus/core/src/room/business/transformSapiRoomsResponse/utils/price'
import {RoomPrice} from '@findhotel/sapi'

import getNumberOfNights from '../components/data/URLParams/mappers/getNumberOfNights'
import getRoomConfig from '../components/data/URLParams/mappers/getRoomConfig'
import calculateDisplayTotal from './calculateDisplayTotal'
import {
  addCurrencyToChargeableAndHotelFees,
  calculateNightlyPrice,
  convertPriceToFloat,
  guaranteeAtLeastOneHotelFeeBreakdown
} from './price'

type PriceType = RoomPrice | OfferPrice | Price | TotalPrice

/* TODO: remove total paths after BoFH remove these properties from the api */
const nightlyTotalPath = ['chargeable', 'nightlyTotal']

export const convertHotelFeesBreakdownToFloat = (price: PriceType) => {
  const hotelFeesBreakdown = getHotelFeesBreakdown(price)
  const convertedHotelFeesBreakdown = hotelFeesBreakdown.map(fee =>
    assocPath(['total'], Number.parseFloat(fee.total as string), fee)
  )

  return assocPath(hotelFeesBreakdownPath, convertedHotelFeesBreakdown, price)
}

export const decorateWithNightlyDisplayTotal = (
  price: OfferPrice,
  numberOfNights: number,
  numberOfRooms: number,
  includeTaxes: boolean,
  includeLocalTaxes: boolean
) => {
  const nightlyDisplayTotal = calculateDisplayTotal(
    pathOr(0, basePath, price),
    pathOr(0, taxesPath, price),
    pathOr(0, hotelFeesPath, price),
    includeTaxes,
    includeLocalTaxes,
    numberOfNights,
    numberOfRooms
  )

  return assocPath(nightlyDisplayTotalPath, nightlyDisplayTotal, price)
}

export const decorateWithNightlyPrice = (
  updatePath: string[],
  totalPath: string[],
  price: PriceType,
  numberOfNights: number,
  numberOfRooms: number
) => {
  const nightlyPrice = calculateNightlyPrice(
    pathOr(null, totalPath, price),
    numberOfNights,
    numberOfRooms
  )
  return assocPath(updatePath, nightlyPrice, price)
}

export const removeNightlyTotal = (price: PriceType) => {
  return dissocPath<RoomPrice>(nightlyTotalPath, price)
}

export const updateAllPrices = (
  price: PriceType,
  taxDisplayLogic: TaxDisplayLogic
): OfferPrice => {
  const params = urlParams()
  const numberOfNights = getNumberOfNights(params)
  const {numberOfRooms} = getRoomConfig(params)

  return pipe<
    [PriceType],
    PriceType,
    PriceType,
    PriceType,
    PriceType,
    PriceType,
    PriceType,
    PriceType,
    OfferPrice
  >(
    price => convertPriceToFloat(basePath, price),
    price => convertPriceToFloat(taxesPath, price),
    price => convertPriceToFloat(hotelFeesPath, price),
    convertHotelFeesBreakdownToFloat,
    removeNightlyTotal,
    price =>
      decorateWithNightlyPrice(
        nightlyBasePath,
        basePath,
        price,
        numberOfNights,
        numberOfRooms
      ),
    price =>
      decorateWithNightlyPrice(
        nightlyTaxesPath,
        taxesPath,
        price,
        numberOfNights,
        numberOfRooms
      ),
    price =>
      decorateWithNightlyPrice(
        hotelFeesNightlyPath,
        hotelFeesPath,
        price,
        numberOfNights,
        numberOfRooms
      ),
    price =>
      decorateWithNightlyDisplayTotal(
        price,
        numberOfNights,
        numberOfRooms,
        taxDisplayLogic.includeTaxes,
        taxDisplayLogic.includeLocalTaxes
      ),
    addCurrencyToChargeableAndHotelFees,
    convertPricesToNewFormat,
    guaranteeAtLeastOneHotelFeeBreakdown
  )(price)
}
