import React from 'react'
import {FallbackProps} from 'react-error-boundary'
import {styled} from '@linaria/react'

import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import {ThemeNames} from '@daedalus/atlas/themes'
import {CSSThemeProvider} from '@daedalus/atlas/themes/CSSThemeProvider'

import {CrashErrorMessageContainer, ErrorMessage} from '../CrashErrorMessage'

const HeightWrapper = styled.div`
  min-height: 600px;
  height: 1px;
`

interface Props extends FallbackProps {
  onClick?: () => void
  t?: ErrorMessage
}

export const ErrorBoundaryFallback: React.FC<Props> = ({onClick, t}) => {
  return (
    <CSSThemeProvider themeName={ThemeNames.vio} isGlobal>
      <CssBaseline />
      <GridGlobalStyles />
      <HeightWrapper>
        <CrashErrorMessageContainer t={t} onClick={onClick} />
      </HeightWrapper>
    </CSSThemeProvider>
  )
}
