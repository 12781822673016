import React, {AnchorHTMLAttributes, forwardRef, ReactNode} from 'react'
import {css, cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'
import {Colors} from 'types/Theme'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'

export type LinkSize = Extract<SizesType, 'xs' | 'sm' | 'md'>
export type LinkVariant = keyof Colors['link']

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Pass through style to allow styles overrides */
  style?: React.CSSProperties
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Size variant. If no size is provided, font-size inherited from surrounding text. */
  size?: LinkSize
  /** Link variant. Can be one of 'default', 'strong', 'info' or 'inverse'. 'strong' by default */
  variant?: LinkVariant
  /** An Icon to display on the right side of the Link for LTR languages. Inverted on RTL */
  iconEnd?: ReactNode
}

const textSizeMap = {
  xs: 'labelXS',
  sm: 'labelS',
  md: 'labelM'
} as const

const noSizeClass = css`
  font-size: inherit;
  line-height: inherit;
`
const textSizeClass: Record<LinkSize, string> = {
  xs: css`
    ${cssTheme.typography.text[textSizeMap['xs']]}
  `,
  sm: css`
    ${cssTheme.typography.text[textSizeMap['sm']]}
  `,
  md: css`
    ${cssTheme.typography.text[textSizeMap['md']]}
  `
}

const createVariantClass = (variantString: string) => {
  const variant = variantString as LinkVariant
  return `
    &.--${variant} {
      color: ${cssTheme.colors.link[variant].content};
      text-decoration: underline;
  
      ${linariaMq.desktopXs} {
        &:hover,
        :focus {
          color: ${cssTheme.colors.link[variant].hover.content};
          text-decoration: none;
        }
      }
  
      &:active {
        color: ${cssTheme.colors.link[variant].active.content};
        text-decoration: underline;
      }
  
      &:visited {
        color: ${cssTheme.colors.link[variant].visited.content};
        text-decoration: underline;
      }
    }
`
}

const LinkWrapper = styled('a')`
  display: inline-block;
  vertical-align: baseline;
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;

  ${Object.keys(cssTheme.colors.link).map(createVariantClass).join('\n')}
`

export const TextWrapper = styled('span')`
  user-select: none;
  ${linariaMq.desktopXs} {
    user-select: auto;
  }
`

export const IconWrapper = styled.span`
  display: inline-block;
  vertical-align: text-bottom;
  &:first-child {
    margin-right: ${cssTheme.layout.spacing.s100};
  }
  &:last-child {
    margin-left: ${cssTheme.layout.spacing.s100};
  }
`

export const Link = forwardRef(
  (
    {
      children,
      size,
      variant = 'default',
      dataId = '',
      iconEnd,
      className,
      ...anchorProps
    }: Props & AnchorHTMLAttributes<HTMLAnchorElement>,
    ref: React.ForwardedRef<HTMLAnchorElement>
  ) => (
    <LinkWrapper
      data-id={dataId}
      className={cx(className, `--${variant}`)}
      ref={ref}
      {...anchorProps}
    >
      <TextWrapper className={cx(size ? textSizeClass[size] : noSizeClass)}>
        {children}
      </TextWrapper>
      {iconEnd && <IconWrapper>{iconEnd}</IconWrapper>}
    </LinkWrapper>
  )
)

// eslint-disable-next-line fp/no-mutation
Link.displayName = 'AtlasLink'
