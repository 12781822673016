import React, {ComponentProps, ReactElement} from 'react'
import {styled} from '@linaria/react'

import {
  BottomSheet as AtlasBottomSheet,
  BottomSheetHeader
} from '@daedalus/atlas/BottomSheet'
import {HeaderWrapper, SubHeader} from '@daedalus/atlas/BottomSheet/styles'
import {cssTheme} from '@daedalus/atlas/themes'
import minus from '@daedalus/atlas/utils/minus'

type AtlasBottomSheetProps = ComponentProps<typeof AtlasBottomSheet>

interface Props extends Omit<AtlasBottomSheetProps, 'titleBarProps'> {
  iconComponent?: ReactElement | null
}

const HeaderIcon = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  left: 50%;
  bottom: ${minus(cssTheme.layout.spacing.s700)};
  transform: translateX(-50%);
`

const StyledBottomSheet = styled(AtlasBottomSheet)`
  z-index: 101 !important;
  .react-modal-sheet-container {
    overflow: hidden;
  }
  ${BottomSheetHeader} {
    position: relative;
    margin-bottom: ${cssTheme.layout.spacing.s500};
  }
  ${HeaderWrapper} {
    height: 100%;
    padding: 0;
  }
  ${SubHeader} {
    padding: 0;
  }
`

export const BrandBottomSheet = ({
  iconComponent,
  children,
  ...props
}: Props) => {
  return (
    <StyledBottomSheet
      {...props}
      titleBarProps={{
        centerContent: <HeaderIcon>{iconComponent}</HeaderIcon>,
        hasDivider: false
      }}
    >
      {children}
    </StyledBottomSheet>
  )
}
